import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import APIFetch from "../../utilities/APIFetch";
import CustomerLookupWidget from "./CustomerLookupWidget";
import OrderStatsWidget from "./OrderStatsWidget";
import RecentOrdersWidget from "./RecentOrdersWidget";
import QuickStatWidget from "./QuickStatWidget";
import ReturnsWidget from "./ReturnsWidget";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [rangeType, setRangeType] = useState(0);
  const [pendingLookup, setPendingLookup] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [bestSeller, setBestSeller] = useState(null);
  const [customerMatches, setCustomerMatches] = useState([]);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if(!firstName && !surname && !email && !phoneNumber) {
        setCustomerMatches([]);
        return;
    }

    if(pendingLookup) {
        clearTimeout(pendingLookup);
    }

    setPendingLookup(setTimeout(() => {
        setPendingLookup(null);

        APIFetch('POST', 'customer/search', {
            firstName,
            surname,
            phoneNumber,
            email,
            dob: null
        })
        .then(result => {
            if(result.ok) {
                setCustomerMatches(result.data);
            }
        })
        .catch(e => {

        });
    }, 500));
  }, [firstName, surname, email, phoneNumber]);

  useEffect(() => {
    APIFetch("GET", `stats/sales/${rangeType === 0 ? "daily" : rangeType === 1 ? "weekly" : "monthly"}`)
    .then(result => {
        if(result.ok) {
            setChartData(result.data.chartData);
            setBestSeller(result.data.bestSeller);
        } else {
            setMessage("An error occurred loading customer source data.");
        }
    })
    .catch(() => {
        setMessage("An error occurred loading customer source data.");
    })
  }, [rangeType]);

  return (
    <div className="App">
      <div className="flex flex-row w-full h-full overflow-y-hidden">
        <Sidebar />
        <div className="flex flex-row p-4 flex-grow bg-brand-grey-light">
          <div className="flex flex-col flex-1 p-4">
            <div className="flex flex-col flex-1 shadow p-4 bg-white mb-2">
              <div className="flex flex-row justify-center items-center border p-4 shadow mb-4 border-black">
                <h1 className="text-lg uppercase font-semibold">Business Dashboard</h1>
                <div className="ml-auto">
                  <select className="flex self-end border border-black p-1 px-3 font-medium" value={rangeType} onChange={(e) => { setRangeType(parseInt(e.target.value)); }}>
                    <option value={0}>Daily</option>
                    <option value={1}>Weekly</option>
                    <option value={2}>Monthly</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-row mb-4 gap-4">
                <QuickStatWidget title={"Shop Orders"} value={chartData != null ? chartData.reduce((acc, d) => acc + d.eposQuantity, 0) : "-"} />
                <QuickStatWidget title={"Web Orders"}>
                  <div className="flex flex-row justify-center">
                    <div className="flex flex-col border-r border-r-black basis-1/2">
                      <div className="my-auto text-2xl font-semibold">{chartData != null ? chartData.reduce((acc, d) => acc + d.webQuantity, 0) : "-"}</div>
                      <div className="my-auto text-sm font-semibold">Total</div>
                    </div>
                    <div className="flex flex-col basis-1/2 cursor-pointer" onClick={() => { navigate(`/orders?status=Fully Paid&type=Web`)  }}>
                      <div className="my-auto text-2xl font-semibold">{chartData != null ? chartData.reduce((acc, d) => acc + d.webQuantityToProcess, 0) : "-"}</div>
                      <div className="my-auto text-sm font-semibold underline">To Process</div>
                    </div>
                  </div>
                </QuickStatWidget>
                <QuickStatWidget title={"Total Value"} value={chartData ? "£" + Math.round(chartData.reduce((acc, d) => acc + d.totalSpend, 0)) : "-"} />
                <QuickStatWidget title={"Most Popular"}>
                  <div className="flex flex-row">
                    <div className="flex flex-col text-left">
                      <div className="text-sm">{bestSeller ? bestSeller.name || bestSeller.productCode : '-'}</div>
                      <div className="text-sm font-normal text-brand-grey">-</div>
                      <div className="text-sm font-normal text-brand-grey">-</div>
                    </div>
                    <div>
                      <img className="" src="" />
                    </div>
                  </div>
                </QuickStatWidget>
              </div>
              <OrderStatsWidget rangeType={rangeType} chartData={chartData}/>
            </div>
            <div className="flex flex-col flex-grow min-h-0">
              <RecentOrdersWidget className="mt-2"/>
            </div>
          </div>
          <div className="flex flex-col ml-2">
            <CustomerLookupWidget />
            <ReturnsWidget />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;