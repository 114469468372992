import { useState } from "react";
import APIFetch from "../utilities/APIFetch";
import MessageModal from "./MessageModal";
import Modal from "./Modal";
import appConfig from '../appConfig.json';


function PasswordResetModal({ onClose, onComplete, email, password }) {
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [waiting, setWaiting] = useState(false);

    const submit = () => {
        if(newPassword.length < 8) {
            setMessage("Password must be atleast 8 characters long.");
            return;
        }

        if(!waiting) {
            setWaiting(true);
            fetch(appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV].API_DOMAIN + `auth/reset`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password, newPassword })
            })
            .then(response => {
                if(response.ok) {
                    onComplete(newPassword);
                } else {
                    setMessage("Something went wrong when attempting to update your password.");
                }
            })
            .catch((e) => {
                setMessage("Something went wrong when attempting to update your password.");
            })
            .finally(() => {
                setWaiting(false);
            })
        }
    }

    return <Modal onClose={onClose} heading={'Set A New Password To Continue'} className="text-black text-left" windowClassName="w-[450px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-col">
            <div className="text-brand-grey text-sm text-center">You must set a new password before you can access Chatley's EPOS.</div>
            <div className="flex flex-col mt-8 ">
                <label className="input-label mb-1">Password: </label>
                <input type="password" value={newPassword} placeholder="New password..." className="flex-1" onChange={(e) => setNewPassword(e.target.value)}/>
            </div>
            <div className="flex flex-row">
                <div className="btn flex ml-auto mr-auto mt-12" onClick={() => submit()}>Submit</div>
            </div>
        </div>
    </Modal>;
}

export default PasswordResetModal;