import { useEffect, useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import DataCache from "../../utilities/DataCache";

const TillShipmentModal = ({ order, shippingMethods, userId, onClose, onSuccess }) => {
    const [message, setMessage] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const [code, setCode] = useState("");
    const [shippingMethod, setShippingMethod] = useState(-1);

    const onSubmit = () => {
        if(!waiting) {
            if(shippingMethod === -1) {
                setMessage("A shipping method must be selected.");
            } else if(selectedItems.length < 1) {
                setMessage("No items have been added to the shipment.");
            } else {
                setWaiting(true);
                APIFetch('POST', `shipping/${order.id}`, {
                    shippingMethodId: shippingMethod,
                    code: code,
                    userId: userId,
                    itemIds: selectedItems.map(i => i.orderItemId)
                })
                .then(result => {
                    if(result.ok) {
                        onSuccess();
                    } else {
                        setMessage("An error occurred when attempting to create the shipment.");
                    }
                })
                .catch(e => {
                    setMessage("An error occurred when attempting to create the shipment.");
                })
                .finally(() => setWaiting(false));
            }
        }
    }

    const removeItem = (item) => {
        var newItems = selectedItems.filter(i => i !== item);
        setSelectedItems(newItems);
    }

    const addItem = (item) => {
        var newItems = [...selectedItems];
        newItems.push({
            orderItemId: item.id,
            productCode: item.productCode,
            returnReason: -1,
            locationId: 0,
            variations: item.variations,
            price: item.price,
            vat: item.vat,
            manufacturerId: item.manufacturerId,
            departmentId: item.departmentId,
            categoryId: item.categoryId,
            productId: item.productId
        });

        setSelectedItems(newItems);
    }

    return <Modal onClose={onClose} heading={'Record Shipment'} className="text-black text-left flex flex-col">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-col md:w-[50vw]">
            <div className="flex flex-col mb-4">
                <div className="flex flex-row py-1 mb-1 bg-brand-grey-light font-semibold">
                    <div className="flex flex-col basis-6/12 justify-center items-center">Product Code</div>
                    <div className="flex flex-col basis-6/12 justify-center items-center">Details</div>
                    <div className="flex flex-col w-[40px] justify-center items-center"></div>
                </div>
                {order.items.filter(i => !i.returnId && !i.shipmentId).map(i => {
                    var selected = selectedItems.find(s => s.orderItemId === i.id);

                    if(selected) {
                        return <div className="flex flex-row pb-1 mb-1 border-b border-b-brand-grey text-sm relative font-bold">
                            <div className="flex flex-col basis-6/12 justify-center items-center">{selected.productCode != "" ? selected.productCode : 'Open Department'}</div>
                            <div className="flex flex-col basis-6/12 justify-center items-center">
                                {selected.variations ? selected.variations.sort((a, b) => a.name.localeCompare(b.name)).map((v, i) => {
                                    return <div className="text-xs">{v.name}: <span className="font-bold">{v.value}</span></div>
                                }) : null }
                            </div>
                            <div className="flex flex-col text-base w-[40px] justify-center items-center">
                                <FontAwesomeIcon className="cursor-pointer" icon={faClose} color={'red'} onClick={() => { removeItem(selected) }}/>
                            </div>
                        </div> 
                    }

                    return <div className="flex flex-row pb-1 mb-1 border-b border-b-brand-grey text-sm relative">
                        <div className="flex flex-col basis-6/12 justify-center items-center">{i.productCode != "" ? i.productCode : 'Open Department'}</div>
                        <div className="flex flex-col basis-6/12 justify-center items-center">
                            {i.variations ? i.variations.sort((a, b) => a.name.localeCompare(b.name)).map((v, i) => {
                                return <div className="text-xs">{v.name}: <span className="font-medium">{v.value}</span></div>
                            }) : null }
                        </div>
                        <div className="flex flex-col text-base w-[40px] justify-center items-center">
                            <FontAwesomeIcon className="cursor-pointer" icon={faPlus} color={'green'} onClick={() => { addItem(i) }}/>
                        </div>
                    </div>  
                })}
            </div>
            <div className="text-center my-4">{selectedItems.length} items selected.</div>
            <div className="flex flex-row justify-center">
                <div className="flex flex-col mt-5 mr-5 w-[200px]">
                    <label className="mb-1 text-brand-grey-alt">Shipping Method*:</label>
                    <select value={shippingMethod} onChange={e => setShippingMethod(e.target.value)}>
                        <option value={-1} selected disabled>Select Shipping Method</option>
                        {shippingMethods ? shippingMethods.map(s => {
                            return <option key={s.id} value={s.id}>{s.name}</option>
                        }) : null}
                    </select>
                </div>
                <div className="flex flex-col mt-5 ml-5 w-[200px]">
                    <label className="mb-1 text-brand-grey-alt">Tracking Code:</label>
                    <input className="flex-1"
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                </div>
            </div>
        </div>
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={() => onClose()}>Cancel</div>
            <div className="flex btn" onClick={() => onSubmit()}>OK</div>
        </div>
    </Modal>;
}

export default TillShipmentModal;