import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { SpinnerCircular } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import Modal from "../../components/Modal";
import ItemEditPanel from "../../components/ItemEditPanel";
import ItemCard from "../../components/ItemCard";
import TemplateEditPanel from "./TemplateEditPanel";

function TemplateManagement() {
    const [templates, setTemplates] = useState(null);
    const [message, setMessage] = useState(null);
    const [creation, setCreation] = useState(false);
    const [selected, setSelected] = useState(null);
    const [pendingDelete, setPendingDelete] = useState(false);

    // Creation
    const [templateName, setTemplateName] = useState("");
    const [variationName, setVariationName] = useState("");
    const [valueList, setValueList] = useState([]);

    useEffect(() => {
        APIFetch("GET", "variationtemplate")
        .then(result => {
            if(result.ok) {
                setTemplates(result.data);
            } else {
                setTemplates([]);
            }
        })
        .catch(() => {
            setTemplates([]);
        })
    }, []);

    const onDelete = (template) => {
        if(!pendingDelete || pendingDelete !== template) {
            setPendingDelete(template);
        } else {
            APIFetch("DELETE", `variationtemplate/${template.id}`)
            .then(result => {
                if(result.ok) {
                    var newTemplates = [...templates];
                    newTemplates = newTemplates.filter(l => l.id !== template.id);
                    setTemplates(newTemplates);
                    setPendingDelete(null);
                    setMessage("Size Scale deleted successfully.");
                } else {
                    setMessage("An error occurred when attempting to delete the size scale.");
                }
            })
            .catch(() => {
                setMessage("An error occurred when attempting to delete the size scale.");
            })
        }
    }

    const createTemplate = () => {
        return APIFetch('POST', `variationtemplate`, { variationName, templateName, values: valueList.map((v) => { return v.value } ).filter(v => v.length > 0) })
        .then(result => {
            if(result.ok) {
                var newTemplates = [...templates];
                newTemplates.push(result.data);
                newTemplates.sort((a,b) => (a.templateName > b.templateName) ? 1 : ((b.templateName > a.templateName) ? -1 : 0));
                setVariationName('');
                setTemplateName('');
                setValueList([]);
                setTemplates(newTemplates);
                setCreation(false);
            } else {
                setMessage(`Something went wrong when attempting to create the size scale.`);
            }
        })
        .catch(e => {
            setMessage(`Something went wrong when attempting to create the size scale.`);
        })
    }

    const cancelTemplate = () => {
        setVariationName('');
        setTemplateName('');
        setValueList([]);
        setCreation(false);
    }

    const onEdit = (template) => {
        var newTemplates = [...templates];
        var index = newTemplates.findIndex(a => a.id === template.id);
        newTemplates[index] = template;
        setTemplates(newTemplates);
        setSelected({...template});
    }

    const addValue = () => {
        var newValues = [...valueList];
        newValues.push({ key: Math.random(), value: "" });
        setValueList(newValues);
    }

    const removeValue = (item) => {
        var newValues = valueList.filter(v => v !== item);
        setValueList(newValues);
    }

    const onValueChange = (item, value) => {
        var newValues = [...valueList];
        var editItem = newValues.find(v => v == item);
        editItem.value = value;
        setValueList(newValues);
    }

    return (
        <div className="App">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { creation ? <Modal onClose={() => cancelTemplate()} heading="Create New Size Scale" className="text-black text-left" windowClassName="w-[450px]">
            <div className="flex flex-col mt-4">
                <label>Size Scale Name: </label>
                <input type="text" value={templateName} className="flex-1" onChange={(e) => setTemplateName(e.target.value)}/>
            </div>
            <div className="flex flex-col mt-4">
                <label>Variation Name: </label>
                <input type="text" value={variationName} className="flex-1" onChange={(e) => setVariationName(e.target.value)}/>
            </div>
            <div className="flex flex-col mt-4 overflow-y-auto flex-shrink">
                <div className="flex flex-row mb-2">
                    <label>Variation Values: </label>
                    <label className="cursor-pointer ml-auto border-b border-b-brand-grey" onClick={() => { addValue() }}>Add Value</label>
                </div>
                <div className="flex flex-col">
                    {valueList.map(v => {
                        return <div className="flex flex-row mt-2 items-center">
                            <input key={v.key || v.id} type="text" className="flex-1" value={v.value} onChange={(e) => { onValueChange(v, e.target.value); }} />
                            <FontAwesomeIcon icon={faTimes} className="text-brand-grey cursor-pointer hover:text-brand-grey-alt ml-2" onClick={() => { removeValue(v) }}/>
                        </div>
                    })}
                </div>
            </div>
            <div className="flex ml-auto mr-auto w-32 btn mt-10" onClick={createTemplate}>Create</div>
        </Modal> : null }
        { pendingDelete ? <Modal heading="Confirm Size Scale Deletion" className="text-black" onClose={() => setPendingDelete(null)} windowClassName="w-[450px]">
            <div className="text-sm">Are you sure you wish to delete '{pendingDelete.templateName}'?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {onDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        <div className="flex flex-col md:flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="flex flex-row items-center ml-2 mt-9 mb-16">
                    <h1 className="text-left font-medium text-lg">Size Scales</h1>
                    <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => setCreation(true)}/>
                </div>
                <div className="flex flex-row px-3 font-bold mb-2 text-sm mt-2">
                    <div className="flex flex-1 basis-4/12">Size Scale Name</div>
                    <div className="flex flex-1 basis-4/12">Field Name</div>
                    <div className="flex flex-1 basis-4/12">No. Values</div>
                    <div className="flex flex-1 basis-[45px]"></div>
                </div>
                <div className="flex flex-col flex-grow overflow-y-auto">
                    { templates && templates.length === 0 ? <div>No size scales to display.</div> : null }
                    { templates ? templates.map(l => <ItemCard key={l.id} item={l} onEdit={() => setSelected(l)} additionalFields={[{ value: l.templateName, className: "basis-4/12" }, { value: l.variationName, className: "basis-4/12" }, { value: l.values ? l.values.length : '0', className: "basis-4/12" }]} onDelete={onDelete} selected={selected === l}/>) : null }
                    <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular enabled={!templates} size={50} color="#24272b" secondaryColor="white" />
                    </div>
                </div>
            </div>
            { selected ? <TemplateEditPanel key={selected.id + "edit"} item={selected} onDelete={onDelete} onChange={(a) => { onEdit(a); }} onClose={() => { setSelected(null) }}/> : null }
        </div>
        </div>
    );
}

export default TemplateManagement;