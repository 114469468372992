import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import CreditNotePrint from "../../components/PrintTemplates/CreditNotePrint";
import APIFetch from "../../utilities/APIFetch";

const TillCreditNoteModal = ({ onClose, settings }) => {
    const [message, setMessage] = useState(null);
    const [waiting, setWaiting] = useState(false);

    const [value, setValue] = useState("");
    const [code, setCode] = useState("");

    const [isPrinting, setIsPrinting] = useState(false);
    const printingRef = useRef(null);

    // We store the resolve Promise being used in `onBeforeGetContent` here
    const promiseResolveRef = useRef(null);

    // We watch for the state to change here, and for the Promise resolve to be available
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = useReactToPrint({
        content: () => printingRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
            onClose();
        }
    });

    const onSubmit = () => {
        if(!value || value.length == 0) {
            setMessage("Credit Note value is missing.");
            return;
        }
        if(!waiting) {
            setWaiting(true);

            APIFetch('POST', `creditnote`, { value, code })
            .then(result => {
                if(result.ok) {
                    setCode(result.data.code);
                    handlePrint();
                } else {
                    setMessage("An error occurred when attempting to create the credit note.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when attempting to create the credit note.");
            })
            .finally(() => {
                setWaiting(false);
            });
        }
    }

    return <Modal onClose={onClose} heading={'Create Credit Note'} className="text-black text-left flex flex-col" windowClassName="w-[450px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <CreditNotePrint ref={printingRef} value={value} code={code} settings={settings}/>
        <div className="flex flex-col mt-2">
            <label>Value:</label>
            <input type="number" value={value} placeholder="Value..." className="flex-1" onChange={(e) => setValue(e.target.value)}/>
        </div>
        <div className="flex flex-col mt-6 mb-4">
            <label>Code (optional):</label>
            <input type="text" value={code} placeholder="Code..." className="flex-1" onChange={(e) => setCode(e.target.value)}/>
        </div>
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={() => onClose()}>Cancel</div>
            <div className="flex btn" onClick={() => onSubmit()}>Create</div>
        </div>
    </Modal>;
}

export default TillCreditNoteModal;