import { forwardRef, useEffect, useState } from "react";
import Barcode from "react-barcode";
import logo from '../../assets/images/chatleys.png';
import OrderDetails from "../../pages/Till/OrderDetails";
import appConfig from "../../appConfig.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

function ReceiptPrint({ order, discounts, itemDiscounts, vouchers, VATRate, tillUserName, settings, isRefund }, ref) {
    const getSetting = (name) => {
        var match = settings.find(s => s.name === name);
        if(!match) return null;
        return match.value;
    }

    let addressLines = [
        getSetting("address1"),
        getSetting("address2"),
        getSetting("town"),
        getSetting("county"),
        getSetting("postcode")
    ].filter(l => l && l.length)

    return <div ref={ref} className="onlyPrint relative top-0 left-0 w-full">
        <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center ml-auto mr-auto text-xs">
                <object data={logo} type="image/png" className="w-[325px] max-w-[90%] min-w-0 my-4">
                    <img src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].ASSETS_DOMAIN + `system/logo.jpg?${new Date().getTime()}` || logo} width={325} className="my-4"/>
                </object>
                <div className="mb-2 text-center text-xs">{addressLines.join(", ")}</div>
                {settings.filter(s => s.name.startsWith("phone_number_")).sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(p => <div className="text-center text-xs"><FontAwesomeIcon icon={p.icon === "1" ? faWhatsapp : faPhone} className="mr-1"/>{p.value}</div>)}
                <div className="mb-2 text-center text-xs">{getSetting('receipt-header')}</div>
                <div className="text-md font-bold mt-0 mb-2">{isRefund ? "Refund " : ""}Receipt (Order #{order.id})</div>
                <div className="flex flex-col w-full pl-1 mb-2 text-xs">
                    <div className="flex flex-row">
                        <div className="font-bold mr-2">Date:</div>
                        <div>{new Date().toLocaleString()}</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="font-bold mr-2">Order Date:</div>
                        <div>{new Date(order.createdAt).toLocaleString()}</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="font-bold mr-2">Status:</div>
                        <div>{order.status}</div>
                    </div>
                    {tillUserName ? <div className="flex flex-row">
                        <div className="font-bold mr-2">Till User:</div>
                        <div>{tillUserName}</div>
                    </div> : null }
                    <div className="flex flex-row">
                        <div className="font-bold mr-2">Transaction Type:</div>
                        <div>{order.transactions.filter(t => t.type !== 'Change').slice(-1)[0].type}</div>
                    </div>
                </div>
                <OrderDetails
                    isPrint={true}
                    isRefund={isRefund}
                    order={order}
                    discounts={discounts}
                    itemDiscounts={itemDiscounts}
                    vouchers={vouchers}
                    VATRate={VATRate}
                    settings={settings}
                />
                {isRefund ? <div className="flex flex-col w-full mt-6 mb-3">
                    <div className="mb-5">Customer Signature:</div>
                    <div className="w-full border-dotted border-b border-b-black"></div>
                </div> :
                <div className="flex flex-row bg-white text-brand-grey px-2 font-bold mt-0 mb-0py-0 text-md print:ml-auto print:mr-auto">
                    {getSetting('receipt-thanks')}
                </div> }
                <div className="mt-0">
                    <Barcode value={order.id.toString().padStart(9, '0')} height={40} fontSize={10}/>
                </div>
                <div  className="flex flex-row mt-0">
                    <div className="">VAT Registration No. <strong>146376649</strong></div>
                </div>
            </div>
        </div>
    </div>;
}

export default forwardRef(ReceiptPrint);