// Sorts strings for clothing sizes
// Handles standard sizing e.g. S, M, L, 2XL
// and handles trouser sizing e.g. 32" L (34")
var sizeOrder = ['one size', 'n/a', 'xs', 's', 'r', 'm', 'l', 'xl', 'xxl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl', '8xl', '9xl', '10xl', '11xl', '12xl'];
var joggersizes = ['m-sh', 'm-reg', 'l-sh', 'l-reg', 'xl-sh', 'xl-reg', 'xxl-sh', 'xxlreg', '3xlreg', '3xl-sh', '4xl-sh', '4xlreg', '5xl-sh', '5xlreg', '6xl-sh', '6xlreg', '7xl-sh', '7xlreg', '8xl-sh', '8xlreg', '9xl-sh', '9xlreg', '10xlsh', '10xlre'];

function alphanum(a, b) {
    
    function isdigit(ch) {
        return '0' <= ch && ch <= '9';
    }
    
    var min = Math.min(a.length, b.length);
    
    for (var i = 0; i < min; i++) {
        if (a.charCodeAt(i) !== b.charCodeAt(i)) break;
    }
    
    if (i == min) return a.length - b.length;
    
    if (isdigit(a[i])) {
        if (isdigit(b[i])) {
            if (a[i] === '0' || b[i] === '0') {
                for (var z = i - 1; z > 0; z--) {
                    if (a[z] !== '0') break;
                }
                if (!isdigit(a[z])) z++;
                if (a[z] === '0' || b[z] === '0') {
                    for (var j = i; ; j++) {
                        if (b[j] !== '0') break;
                    }
                    for (;; i++) {
                        if (a[i] !== '0') break;
                    }
                    if (!isdigit(a[i])) {
                        if (isdigit(b[j])) {
                            return -1;
                        } else {
                            return i - j;
                        }
                    } else if (!isdigit(b[j])) {
                        return 1;
                    } else {
                        let cmp = a.charCodeAt(i) - b.charCodeAt(j);
                        for (i++, j++;; i++, j++) {
                            if (!isdigit(a[i])) {
                                if (isdigit(b[j])) {
                                    return -1;
                                } else {
                                    if (cmp) return cmp;
                                    return i - j;
                                }
                            } else if (!isdigit(b[j])) {
                                return 1;
                            }
                        }
                    }
                }
            }
            
            let cmp = a.charCodeAt(i) - b.charCodeAt(i);
            for (i++;; i++) {
                if (!isdigit(a[i])) {
                    if (isdigit(b[i])) {
                        return -1;
                    } else {
                        return cmp;
                    }
                } else if (!isdigit(b[i])) {
                    return 1;
                }
            }
        } else if (isdigit(a[i - 1])) {
            return 1;
        } else {
            return a.charCodeAt(i) - b.charCodeAt(i);
        }
    } else if (isdigit(b[i])) {
        if (isdigit(b[i - 1])) {
            return -1;
        } else {
            return a.charCodeAt(i) - b.charCodeAt(i);
        }
    } else {
        return a.charCodeAt(i) - b.charCodeAt(i);
    }
}

const sortSizes = (items, property) => {
    return items.slice().sort((fullA, fullB) => {
        let a = property ? fullA[property].toLowerCase() : fullA.toLowerCase();
        let b = property ? fullB[property].toLowerCase() : fullB.toLowerCase();
        
        if(sizeOrder.indexOf(a) > -1 && sizeOrder.indexOf(b) > -1) {
            // Two standard sizes
            return sizeOrder.indexOf(a) - sizeOrder.indexOf(b);
        } else {
            // Handle odd jogger sizes e.g 3XL-SH, 3XLREG
            if(joggersizes.indexOf(a) > -1 && joggersizes.indexOf(b) > -1) {
                // Two standard sizes
                return joggersizes.indexOf(a) - joggersizes.indexOf(b);
            }
            
            let matchesA = [...a.matchAll(/([0-9]+)(?:.+?)?([A-z]+)(?:[^0-9\n]+)?([0-9]+)?/g)][0];
            let matchesB = [...b.matchAll(/([0-9]+)(?:.+?)?([A-z]+)(?:[^0-9\n]+)?([0-9]+)?/g)][0];

            let mixedSort = (a, b) => {
                return sizeOrder.indexOf(a) > -1 && sizeOrder.indexOf(b) > -1 ? sizeOrder.indexOf(a) - sizeOrder.indexOf(b) : alphanum(a, b);
            };

            if(matchesA && matchesB) {
                for(let i = 1; i < matchesA.length && i < matchesB.length; i++) {
                    if(matchesA[i] && matchesB[i]) {
                        var sort = mixedSort(matchesA[i], matchesB[i]);

                        if(sort !== 0) return sort;
                    }
                }
            }

            return alphanum(a, b);
        }
    });
}

export default sortSizes;