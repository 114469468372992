import { forwardRef, useEffect, useState } from "react";
import Barcode from "react-barcode";
import logo from '../../assets/images/chatleys.png';
import appConfig from "../../appConfig.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import '../../index.scss';

function VoucherPrint({ value, code, id, expiry, settings }, ref) {
    const getSetting = (name) => {
        var match = settings.find(s => s.name === name);
        if(!match) return null;
        return match.value;
    }

    let addressLines = [
        getSetting("address1"),
        getSetting("address2"),
        getSetting("town"),
        getSetting("county"),
        getSetting("postcode")
    ].filter(l => l && l.length);

    return <div ref={ref} className="onlyPrint voucherPrint fixed top-0 left-0 h-full w-full flex flex-col px-8" style={{ letterSpacing: '0.25em' }}>
        <div className="flex basis-[33%] border-b-2 border-b-gray-400 border-dashed flex-shrink-0 flex-grow-0">
            <div className="flex mt-auto ml-auto mr-auto text-xs mb-2" style={{ fontSize: '0.5rem' }}>FOLD ALONG THIS LINE FIRST</div>
        </div>
        <div className="flex flex-col justify-center items-center basis-[34%] border-b-2 border-b-gray-400 border-dashed relative flex-shrink-0 flex-grow-0">
            <object data={logo} type="image/png" className="h-[6vh] mb-8 mt-3">
                <img src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].ASSETS_DOMAIN + `system/logo.jpg?${new Date().getTime()}` || logo} width={400} className="my-8"/>
            </object>
            <div className="flex flex-row justify-center items-center">
                <div className="text-9xl font-semibold mr-6">£{value.toFixed(0)}</div>
                <div className="flex flex-col text-5xl font-semibold">
                    <div>GIFT</div>
                    <div>VOUCHER</div>
                </div>
            </div>
            <div className="flex ml-auto mr-auto mb-8">TO SPEND IN STORE OR ONLINE WITH CHATLEYS</div>
            <div className="flex flex-row mb-4">
                <div className="mr-1 text-xs">TO:</div>
                <div className="w-[30vw] border-b border-b-black mr-8"></div>
                <div className="mr-1 text-xs">FROM:</div>
                <div className="w-[30vw] border-b border-b-black"></div>
            </div>
            <div className="absolute top-3 right-2 font-semibold text-lg">
                {id.toString().padStart(5, '0')}
            </div>
            <div className="absolute bottom-2 right-2" style={{ fontSize: '0.5rem' }}>
                EXP: {new Date(expiry).toLocaleDateString()}
            </div>
        </div>
        <div className="flex flex-col items-center basis-[33%] relative flex-shrink-0 flex-grow-0 rotate-180">
            <div className="flex flex-col justify-center items-center absolute top-1/2 -left-2" style={{ transform: "rotate(-90deg) translateX(-50%)", transformOrigin: 'top left' }}>
                <div className="">
                    <Barcode
                        value={code}
                        height={60}
                        width={1.32}
                        displayValue={false}/>
                </div>
                <div className="mt-[-5px] z-10 text-sm">CODE: {code}</div>
            </div>
            <object data={logo} type="image/png" className="h-[6vh] mb-6 mt-7">
                <img src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].ASSETS_DOMAIN + `system/logo.jpg?${new Date().getTime()}` || logo} width={400} className="my-8"/>
            </object>
            <div className="flex ml-auto mr-auto my-10 max-w-[75%] text-center text-xs tracking-widest uppercase">{addressLines.join(", ")}</div>
            {settings.filter(s => s.name.startsWith("phone_number_")).sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(p => <div className="text-center text-lg mb-3"><FontAwesomeIcon icon={p.icon === "1" ? faWhatsapp : faPhone} className="mr-3"/>{p.value}</div>)}
            <div className="flex ml-auto mr-auto mb-2">www.chatleys.co.uk</div>
        </div>
    </div>
}

export default forwardRef(VoucherPrint);