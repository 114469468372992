import Modal from "../../components/Modal";
import MessageModal from "../../components/MessageModal";
import { useEffect, useState } from "react";
import APIFetch from "../../utilities/APIFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

function ReplenishmentTransferModal({ transfers, onClose, onSuccess }) {
    const [message, setMessage] = useState(null);
    const [value, setValue] = useState(0);
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
        if(transfers.length === 1) {
            setValue(transfers[0].max);
        }
    }, [transfers]);

    const applyTransfer = () => {
        if(!waiting) {
            setWaiting(true);

            APIFetch('POST', 'stocktransfer/replenish', { transfers: transfers.length === 1 ? [{ barcode: transfers[0].barcode, quantity: value }] : transfers })
            .then(result => {
                if(result.ok) {
                    onSuccess(transfers, value);
                } else {
                    setMessage("An error occurred during the transfer.");
                    setWaiting(false);
                }
            })
            .catch(e => {
                setMessage("An error occurred during the transfer.");
                setWaiting(false);
            });
        }
    }

    return <Modal onClose={onClose} heading="Replenish Stock" className="text-black text-center" windowClassName="w-[450px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="text-sm arrows">
            {transfers.length === 1 ? <div>
                Transfer
                <FontAwesomeIcon className={"select-none cursor-pointer px-2" + (value > 1 ? " text-red-600" : " text-brand-grey-alt")} icon={faMinus} onClick={() => { setValue((value - 1) || 1) }}/>
                <input className="text-center inline-block border-b-0 no-arrows" type="number" min={1} max={transfers[0].max} value={value} onChange={(e) => {setValue(e.target.value)}}/>
                <FontAwesomeIcon className={"select-none cursor-pointer px-2" + (value < transfers[0].max ? " text-green-600" : " text-brand-grey-alt")} icon={faPlus} onClick={() => { setValue(Math.min((value + 1), transfers[0].max)) }}/>
                items from Warehouse to Shop.
            </div> : null }
            {transfers.length > 1 ? <div>Transfer stock for {transfers.length} variants?</div> : null}
        </div>
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={onClose}>Cancel</div>
            <div className="flex btn" onClick={() => { applyTransfer() }}>Transfer</div>
        </div>
    </Modal>;
}

export default ReplenishmentTransferModal;