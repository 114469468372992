import { useEffect, useRef, useState } from "react";
import { SpinnerCircular } from "spinners-react";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import { useReactToPrint } from "react-to-print";
import DataCache from "../../utilities/DataCache";
import PickListPrint from "../../components/PrintTemplates/PickListPrint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function PickList({ orderIds, onClose }) {
    const [stock, setStock] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState([]);

    // Print handling code
    const [isPrinting, setIsPrinting] = useState(false);
    const printingRef = useRef(null);

    // We store the resolve Promise being used in `onBeforeGetContent` here
    const promiseResolveRef = useRef(null);

    // We watch for the state to change here, and for the Promise resolve to be available
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = useReactToPrint({
        content: () => printingRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        }
    });

    useEffect(() => {
        applySearch({}, true);
    }, []);

    const applySearch = () => {
        setLoading(true);
        APIFetch("POST", "pick", orderIds )
        .then(result => {
            if(result.ok) {
                setStock(result.data);
            } else {
                setStock([]);
                setMessage("An error occurred when attempting to load the stock data");
            }

            setLoading(false);
        })
        .catch(() => {
            setStock([]);
            setMessage("An error occurred when attempting to load the stock data");
            setLoading(false);
        })
    }

    const toggleRow = (stock, value) => {
        if(value) {
            setSelected([...selected, stock]);
        } else {
            setSelected(selected.filter(s => s !== stock));
        }
    }

    return (
        <div className="max-h-full min-h-0">
            { isPrinting ? <PickListPrint 
                ref={printingRef}
                items={stock}
            /> : null }
            <MessageModal message={message} onClose={() => setMessage(null)} />
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <div className="relative content flex flex-col p-8 pb-4 flex-grow">
                    <div className="flex flex-row items-center ml-2 mt-9 mb-16 flex-wrap">
                        <FontAwesomeIcon icon={faChevronLeft} className="text-xl mr-3 mb-1 cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => onClose()}/>
                        <h1 className="text-left font-medium text-lg">Pick List</h1>
                        <div className="flex btn px-2 ml-5" onClick={() => handlePrint()}>
                            Print List
                        </div>
                    </div>
                    <div className="flex flex-col flex-grow overflow-y-auto">
                        {loading ? <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                            <SpinnerCircular size={50} color="#24272b" secondaryColor="white" />
                        </div> : null }
                        { !loading && stock && stock.length === 0 ? <div>No stock to pick.</div> : null }
                        { stock && stock.length > 0 ? <div className=" text-left grid grid-cols-[auto_auto_auto_auto_auto_90px_90px_90px]">
                            <div className="p-3 font-bold sticky top-0 bg-white">Barcode</div>
                            <div className="p-3 font-bold sticky top-0 bg-white">Code</div>
                            <div className="p-3 font-bold sticky top-0 bg-white">Product</div>
                            <div className="p-3 font-bold sticky top-0 bg-white">Options</div>
                            <div className="p-3 font-bold sticky top-0 bg-white text-center">Location</div>
                            <div className="p-3 font-bold sticky top-0 bg-white text-center">Expected</div>
                            <div className="p-3 font-bold sticky top-0 bg-white text-center">To Pick</div>
                            <div className="p-3 font-bold sticky top-0 bg-white text-center">Picked</div>
                            {
                                stock.map(s => {
                                    let bgClass = "flex p-3 border-b border-brand-grey text-sm font-medium";

                                    return [
                                        <div key={s.id + "barcode"} className={"items-center " + bgClass}>{s.item.barcode}</div>,
                                        <div key={s.id + "productcode"} className={"items-center " + bgClass}>{s.item.productCode}</div>,
                                        <div key={s.id + "productname"} className={"items-center " + bgClass}>{s.item.description}</div>,
                                        <div key={s.id + "options"} className={" flex flex-col justify-center " + bgClass}>
                                            {s.item.variations.map((v, i) => {
                                                return <div key={s.id + "options" + i} className="whitespace-nowrap">
                                                    {v.name}: {v.value}
                                                </div>
                                            })}
                                        </div>,
                                        <div key={s.id + "location"} className={"items-center justify-center " + bgClass}>{s.locationId > 0 ? DataCache.locationLookup[s.locationId].name : ""}</div>,
                                        <div key={s.id + "topick"} className={"items-center justify-center " + bgClass}>{s.expectedQuantity}</div>,
                                        <div key={s.id + "topick"} className={"items-center justify-center " + bgClass}>{s.quantity}</div>,
                                        <div key={s.id + "selector"} className={"justify-center " + bgClass}>
                                            <input type="checkbox" checked={selected.includes(s)} onChange={(e) => { toggleRow(s, e.target.checked)}} className=""></input>
                                        </div>
                                    ]
                                })
                            }
                        </div> : null }
                        <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                            <SpinnerCircular enabled={!stock} size={50} color="#24272b" secondaryColor="white" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PickList;