import { useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const PendingAssignmentsModal = ({onClose, onSuccess, pendingAssignments}) => {
    const [message, setMessage] = useState(null);
    const [failed, setFailed] = useState(null);

    const performAssignments = () => {
        APIFetch('POST', `order/assignbatch`, pendingAssignments)
        .then(result => {
            if(result.ok) {
                if(result.data.failed.length > 0) {
                    // One or more assignments failed, list them here
                    setFailed(result.data.failed)
                } else {
                    onSuccess();
                }
            } else {
                setMessage("An error occurred when assigning stock.");
            }
        })
        .catch(e => {
            setMessage("An error occurred when assigning stock.");
        })
    }

    if(failed && failed.length > 0) {
        return <Modal onClose={onClose} heading="Assignments Failed" className="text-black text-center" windowClassName="w-[450px]">
            <div className="text-center mb-4">Failed to assign stock to the following orders:</div>
            <div className="overflow-y-auto">
                {failed.map(a => <div>
                    {a.orderId}
                </div>)}
            </div>
            <div className="flex flex-row justify-center mt-8">
                <div className="flex btn" onClick={() => { onClose() }}>OK</div>
            </div>
        </Modal>;
    }

    return <Modal onClose={onClose} heading="Fulfill Pending Orders" className="text-black text-center" windowClassName="w-[450px]">
        {message ? <MessageModal message={message} onClose={() => setMessage(null)} /> : null}
        <div className="overflow-y-auto">
            {[...new Set(pendingAssignments.map(a => a.orderId))].map(a => <div>
                {a}
            </div>)}
        </div>
        <div className="text-center mt-8">{pendingAssignments.length || 0} items of stock will be assigned</div>
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={onClose}>Skip</div>
            <div className="flex btn" onClick={() => { performAssignments() }}>Assign</div>
        </div>
    </Modal>;
}

export default PendingAssignmentsModal;