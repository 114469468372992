import { useEffect, useState } from "react";
import { SpinnerCircular } from "spinners-react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const TillSelectModal = ({ onClose, onSuccess }) => {
    const [message, setMessage] = useState(null);
    const [tills, setTills] = useState(null);

    useEffect(() => {
        APIFetch("GET", "till")
        .then(result => {
            if(result.ok) {
                setTills(result.data);
            } else {
                setMessage("An error occurred when loading available tills.");
            }
        })
        .catch(() => {
            setMessage("An error occurred when loading available tills.");
        })
    }, []);

    return <Modal onClose={onClose} heading={'Select Till'} className="text-left flex flex-col" windowClassName="w-[450px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        {tills ? tills.map(t => {
            return <div className="text-center border my-2 py-1 cursor-pointer" onClick={() => { onSuccess(t.id) }}>
                {t.name}
            </div>
        }) : null}
        <SpinnerCircular enabled={(!tills)} size={50} color="#24272b" secondaryColor="white" />
        <div className="flex flex-row mt-12 justify-center">
            <div className="flex btn" onClick={() => onClose()}>Cancel</div>
        </div>
    </Modal>;
}

export default TillSelectModal;