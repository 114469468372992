import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const TemplateImportModal = ({onClose, onImport}) => {
    const [templates, setTemplates] = useState([]);
    const [selected, setSelected] = useState([]);
    const [variantCount, setVariantCount] = useState(0);

    useEffect(() => {
        APIFetch("GET", "variationtemplate")
        .then(result => {
            if(result.ok) {
                setTemplates(result.data);
            } else {
                setTemplates([]);
            }
        })
        .catch(() => {
            setTemplates([]);
        })
    }, []);

    const deselect = (template) => {
        let newSelected = selected.filter(t => t !== template);
        setSelected(newSelected);

        var count = 0;

        newSelected.forEach(t => {
            if(!t.values || t.values.length === 0) {
                return;
            }

            if(count === 0) {
                count += t.values.length;
            } else {
                count *= t.values.length;
            }
        })

        setVariantCount(count);
    }

    const select = (template) => {
        if(selected.length > 1) {
            return;
        }

        let newSelected = [template].concat(selected);
        setSelected(newSelected);
        
        var count = 0;

        newSelected.forEach(t => {
            if(!t.values || t.values.length === 0) {
                return;
            }

            if(count === 0) {
                count += t.values.length;
            } else {
                count *= t.values.length;
            }
        })

        setVariantCount(count);
    }

    return <Modal onClose={onClose} heading="Select up to 2 Size Scales To Import" className="text-left" windowClassName="w-[525px]">
        <div className="text-center text-red-600 mb-8">ALL EXISTING VARIANTS WILL BE IRREVERSIBLY DELETED!</div>
        <div className="overflow-y-auto">
            {!templates || templates.length == 0 ? <div>No size scales found.</div> :
                templates.map(t => {
                    let isSelected = selected.indexOf(t) > -1;
                    return <div className="flex flex-row">
                        <input className="mr-2" type="radio" checked={isSelected} onClick={isSelected ? () => deselect(t) : () => select(t)}/>
                        <div>{t.templateName} ({t.values.length})</div>
                    </div>;
                })
            }
        </div>
        <div className="text-center mt-8">{variantCount || 0} Variants will be generated</div>
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={onClose}>Cancel</div>
            <div className="flex btn" onClick={() => { onImport(selected) }}>Import</div>
        </div>
    </Modal>;
}

export default TemplateImportModal;