import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import DataCache from "../../utilities/DataCache";
import APIFetch from "../../utilities/APIFetch";
import MessageModal from "../../components/MessageModal";
import OverlaySpinner from "../../components/OverlaySpinner";

const TillStockCorrectionModal = ({ onClose, onSuccess, orderItem }) => {
    const [location, setLocation] = useState(DataCache.location.find(l => l.id !== orderItem.stockFrom));
    const [stock, setStock] = useState({});
    const [newStock, setNewStock] = useState({});
    const [message, setMessage] = useState();
    const [waiting, setWaiting] = useState(true);

    useEffect(() => {
        var s = {};

        DataCache.location.forEach(l => {
            s[l.id] = 0;
        });

        setNewStock(s);

        // Get Stock Data
        APIFetch('POST', 'stock/lookup', [orderItem.variantId])
        .then(result => {
            if(result.ok) {
                const realStock = {};
                const newRealStock = {};

                result.data.forEach(s => {
                    realStock[s.locationId] = s.quantity;
                    
                    if(s.locationId === location.id) {
                        // New location selected - pre-remove stock
                        newRealStock[s.locationId] = s.quantity - 1;
                    } else if(s.locationId === orderItem.stockFrom) {
                        // Old location, restore stock item
                        newRealStock[s.locationId] = s.quantity + 1;
                    } else {
                        // All other locations
                        newRealStock[s.locationId] = s.quantity;
                    }
                });

                if(!newRealStock[orderItem.stockFrom]) {
                    newRealStock[orderItem.stockFrom] = 1;
                }

                setStock(realStock);
                setNewStock(newRealStock);
            } else {
                setMessage("Failed to load stock data.");
            }
        })
        .catch(e => {
            setMessage("Failed to load stock data.");
        })
        .finally(() => setWaiting(false));
    }, []);

    const onStockChange = (loc, quantity) => {
        var updatedStock = {...newStock};
        updatedStock[loc.id] = quantity;
        setNewStock(updatedStock);
    }

    const onLocationChange = (value) => {
        var newLoc = DataCache.locationLookup[value];
        var updatedStock = { ...newStock };

        // Add 1 stock to previous value
        updatedStock[location.id] = updatedStock[location.id] ? updatedStock[location.id] + 1 : 1;

        // Remove 1 stock from new value
        updatedStock[value] = updatedStock[value] ? updatedStock[value] - 1 : 0;

        setLocation(newLoc);
        setNewStock(updatedStock);
    }

    const onSave = () => {
        if(location.id === orderItem.stockFrom) {
            setMessage("Cannot reassign stock to the same location.");
            return;
        }

        // Check new stock level > 0
        if(newStock[location.id] === 0) {
            setMessage("Cannot take stock from an empty location. Please correct your stock levels.");
            return;
        }
        
        if(!waiting) {
            setWaiting(true);
            var stockChanges = [];

            // Add adjustment for each stock
            Object.keys(newStock).forEach(k => {
                if(newStock[k] !== stock[k]) {
                    // Add extra to location which will then lose stock on assigning
                    stockChanges.push({ variantId: orderItem.variantId, locationId: k, adjustment: location.id == k ? newStock[k] - stock[k] + 1 : newStock[k] - stock[k] });
                }
            });

            // If no adjustment exists for new location, add 1 to stock so that final stock is correct
            if(!stockChanges.some(c => c.locationId == location.id)) {
                stockChanges.push({ variantId: orderItem.variantId, locationId: location.id, adjustment: 1 });
            }

            APIFetch('POST', `order/reassign`, { itemId: orderItem.id, locationId: location.id, stockAdjustments: stockChanges})
            .then(result => {
                if(result.ok) {
                    onSuccess(location.id);
                } else {
                    setMessage("Failed to reassign stock, all changes rolled back.");
                }
            })
            .catch(e => {
                setMessage("Failed to reassign stock, all changes rolled back.");
            })
            .finally(() => setWaiting(false));
        }
    }

    return <Modal onClose={onClose} heading={'Update Stock Location'} className="text-black text-left flex flex-col">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { waiting ? <OverlaySpinner /> : null }
        <label className="">New Location: </label>
        <select className="rounded p-1 px-2 text-black border border-brand flex-1 mb-2 mt-2 w-full" value={location.id} onChange={(e) => { 
            onLocationChange(e.target.value);
        }}>
            {DataCache.location ? DataCache.location.map(l => {
                return <option key={l.id} value={l.id}>{DataCache.locationLookup[l.id].name}</option>
            }) : null}
        </select>
        <label className="mt-8 mx-auto text-red-500 font-bold">NOTE: The stock AFTER picking ALL items</label>
        {DataCache.location ? DataCache.location.map(l => {
            var change = (newStock[l.id] || 0) - (stock[l.id] || 0);
            return <>
                <label className="mt-4">Current {l.name} Stock{change > 0 || change < 0 ? ` (${change > 0 ? '+' : ''}${change})` : ""}: </label>
                <input type="number" value={newStock[l.id]} className="" onChange={(e) => { onStockChange(l, e.target.value) }}/>
            </>
        }) : null}
        <div className="flex flex-row justify-center mt-12">
            <div className="flex btn mr-4" onClick={() => onClose()}>Cancel</div>
            <div className="flex btn" onClick={() => onSave()}>Save</div>
        </div>
    </Modal>;
}

export default TillStockCorrectionModal;