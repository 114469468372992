import { useCallback, useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { SpinnerCircular } from "spinners-react";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import DataCache from "../../utilities/DataCache";
import TransferModal from "../../components/TransferModal";
import { useNavigate } from "react-router-dom";
import ProductDetails from "../ProductDetails/ProductDetails";
import appConfig from "../../appConfig.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import LabelConfigModal from "../ProductDetails/LabelConfigModal";
import { useReactToPrint } from "react-to-print";
import LabelPrint from "../../components/PrintTemplates/LabelPrint";

function StockArrivals() {
    const [stock, setStock] = useState(null);
    const [message, setMessage] = useState(null);
    const [departments, setDepartments] = useState([...DataCache.department].sort((a, b) => a.name.localeCompare(b.name)));
    const [categories, setCategories] = useState([...DataCache.category].sort((a, b) => a.departmentName.localeCompare(b.departmentName) || a.name.localeCompare(b.name)));
    const [locations, setLocations] = useState([...DataCache.location].sort((a, b) => a.name.localeCompare(b.name)));
    const [loading, setLoading] = useState(true);
    const [complete, setComplete] = useState(false);
    const loader = useRef(null);
    const navigate = useNavigate();
    const [displayProduct, setDisplayProduct] = useState(null);
    const [selected, setSelected] = useState([]);
    const [labelConfigModal, setLabelConfigModal] = useState(false);
    const [labelSkip, setLabelSkip] = useState(0);
    const [printLabels, setPrintLabels] = useState([]);

    // Filters
    const [searchText, setSearchText] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("");
    const [locationFilter, setLocationFilter] = useState("");
    const [departmentFilter, setDepartmentFilter] = useState("");

    // Stock transfer
    const [transfer, setTransfer] = useState(null);
        
    // Print handling code
    const [isPrinting, setIsPrinting] = useState(false);
    const printingRef = useRef(null);

    // We store the resolve Promise being used in `onBeforeGetContent` here
    const promiseResolveRef = useRef(null);

    // We watch for the state to change here, and for the Promise resolve to be available
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = useReactToPrint({
        content: () => printingRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
            setLabelSkip(0);
            setPrintLabels(null); 
        }
    });

    useEffect(() => {
        applySearch({}, true);
    }, []);

    const applySearch = (filterOverride, clear = false) => {
        setLoading(true);
        var query = { count: 50 };

        if(stock && stock.length > 0) {
            query.lastDate = stock[stock.length - 1].updatedAt;
        }

        if(searchText.length > 0) {
            query.TextSearch = searchText;
        }

        if(departmentFilter) {
            query.departmentId = departmentFilter;
        }

        if(categoryFilter) {
            query.categoryId = categoryFilter;
        }

        if(locationFilter) {
            query.locationId = locationFilter;
        }

        if(filterOverride) {
            query = {...query, ...filterOverride};
        }

        APIFetch("POST", "stock/arrivals", query)
        .then(result => {
            if(result.ok) {
                if(clear || !stock) {
                    setStock(result.data);
                } else {
                    setStock(stock.concat(result.data));
                }

                if(result.data.length < query.count) {
                    setComplete(true);
                } else {
                    setComplete(false);
                }

                setSelected([]);
            } else {
                setStock([]);
                setSelected([]);
                setMessage("An error occurred when attempting to load the stock data");
                setComplete(true);
            }

            setLoading(false);
        })
        .catch(() => {
            setStock([]);
            setMessage("An error occurred when attempting to load the stock data");
            setLoading(false);
            setComplete(true);
        })
    }

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (!loading  && !complete && target.isIntersecting) {
          applySearch();
        }
      }, [complete, loading, applySearch]);

    useEffect(() => {
        const option = {
          root: null,
          rootMargin: "20px",
          threshold: 0
        };

        const observer = new IntersectionObserver(handleObserver, option);
        const current = loader.current;
  
        if (current) observer.observe(current);
    
        return () => {
          if (current) observer.unobserve(current);
        }
    }, [complete, loading, handleObserver]);

    const detectEnter = ({key}) => {
        if(key === "Enter") {
            applySearch({}, true);
        }
    }

    const updateLocationFilter = (e) => {
        setLocationFilter(e.target.value);
        applySearch({ locationId: e.target.value || null }, true);
    }

    const updateDepartmentFilter = (e) => {
        setDepartmentFilter(e.target.value);

        if(!categoryFilter || !DataCache.categoryLookup[categoryFilter] || !(DataCache.categoryLookup[categoryFilter].departmentId == e.target.value)) {
            setCategoryFilter("");
            applySearch({ departmentId: e.target.value || null, categoryId: null }, true);
        } else {
            applySearch({ departmentId: e.target.value || null }, true);
        }
    }

    const updateCategoryFilter = (e) => {
        setCategoryFilter(e.target.value);
        applySearch({ categoryId: e.target.value || null }, true);
    }

    const startTransfer = (s) => {
        setTransfer(s);
    }

    const viewProduct = (id) => {
        //navigate(`/product/${id}`);
        setDisplayProduct(id);
    }

    const clearFilters = () => {
        if(searchText === "" && locationFilter === "" && categoryFilter === "" && departmentFilter === "") return;
        setSearchText("");
        setLocationFilter("");
        setCategoryFilter("");
        setDepartmentFilter("");
        applySearch({ categoryId: null, locationId: null, departmentId: null, TextSearch: "" }, true);
    }

    const toggleRow = (stock, value) => {
        if(value) {
            setSelected([...selected, stock]);
        } else {
            setSelected(selected.filter(s => s !== stock));
        }
    }

    const initiatePrint = (s) => {
        var labels = [];

        (s ? [s] : selected).forEach(s => {
            var remaining = s.totalIn;

            while(remaining > 0) {
                labels.push({
                    product: s.product,
                    variant: s.variant,
                    locationId: s.location.id
                });

                remaining--;
            }
        })

        setPrintLabels(labels);
        setLabelConfigModal(true);
    }

    return (
        <div className="App">
            {displayProduct ? <div className="absolute w-full h-full bg-white z-10"><ProductDetails productId={displayProduct} onBack={() => { setDisplayProduct(null) }}/></div> : null}
            <MessageModal message={message} onClose={() => setMessage(null)} />
            {labelConfigModal ? <LabelConfigModal items={printLabels} onClose={() => { setLabelConfigModal(false); }} onSuccess={(result) => { setLabelConfigModal(false); setLabelSkip(result.skipCount); handlePrint(); }}/> : null }
            { isPrinting ? <LabelPrint 
                ref={printingRef}
                labels={printLabels}
                skip={labelSkip}
            /> : null }
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <Sidebar />
                <div className="relative content flex flex-col p-8 flex-grow">
                    <div className="flex flex-row items-center ml-2 mt-9 mb-16 flex-wrap">
                        <h1 className="text-left font-medium text-lg">Stock</h1>
                        <input type="text"
                            value={searchText} 
                            className="ml-10 mb-1 text-sm" 
                            onChange={(e) => { setSearchText(e.target.value) }} placeholder="Search..."
                            onKeyDown={detectEnter}/>
                        <select className="ml-auto text-sm" value={locationFilter} onChange={updateLocationFilter}>
                            <option value=''>Any location</option>
                            {locations && locations.length > 0 ? locations.map(o => { return <option value={o.id}>{o.name}</option> }): null}
                        </select>
                        <select className="ml-4 text-sm" value={departmentFilter} onChange={updateDepartmentFilter}>
                            <option value=''>Any department</option>
                            {departments && departments.length > 0 ? departments.map(o => { return <option value={o.id}>{o.name}</option> }): null}
                        </select>
                        <select className="ml-4 text-sm" value={categoryFilter} onChange={updateCategoryFilter}>
                            <option value=''>Any category</option>
                            {categories && categories.length > 0 ? (departmentFilter ? categories.filter(c => c.departmentId.toString() === departmentFilter) : categories).map(o => { return <option value={o.id}>{departmentFilter && departmentFilter.length > 0 ? o.name : `${o.departmentName} - ${o.name}`}</option> }): null}
                        </select>
                        <div className="cursor-pointer ml-10 text-sm border-b border-b-brand-grey mt-1 mr-4" onClick={clearFilters}>Clear All</div>
                    </div>
                    <div className="flex flex-col flex-grow overflow-y-auto">
                        {loading ? <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                            <SpinnerCircular size={50} color="#24272b" secondaryColor="white" />
                        </div> : null }
                        { !loading && stock && stock.length === 0 ? <div>No stock to display.</div> : null }
                        { stock && stock.length > 0 ? <div className=" text-left grid grid-cols-[auto_auto_auto_auto_auto_auto_auto_62px]">
                            <div className="p-3 font-bold sticky top-0 bg-white"></div>
                            <div className="p-3 font-bold sticky top-0 bg-white">Date/Time</div>
                            <div className="p-3 font-bold sticky top-0 bg-white">Code</div>
                            <div className="p-3 font-bold sticky top-0 bg-white">Product</div>
                            <div className="p-3 font-bold sticky top-0 bg-white">Options</div>
                            <div className="p-3 font-bold sticky top-0 bg-white">No.</div>
                            <div className="p-3 font-bold sticky top-0 bg-white">Location</div>
                            <div className="p-3 font-bold sticky top-0 bg-white">Print</div>
                            {
                                stock.map(s => {
                                    let bgClass = " bg-slate-400 text-white";

                                    if(s.location.name === "Shop") {
                                        bgClass = "";
                                    } else if(s.location.name === "Warehouse") {
                                        bgClass = " bg-brand-grey-alt text-white";
                                    }

                                    bgClass += " flex p-3 border-b border-brand-grey text-sm font-medium";

                                    return [
                                        <div key={s.id + "selector"} className={"justify-center" + bgClass}>
                                            <input type="checkbox" checked={selected.includes(s)} onChange={(e) => { toggleRow(s, e.target.checked)}} className=""></input>
                                        </div>,
                                        <div className={"flex-col" + bgClass}>
                                            <div>{new Date(s.createdAt).toLocaleDateString()}</div>
                                            <div>{new Date(s.createdAt).toLocaleTimeString()}</div>
                                        </div>,
                                        <div key={s.id + "productcode"} className={"items-center" + bgClass}>{s.product.productCode}</div>,
                                        <div key={s.id + "productname"} className={"cursor-pointer underline items-center" + bgClass} onClick={() => { viewProduct(s.product.id) }}>{s.product.name}</div>,
                                        <div key={s.id + "options"} className={" flex flex-col justify-center" + bgClass}>
                                            {s.variant.variations.map((v, i) => {
                                                return <div key={s.id + "options" + i} className="whitespace-nowrap">
                                                    {v.name}: {v.value}
                                                </div>
                                            })}
                                        </div>,
                                        <div key={s.id + "totalin"} className={"items-center" + bgClass}>{s.totalIn}</div>,
                                        <div key={s.id + "location"} className={"items-center" + bgClass}>{s.location.name}</div>,
                                        <div key={s.id + "actions"} className={"cursor-pointer underline justify-center items-center " + bgClass} onClick={() => {  }}><FontAwesomeIcon icon={faPrint} className="cursor-pointer" onClick={() => initiatePrint(s)} /></div>
                                    ]
                                })
                            }
                        </div> : null }
                        <div ref={loader} className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                            <SpinnerCircular enabled={!stock} size={50} color="#24272b" secondaryColor="white" />
                        </div>
                    </div>
                    {selected.length > 0 ? <div className="absolute w-full bg-white bottom-0 left-0 flex flex-row p-4 items-center justify-center">
                        <div className="font-medium">{selected.length} items selected</div>
                        <div className="flex btn px-2 ml-5" onClick={() => initiatePrint()}>
                            Print Labels
                        </div>
                    </div> : null}
                </div>
            </div>
        </div>
    );
}

export default StockArrivals;