import Modal from "./Modal";


function MessageModal({ onClose, heading, message }) {
    if(!message) {
        return null;
    }

    return <Modal onClose={onClose} heading={heading || 'Notice'} windowClassName="w-[450px]">
        <div className="text-sm text-center">{message}</div>
        <div className="flex flex-row">
            <div className="btn ml-auto mr-auto mt-6" onClick={() => onClose()}>OK</div>
        </div>
    </Modal>;
}

export default MessageModal;