import { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import MessageModal from "../../components/MessageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPrint, faRefresh, faSave, faUpload } from "@fortawesome/free-solid-svg-icons";
import Tabs from "../../components/Tabs";
import ReactToPrint from "react-to-print";
import StockTakeModal from "./StockTakeModal";
import ProductDetails from "../ProductDetails/ProductDetails";
import APIFetch from "../../utilities/APIFetch";
import DataCache from "../../utilities/DataCache";
var pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const tabLookup = ['successful', 'tooMany', 'tooFew', 'missing', 'unnecessary']

function StockTakeResults({ id, results, refreshBody, title, onBack, onSubmit }) {
    const [message, setMessage] = useState(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [processedData, setProcessedData] = useState({});
    const [create, setCreate] = useState(false);
    const [displayProduct, setDisplayProduct] = useState(null);

    const printingRef = useRef();

    const viewProduct = (id) => {
        //navigate(`/product/${id}`);
        setDisplayProduct(id);
    }

    const refresh = () => {
        if(refreshBody) {
            APIFetch('POST', 'stocktake', refreshBody)
            .then(result => {
                if(result.ok) {
                    var itemName = "";
                    var typeName = "Location";
                    
                    if(refreshBody.productId) {
                        typeName = 'Product';
                        itemName = DataCache.productLookup[refreshBody.productId].productCode + " " + DataCache.productLookup[refreshBody.productId].name;
                    } else if(refreshBody.categoryId) {
                        typeName = 'Category';
                        itemName = DataCache.categoryLookup[refreshBody.categoryId].name;
                    } else if(refreshBody.departmentId) {
                        typeName = 'Department';
                        itemName = DataCache.departmentLookup[refreshBody.departmentId].name;
                    }

                    onSubmit(result.data, refreshBody.locationId === -1 ? "All Locations" : DataCache.locationLookup[refreshBody.locationId].name, typeName, itemName, refreshBody);
                }
            })
            .catch(e => {
            })
        }
    }

    useEffect(() => {
        var newData = {
            successful: [],
            tooMany: [],
            tooFew: [],
            missing: [],
            unnecessary: []
        };
        
        Object.keys(results).forEach(r => {
            if(results[r]) {
                if(results[r].quantity === 0) {
                    newData.successful.push(results[r]);
                } else if(results[r].quantity < 0 && results[r].targetQuantity > 0) {
                    newData.tooMany.push(results[r]);
                } else if(results[r].quantity > 0 && results[r].quantity !== results[r].targetQuantity) {
                    newData.tooFew.push(results[r]);
                } else if(results[r].quantity > 0 && results[r].quantity === results[r].targetQuantity) {
                    newData.missing.push(results[r]);
                } else if(results[r].targetQuantity === 0) {
                    newData.unnecessary.push(results[r]);
                }
            }
        });

        setProcessedData(newData);
    }, [results]);

    return (
        <div className="App">
            <MessageModal message={message} onClose={() => setMessage(null)} />
            {displayProduct ? <div className="absolute w-full h-full bg-white z-10"><ProductDetails productId={displayProduct} onBack={() => { setDisplayProduct(null); refresh(); }}/></div> : null}
            {create ? <StockTakeModal onClose={() => { setCreate(false) }} onSuccess={(...args) => { onSubmit(...args); setCreate(false); }} existingId={id}/> : null }
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <Sidebar />
                <div className="content overflow-y-scroll flex flex-col p-4 flex-grow text-left">
                    <div className="hidden">
                        <div ref={printingRef} className="text-black">
                            {processedData && processedData.tooMany && processedData.tooMany.length > 0 ? <div style={{ pageBreakAfter: "always" }} className="p-16">
                                <div className="text-xl font-bold">{new Date(Date.now()).toLocaleDateString()} - {title}</div>
                                <div className="text-xl font-bold mb-4">TOO MANY</div>
                                {
                                    processedData.tooMany.map(r => {
                                        if(r && r.quantity < 0 && r.targetQuantity > 0) {
                                            return(<div key={r} className="flex flex-row pb-2 mb-2 border-b items-center break-inside-avoid">
                                                <div className="basis-3/12 p-1">{r.barcode.match(pattern) ? "No Barcode" : r.barcode}</div>
                                                <div className="basis-5/12 p-1">{r.name}</div>
                                                {r.variations.map(v => <div className="basis-2/12 p-1">
                                                    <div>{v.name}:</div>
                                                    <div className="font-semibold">{v.value}</div>
                                                </div>)}
                                                <div className="ml-auto p-1 font-semibold min-w-[35px]">X{Math.abs(r.quantity)}</div>
                                            </div>);
                                        }
                                    })
                                }
                            </div> : null }
                            {processedData && processedData.tooFew && processedData.tooFew.length > 0 ? <div style={{ pageBreakAfter: "always" }} className="p-16">
                                <div className="text-xl font-bold">{new Date(Date.now()).toLocaleDateString()} - {title}</div>
                                <div className="text-xl font-bold mb-4">TOO FEW</div>
                                {
                                    processedData.tooFew.map(r => {
                                        if(r && r.quantity > 0 && Math.abs(r.quantity) !== r.targetQuantity) {
                                            return(<div key={r} className="flex flex-row pb-2 mb-2 border-b items-center break-inside-avoid">
                                                <div className="basis-3/12 p-1">{r.barcode.match(pattern) ? "No Barcode" : r.barcode}</div>
                                                <div className="basis-5/12 p-1">{r.name}</div>
                                                {r.variations.map(v => <div className="basis-2/12 p-1">
                                                    <div>{v.name}:</div>
                                                    <div className="font-semibold">{v.value}</div>
                                                </div>)}
                                                <div className="ml-auto p-1 font-semibold min-w-[35px]">X{Math.abs(r.quantity)}</div>
                                            </div>);
                                        }
                                    })
                                }
                            </div> : null }
                            {processedData && processedData.missing && processedData.missing.length > 0 ? <div className="p-16">
                                <div className="text-xl font-bold">{new Date(Date.now()).toLocaleDateString()} - {title}</div>
                                <div className="text-xl font-bold mb-4">MISSING</div>
                                {
                                    processedData.missing.map(r => {
                                        if(r && r.quantity > 0 && Math.abs(r.quantity) === r.targetQuantity) {
                                            return(<div key={r} className="flex flex-row pb-2 mb-2 border-b items-center break-inside-avoid">
                                                <div className="basis-3/12 p-1">{r.barcode.match(pattern) ? "No Barcode" : r.barcode}</div>
                                                <div className="basis-5/12 p-1">{r.name}</div>
                                                {r.variations.map(v => <div className="basis-2/12 p-1">
                                                    <div>{v.name}:</div>
                                                    <div className="font-semibold">{v.value}</div>
                                                </div>)}
                                                <div className="ml-auto p-1 font-semibold min-w-[35px]">X{Math.abs(r.quantity)}</div>
                                            </div>);
                                        }
                                    })
                                }
                            </div> : null }
                        </div>
                    </div>
                    <div className="flex flex-col justify-center ml-2 mt-9 mb-2">
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon={faChevronLeft} className="mr-4 cursor-pointer text-lg hover:text-brand-grey-alt" onClick={() => { onBack() }} /> 
                            <h1 className="text-left font-medium text-lg mt-1">Stock Take Results</h1>
                            <ReactToPrint
                                trigger={() => {
                                    return <FontAwesomeIcon icon={faPrint} className="ml-4 text-lg cursor-pointer hover:text-brand-grey-alt" />
                                }}
                                content={() => printingRef.current}
                            />
                            <FontAwesomeIcon icon={faRefresh} className="ml-4 text-lg cursor-pointer hover:text-brand-grey-alt" onClick={() => { setCreate(true) }} />
                        </div>
                        <h2 className="text-left p-0 ml-5 mt-4">{title}</h2>
                    </div>
                    <div className="flex flex-col flex-grow overflow-y-auto p-3 mt-2">
                        <Tabs headings={['Successful Items', 'Too Many Items', 'Too Few Items', 'Missing Items', 'Unnecessary Items']} tab={currentTab} onTabChange={(t) => { setCurrentTab(t) }} className="mb-2"/>
                        {processedData && processedData[tabLookup[currentTab]] ? processedData[tabLookup[currentTab]].map(r => {
                            return <div key={r.barcode} className="flex flex-row border-b border-b-brand-grey p-4 mb-2 mt-0">
                                <div className="mr-4 min-w-[28px]">{currentTab === 0 ? r.targetQuantity : Math.abs(r.quantity)}x</div>
                                <div className="mr-4 basis-[20%] max-w-[200px]">{r.barcode.match(pattern) ? "No Barcode" : r.barcode}</div>
                                {r.productId ? <div className="mr-4 underline basis-[20%] max-w-[300px] cursor-pointer" onClick={() => { viewProduct(r.productId) }}>{r.name}</div> : <div className="mr-4 basis-[20%] max-w-[300px]">{r.name}</div> }
                                {r.variations.sort((a, b) => a.name.localeCompare(b.name)).map(v => <div className="mr-2 basis-[20%] max-w-[200px]">{`${v.name}: ${v.value}`}</div>)}
                            </div>;
                        }) : null }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StockTakeResults;