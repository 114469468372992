import { useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const ROLES = [ 'None', 'Staff', 'Admin' ];

const AccountCreationModal = ({ onClose, heading, onCreate }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState(1);
    const [tillPIN, setTillPIN] = useState('');
    const [forcePasswordChange, setForcePasswordChange] = useState(true);
    const [password, setPassword] = useState('');
    const [waiting, setWaiting] = useState(false);
    const [message, setMessage] = useState(null);

    const create = () => {
        if(name.length === 0) {
            setMessage("A name is required.");
            return;
        }

        if(email.length === 0) {
            setMessage("An email address is required.");
            return;
        }

        if(password.length < 8) {
            setMessage("Password must be atleast 8 characters long.");
            return;
        }
        
        if(!waiting) {
            setWaiting(true);
            APIFetch('POST', 'account/create', { name, email, password, role, tillPIN, forcePasswordChange })
            .then(result => {
                if(result.ok) {
                    onCreate(result.data);
                } else {
                    if(result.status === 409) {
                        if(result.data && result.data.includes("email address")) {
                            setMessage("An account with this email address already exists.");
                        } else if(result.data && result.data.includes("PIN")) {
                            setMessage("An account with this PIN already exists.");
                        }
                    } else {
                        setMessage("Something went wrong when attempting to create the account.");
                    }
                }
            })
            .catch(e => {
                setMessage("Something went wrong when attempting to create the account.");
            })
            .finally(() => {
                setWaiting(false);
            });
        }
    }

    return <Modal onClose={onClose} heading={heading} className="text-black text-left" windowClassName="w-[450px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-col flex-1 mt-2">
            <label>Name: </label>
            <input type="text" value={name} className="flex-1" onChange={(e) => setName(e.target.value)}/>
        </div>
        <div className="flex flex-col flex-1 mt-4">
            <label>Email: </label>
            <input type="text" value={email} className="flex-1" onChange={(e) => setEmail(e.target.value)}/>
        </div>
        <div className="flex flex-col flex-1 mt-4">
            <label>Password:</label>
            <input type="password" value={password} className="flex-1" onChange={(e) => setPassword(e.target.value)}/>
        </div>
        <div className="flex flex-col flex-1 mt-4">
            <label>Role: </label>
            <select className="flex-1" value={role} onChange={(e) => { setRole(parseInt(e.target.value)) }}>
                {ROLES.map((r, i) => {
                    return <option value={i} key={i}>{r}</option>
                })}
            </select>
        </div>
        <div className="flex flex-col flex-1 mt-4">
            <label>Till PIN: </label>
            <input type="text" value={tillPIN} className="flex-1" onChange={(e) => setTillPIN(e.target.value)}/>
        </div>
        <div className="flex flex-row items-center mt-6">
            <input type="checkbox" value={forcePasswordChange} className="rounded p-1 px-2 text-black border border-brand mr-2" onChange={(e) => setForcePasswordChange(!forcePasswordChange)}/>
            <label className="mr-0">Force User to Change Password</label>
        </div>
        <div className="btn mt-8" onClick={create}>Create</div>
    </Modal>;
}

export default AccountCreationModal;