import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Modal({ className, windowClassName, children, onClose, heading }) {
    return <div className={"fixed top-0 left-0 w-full h-full bg-white bg-opacity-50 z-10 flex justify-center items-center overflow-auto " + className}>
        <div className={"relative bg-white box-shadow h-auto max-h-[95vh] flex flex-col px-28 pt-8 pb-14 border-brand-grey border " + windowClassName}>
            <div className="absolute mt-3 top-0 right-0 cursor-pointer" onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} className="text-2xl mr-4 text-brand-grey hover:text-brand-grey-alt" />
            </div>
            {heading ? <div className="text-xl text-brand-grey mt-6 mb-6 font-medium text-center">
                {heading}
            </div> : null }
            {children}
        </div>
    </div>;
}

export default Modal;