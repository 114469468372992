import { useEffect, useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const TillCustomerSourceModal = ({ onClose, onSuccess, onCancel }) => {
    const [message, setMessage] = useState(null);
    const [source, setSource] = useState(null);
    const [sources, setSources] = useState([]);

    useEffect(() => {
        APIFetch("GET", "customersource")
        .then(result => {
            if(result.ok) {
                setSources(result.data);
            } else {
                setSources([]);
            }
        })
        .catch(() => {
            setSources([]);
        })
    }, []);

    const onSubmit = () => {
        if(!source) return;

        onSuccess(source);
    }

    return <Modal onClose={onCancel} heading={'How did you hear about us?'} className="text-black text-left flex flex-col" windowClassName="w-[500px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <select className="" value={source} onChange={(e) => { setSource(e.target.value) }}>
            <option disabled selected>Please select...</option>
            {sources.map(s => {
                return <option value={s.id}>{s.name}</option>
            })}
        </select>
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={() => onClose()}>Skip</div>
            <div className="flex btn" onClick={() => onSubmit()}>OK</div>
        </div>
    </Modal>;
}

export default TillCustomerSourceModal;