import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import MessageModal from "../../components/MessageModal";
import logo from '../../assets/images/chatleys.png';
import APIFetch, { uploadFiles } from "../../utilities/APIFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPhone, faPlus, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import appConfig from "../../appConfig.json";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import SettingsAddressModal from "./SettingsAddressModal";
import SettingsNumbersModal from "./SettingsNumbersModal";
import DataCache from '../../utilities/DataCache';

const get = (obj, property) => {
    if(!obj) return null;
    return obj[property];
}

const options = [
    { value: "0", label: "phone", icon: faPhone },
    { value: "1", label: "whatsapp", icon: faWhatsapp }
];

const objectToFormData = (obj, form, namespace) => {
    var fd = form || new FormData();
    var formKey;
    
    for(var property in obj) {
      if(obj.hasOwnProperty(property)) {

        if((obj[property] instanceof File)) {
            fd.append('files', obj[property]);
        }
        
        if(namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = property;
        }
       
        // if the property is an object, but not a File,
        // use recursivity.
        if(typeof obj[property] === 'object') {
          objectToFormData(obj[property], fd, formKey);
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
        
      }
    }
    
    return fd;
};

function SystemSettings() {
    const [message, setMessage] = useState(null);
    const [originalSettingData, setOriginalSettingData] = useState();
    const [settingData, setSettingData] = useState();
    const [waiting, setWaiting] = useState(false);
    const [newImage, setNewImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [apiLogo, setAPILogo] = useState(null);
    const [editingAddress, setEditingAddress] = useState(null);
    const [editingNumbers, setEditingNumbers] = useState(null);

    const getSettings = () => {
        APIFetch("GET", 'settings')
        .then(result => {
            if(result.ok) {
                var formattedSettings = [...result.data];

                var logoIdx = formattedSettings.findIndex(s => s.name === "logo");

                // Update icon to option
                formattedSettings.forEach(s => {
                    if(s.icon == "1") {
                        s.icon = options[1];
                    } else if(s.name.startsWith("phone_number_")) {
                        s.icon = options[0];
                    }
                })

                if(logoIdx >= 0) {
                    setAPILogo(appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].ASSETS_DOMAIN + formattedSettings[logoIdx].value + `?${new Date().getTime()}`);
                    formattedSettings.splice(logoIdx, 1);
                }

                setSettingData([...formattedSettings]);
                setOriginalSettingData(formattedSettings);
            } else {
                setMessage("An error occurred when attempting to load system settings.");
            }
        })
        .catch(e => {
            setMessage("An error occurred when attempting to load system settings.");
        })
    }

    useEffect(() => {
        getSettings();
    }, []);

    const updateSettingData = (name, value, icon = null) => {
        var newSettingData = [...settingData];
        var match = newSettingData.findIndex(s => s.name == name);

        if(match >= 0) {
            var newSettingDataItem = {...newSettingData[match]};
            newSettingDataItem.value = value;
            if(icon) {
                newSettingDataItem.icon = icon;
            }
            newSettingData[match] = newSettingDataItem;
        } else {
            newSettingData.push({ name, value, icon });
        }

        setSettingData(newSettingData);
    }

    const saveChanges = () => {
        if(settingData && !waiting) {
            setWaiting(true);

            var toSave = [];
            var newVATRate = null;

            for(var i = 0; i < settingData.length; i++) {
                var setting = settingData[i];
                var previous = originalSettingData.find(s => s.name == setting.name);

                if(setting.name === 'vat-rate') {
                    newVATRate = ((setting.value || 0) / 100) + 1;
                }

                if(!previous) {
                    toSave.push({...setting, icon: setting.icon ? setting.icon.value : ""});
                } else {
                    if(previous.value !== setting.value || previous.icon !== setting.icon || setting.isDeleted) toSave.push({...setting, icon: setting.icon ? setting.icon.value : ""});
                }
            }

            var uploadImage = () => {
                var dataObject = { files: [] };
                dataObject.files.push(new File([newImage], 1));
                var fd = objectToFormData(dataObject);

                uploadFiles('POST', `settings/logo`, fd, null)
                .then(result => {
                    if(result.ok) {
                        setMessage("Saved successfully.");
                        getSettings();
                    } else {
                        setMessage("An error occurred while attempting to save the new logo.");
                    }
                })
                .catch(() => {
                    setMessage("An error occurred while attempting to save the new logo.");
                })
                .finally(() => setWaiting(false))
            }

            

            if(toSave.length > 0) {
                APIFetch('POST', 'settings', toSave)
                .then((result) => {
                    if(result.ok) {
                        if(newVATRate) {
                            DataCache.vatRate = newVATRate;
                        }

                        if(newImage) {
                            uploadImage();
                        } else {
                            setMessage("Saved successfully.");
                            getSettings();
                        }
                    } else {
                        setMessage("An error occurred when saving settings.");
                    }
                })
                .catch(() => {
                    setMessage("An error occurred when saving settings.");
                })
                .finally(() => setWaiting(false));
            } else {
                if(newImage) {
                    uploadImage();
                } else {
                    setWaiting(false);
                }
            }
        }
    }

    const fileChangeListener = (e) => {
        setNewImage(e.target.files[0]);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
    }

    const saveSettingChanges = (changes) => {
        setSettingData(changes);
    }

    return (
        <div className="App">
            {editingAddress ? <SettingsAddressModal onClose={() => setEditingAddress(false)} onSave={(changes) => { saveSettingChanges(changes); setEditingAddress(false); }} settings={settingData} /> : null }
            {editingNumbers ? <SettingsNumbersModal onClose={() => setEditingNumbers(false)} onSave={(changes) => { saveSettingChanges(changes); setEditingNumbers(false); }} settings={settingData} /> : null }
            <MessageModal message={message} onClose={() => setMessage(null)} />
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <Sidebar />
                <div className="content overflow-y-scroll flex flex-col p-8 flex-grow text-left">
                    <div className="flex flex-row items-center ml-2 mt-9 mb-16">
                        <h1 className="text-left font-medium text-lg">System Settings</h1>
                        <FontAwesomeIcon icon={faSave} className={"ml-2 cursor-pointer hover:text-brand-grey-alt"} onClick={() => { saveChanges(); }}/>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex flex-row mb-6 w-full border-b border-b-brand-grey pb-10">
                            <div className="flex flex-col">
                                <h2 className="font-bold mb-4">Update logo:</h2>
                                <input type="file" onChange={fileChangeListener} className="border-b-0"/>
                            </div>
                            <div className="flex flex-col">
                                <h2 className="font-bold">{imagePreview ? "New logo:" : "Current logo:"}</h2>
                                <img src={imagePreview || apiLogo || logo} width={250} className="mt-4"/>
                            </div>
                        </div>
                        <div className="flex flex-row w-full border-b border-b-brand-grey mb-8 pb-6 mt-4">
                            <div className="flex flex-col basis-6/12 mr-8">
                                <h2 className="font-bold mb-4">Business Name</h2>
                                <input className="mb-6 max-w-[300px]" type="text" value={settingData && settingData.length ? get(settingData.find(s => s.name === "business-name"), "value")  || "" : ""} onChange={(e) => updateSettingData("business-name", e.target.value)}/>
                                <div className="flex flex-row mb-4 items-center">
                                    <h2 className="font-bold">Address:</h2>
                                    <div className="border-b border-b-brand-grey-active text-brand-grey-active text-sm ml-4 cursor-pointer" onClick={() => setEditingAddress(true)}>Edit</div>
                                </div>
                                {settingData && settingData.length ? <div className="text-brand-grey-active text-sm">{[
                                    get(settingData.find(s => s.name === "address1"), "value") || "",
                                    get(settingData.find(s => s.name === "address2"), "value") || "",
                                    get(settingData.find(s => s.name === "town"), "value") || "",
                                    get(settingData.find(s => s.name === "county"), "value") || "",
                                    get(settingData.find(s => s.name === "postcode"), "value") || ""
                                ].filter(s => s !== "").join(", ") || "No address set."}</div> : null }
                                
                            </div>
                            <div className="flex flex-col flex-grow basis-6/12 ml-8">
                                <div className="flex flex-col">
                                    <div className="flex flex-row items-center mb-4">
                                        <h2 className="font-bold">Contact numbers:</h2>
                                        <div className="border-b border-b-brand-grey-active text-brand-grey-active text-sm ml-4 cursor-pointer" onClick={() => setEditingNumbers(true)}>Edit</div>
                                    </div>
                                    <div className="flex flex-col">
                                        { settingData ? settingData.filter(s => s.name.startsWith("phone_number_") && !s.isDeleted)
                                            .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                                            .map(p => {
                                                return <div className="flex flex-row mb-4 items-center text-brand-grey-active">
                                                    <FontAwesomeIcon icon={p.icon && p.icon.icon ? p.icon.icon : faPhone} className="mr-2" />
                                                    {p.value}
                                                </div>
                                            })
                                        : null }
                                    </div>
                                </div>
                                <div>
                                    { !settingData || !settingData.some(s => s.name.startsWith("phone_number_")) ? <div className="text-sm">No phone numbers configured yet</div> : null }
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mb-6 w-full border-b border-b-brand-grey pb-10">
                            <div className="flex flex-col flex-grow mr-8">
                                <h2 className="font-bold mb-4">VAT Rate (%)</h2>
                                <input className="flex-1 max-w-[75px]" type="number" value={settingData?.length && get(settingData.find(s => s.name === "vat-rate"), "value") !== null ? get(settingData.find(s => s.name === "vat-rate"), "value") : "20"} onChange={(e) => updateSettingData("vat-rate", e.target.value)}/>
                            </div>
                            <div className="flex flex-col flex-grow mr-8">
                                <h2 className="font-bold mb-4">Loyalty Point Value (Pence)</h2>
                                <input className="flex-1 max-w-[75px]" type="number" value={settingData?.length && get(settingData.find(s => s.name === "point-value"), "value") !== null ? get(settingData.find(s => s.name === "point-value"), "value") : "1"} onChange={(e) => updateSettingData("point-value", e.target.value)}/>
                            </div>
                            <div className="flex flex-col flex-grow">
                                <h2 className="font-bold mb-4">Loyalty Points Per £1 Spent</h2>
                                <input className="flex-1 max-w-[75px]" type="number" value={settingData?.length && get(settingData.find(s => s.name === "point-rate"), "value") !== null ? get(settingData.find(s => s.name === "point-rate"), "value") : "1"} onChange={(e) => updateSettingData("point-rate", e.target.value)}/>
                            </div>
                        </div>
                        <div className="flex flex-row w-full justify-evenly">
                            <div className="flex flex-col flex-grow mr-8">
                                <h2 className="font-bold mb-4">Receipt Header Line</h2>
                                <input className="flex-1" type="text" value={settingData && settingData.length ? get(settingData.find(s => s.name === "receipt-header"), "value")  || "" : ""} onChange={(e) => updateSettingData("receipt-header", e.target.value)}/>
                            </div>
                            <div className="flex flex-col flex-grow ml-8">
                                <h2 className="font-bold mb-4">Receipt Thanks Line</h2>
                                <input className="flex-1" type="text" value={settingData && settingData.length ? get(settingData.find(s => s.name === "receipt-thanks"), "value")  || "" : ""} onChange={(e) => updateSettingData("receipt-thanks", e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SystemSettings;