import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { SpinnerCircular } from "spinners-react";
import APIFetch from '../../utilities/APIFetch';
import AccountCard from "../../components/AccountCard";
import EditAccountPanel from "../../components/EditAccountPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../components/Modal";
import AccountCreationModal from "./AccountCreationModal";
import MessageModal from "../../components/MessageModal";

const ROLES = [ 'None', 'Staff', 'Admin' ];

function AccountManagement() {
    const [accounts, setAccounts] = useState(null);
    const [filteredAccounts, setFilteredAccounts] = useState(null);
    const [selected, setSelected] = useState(null);
    const [search, setSearch] = useState('');
    const [creation, setCreation] = useState(false);

    const [message, setMessage] = useState(null);
    const [pendingDelete, setPendingDelete] = useState(null);

    useEffect(() => {
        APIFetch("GET", "account")
        .then(result => {
            if(result.ok) {
                setAccounts(result.data);
            } else {
                setAccounts([]);
            }
        })
        .catch(() => {
            setAccounts([]);
        })
    }, []);

    useEffect(() => {
        if(!search || search.length === 0) {
            setFilteredAccounts(null);
        } else {
            var searchLower = search.toLowerCase();
            setFilteredAccounts(accounts.filter(a => {
                return (a.email || "").toLowerCase().includes(searchLower) || (a.name || "").toLowerCase().includes(searchLower) || a.role.name.toLowerCase().includes(searchLower);
            }));
        }
    }, [search]);

    const reapplyFilter = (newAccounts) => {
        if(filteredAccounts !== null) {
            var searchLower = search.toLowerCase();
            setFilteredAccounts(newAccounts.filter(a => {
                return a.email.toLowerCase().includes(searchLower) || a.name.toLowerCase().includes(searchLower) || a.role.name.toLowerCase().includes(searchLower);
            }));
        }
    }

    const updateAccount = (account) => {
        var newAccounts = [...accounts];
        var index = newAccounts.findIndex(a => a.userId === account.userId);
        newAccounts[index] = account;
        setAccounts(newAccounts);
        reapplyFilter(newAccounts);
    }

    const createAccount = (account) => {
        var newAccounts = [...accounts];
        newAccounts.push(account);
        newAccounts.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        setAccounts(newAccounts);
        reapplyFilter(newAccounts);
        setCreation(false);
    }

    const onEdit = (account) => {
        setSelected(account);
    }

    const onDelete = (account) => {
        if(!pendingDelete || pendingDelete !== account) {
            setPendingDelete(account);
        } else {
            APIFetch("DELETE", `account/deactivate/${account.userId}`)
            .then(result => {
                if(result.ok) {
                    var newAccounts = [...accounts];
                    newAccounts = newAccounts.filter(a => a.userId !== account.userId);
                    setAccounts(newAccounts);
                    reapplyFilter(newAccounts);
                    setPendingDelete(null);
                    setMessage("Account deleted successfully.");
                } else {
                    setMessage("An error occurred when attempting to delete the account.");
                }
            })
            .catch(() => {
                setMessage("An error occurred when attempting to delete the account.");
            })
        }
    }

    const detectEnter = (e) => {
        if(e.key === "Enter") {
            setSearch(e.target.value);
        }
    }

    return (
        <div className="App">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { creation ? <AccountCreationModal onClose={() => setCreation(false)} heading="Create New Account" onCreate={createAccount}/> : null }
        { pendingDelete ? <Modal heading="Confirm Account Deletion" onClose={() => setPendingDelete(null)}  windowClassName="w-[450px]">
            <div className="text-sm">Are you sure you wish to delete this account?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {onDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        <div className="flex flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="flex flex-row items-center ml-2 mt-9 mb-16">
                    <h1 className="text-left font-medium text-lg">Account Management</h1>
                    <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => setCreation(true)}/>
                    <input type="text" placeholder="Search..." className="ml-4 mb-1 w-48" onKeyUp={detectEnter}/>
                </div>
                <div className="min-w-[1000px] md:min-w-[0px] flex flex-row px-3 font-bold mb-2 text-sm mt-2">
                    <div className="flex flex-1 basis-4/12">Name</div>
                    <div className="flex flex-1 basis-4/12">Email</div>
                    <div className="flex flex-1 basis-4/12">Role</div>
                    <div className="flex flex-1 basis-[45px]"></div>
                </div>
                <div className="min-w-[1000px] md:min-w-[0px] flex flex-col flex-grow overflow-y-auto">
                    { accounts && accounts.length === 0 ? <div>Failed to load accounts.</div> : null }
                    { accounts ? (filteredAccounts || accounts).map(a => <AccountCard key={a.userId} account={a} onEdit={onEdit} onDelete={onDelete} selected={a === selected}/>) : null }
                    <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular enabled={!accounts} size={50} color="#24272b" secondaryColor="white" />
                    </div>
                </div>
            </div>
            { selected ? <EditAccountPanel key={selected.id + "edit"} account={selected} onDelete={onDelete} onChange={(a) => { updateAccount(a); }} onClose={() => { setSelected(null) }}/> : null }
        </div>
        </div>
    );
}

export default AccountManagement;