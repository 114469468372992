import { useCallback, useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import MessageModal from "../../components/MessageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import StockTakeModal from "./StockTakeModal";
import StockTakeResults from "./StockTakeResults";
import { SpinnerCircular } from "spinners-react";
import StockTakeItem from "../../components/StockTakeItem";
import APIFetch from "../../utilities/APIFetch";

function StockTakes() {
    const [message, setMessage] = useState(null);
    const [create, setCreate] = useState(false);
    const [results, setResults] = useState(null);
    const [stockTakes, setStockTakes] = useState(null);
    const [complete, setComplete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState("");
    const loader = useRef(null);

    const clearFilters = () => {
        if(searchText === "") return;
        setSearchText("");
        getData(true, { TextSearch: "" });
    }

    useEffect(() => {
        getData();
    }, []);

    const getData = (clear = false, filterOverride = null) => {
        setLoading(true);

        var query = {
            count: 50
        }

        if(!clear && stockTakes && stockTakes.length > 0) {
            query.lastDate = stockTakes[stockTakes.length - 1].createdAt;
        }

        if(searchText.length > 0) {
            query.TextSearch = searchText;
        }

        if(filterOverride) {
            query = {...query, ...filterOverride};
        }

        APIFetch("POST", "stocktake/search", query)
        .then(result => {
            if(result.ok) {
                if(clear || !stockTakes) {
                    setStockTakes(result.data);
                } else {
                    setStockTakes(stockTakes.concat(result.data));
                }

                if(result.data.length < query.count) {
                    setComplete(true);
                } else {
                    setComplete(false);
                }
            } else {
                setStockTakes([]);
                setComplete(true);
            }
            setLoading(false);
        })
        .catch(() => {
            setStockTakes([]);
            setComplete(true);
            setLoading(false);
        })
    }

    const detectEnter = ({key}) => {
        if(key === "Enter") {
            getData(true);
        }
    }

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (!loading  && !complete && target.isIntersecting) {
          getData();
        }
      }, [complete, loading, getData]);

    useEffect(() => {
        const option = {
          root: null,
          rootMargin: "20px",
          threshold: 0
        };

        const observer = new IntersectionObserver(handleObserver, option);
        const current = loader.current;
  
        if (current) observer.observe(current);
    
        return () => {
          if (current) observer.unobserve(current);
        }
      }, [complete, loading, handleObserver]);

    const handleSuccess = (resultData, locationName, typeName, targetName, requestBody) => {
        setCreate(false);
        var failed = false;
        var results = resultData.results;

        Object.keys(results).forEach(key => {
            if(failed) return;
            if(results[key].quantity !== 0) failed = true;
        });

        if(failed) {
            setResults({
                id: resultData.id,
                requestBody: requestBody,
                results: results,
                title: typeName === 'Location' ? `Full stock take in ${locationName}` : `${targetName} in ${locationName}`
            });
        } else {
            setMessage("Stock take completed without errors!");
            setResults(null);
            getData();
        }
    }

    const itemDeleted = () => {
        setMessage("Stock take deleted successfully.");
        APIFetch("GET", "stocktake")
        .then(result => {
            if(result.ok) {
                setStockTakes(result.data);
            } else {
                setStockTakes([]);
            }
        })
        .catch(() => {
            setStockTakes([]);
        });
    }

    if(results) {
        return <StockTakeResults id={results.id} title={results.title} results={results.results} refreshBody={results.requestBody} onSubmit={handleSuccess} onBack={() => { setResults(null); getData(); }}/>;
    }

    return (
        <div className="App">
            {create ? <StockTakeModal onClose={() => { setCreate(false) }} onSuccess={handleSuccess}/> : null }
            <MessageModal message={message} onClose={() => setMessage(null)} />
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <Sidebar />
                <div className="content overflow-y-scroll flex flex-col p-8 flex-grow text-left">
                    <div className="flex flex-row items-center ml-2 mt-9 mb-16">
                        <h1 className="text-left font-medium text-lg">Stock Takes</h1>
                        <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => setCreate(true)}/>
                        <input type="text"
                            value={searchText} 
                            className="ml-10 mb-1 text-sm" 
                            onChange={(e) => { setSearchText(e.target.value) }} placeholder="Search..."
                            onKeyDown={detectEnter}/>
                        <div className="cursor-pointer ml-10 text-sm border-b border-b-brand-grey mt-1" onClick={clearFilters}>Clear</div>
                    </div>
                    <div className="min-w-[1000px] md:min-w-[0px] flex flex-row px-3 font-bold mb-2 text-sm mt-2">
                        <div className="flex flex-1 basis-2/12">Type</div>
                        <div className="flex flex-1 basis-3/12">Selection</div>
                        <div className="flex flex-1 basis-2/12">Location</div>
                        <div className="flex flex-1 basis-2/12">Status</div>
                        <div className="flex flex-1 basis-3/12">Date & Time</div>
                        <div className="flex flex-1 basis-[45px]"></div>
                    </div>
                    <div className="min-w-[1000px] md:min-w-[0px] flex flex-col flex-grow overflow-y-auto">
                        {stockTakes && stockTakes.length === 0 ? <div>No stock takes found.</div> : null}
                        {stockTakes && stockTakes.length > 0 ? stockTakes.map(s => <StockTakeItem item={s} key={s.id} onDelete={itemDeleted}/>) : null}
                        <div ref={loader} className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                            <SpinnerCircular enabled={!stockTakes || !complete} size={50} color="#24272b" secondaryColor="white" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StockTakes;