import { format } from "date-fns";
import { forwardRef } from "react";

function TillAdjustmentPrint({ adjustment }, ref) {
    return <div ref={ref} className="onlyPrint absolute top-0 left-0 w-full max-w-full m-0 p-2 text-sm">
        <div className="font-bold text-center w-100 mb-2">Till Adjustment</div>
        <div className="flex flex-col mt-1">
            <div className="font-bold">Date:</div>
            <div>{format(adjustment.date, "dd/MM/yy HH:mmaaa") }</div>
        </div>
        <div className="flex flex-col mt-1">
            <div className="font-bold">Till:</div>
            <div>{adjustment.till}</div>
        </div>
        <div className="flex flex-col mt-1">
            <div className="font-bold">User:</div>
            <div>{adjustment.user}</div>
        </div>
        <div className="flex flex-col mt-1">
            <div className="font-bold">Amount:</div>
            <div>£{adjustment.amount}</div>
        </div>
       {adjustment.newBalance ? <div className="flex flex-col mt-1">
            <div className="font-bold">New Balance:</div>
            <div>£{adjustment.newBalance}</div>
        </div> : null }
        <div className="flex flex-col mt-1">
            <div className="font-bold">Comment:</div>
            <div>{adjustment.comment}</div>
        </div>
    </div>;
}

export default forwardRef(TillAdjustmentPrint);