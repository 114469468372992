import APIFetch from "./APIFetch";
import DataCache from "./DataCache";

const createItem = (itemType, data) => {
    return APIFetch('POST', `${itemType}`, data)
    .then(result => {
        if(result.ok) {
            DataCache[itemType].push(result.data);
            return { data: result.data };
        } else {
            if(result.status === 409) {
                return `A ${itemType} with this name already exists.`;
            } else {
                return `Something went wrong when attempting to create the ${itemType}.`;
            }
        }
    })
    .catch(e => {
        return `Something went wrong when attempting to create the ${itemType}.`;
    })
}

export default createItem;