import { faPrint, faTags, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import appConfig from "../../appConfig.json";
import APIFetch from "../../utilities/APIFetch";
import MessageModal from "../../components/MessageModal";
import { useReactToPrint } from "react-to-print";
import VoucherPrint from "../../components/PrintTemplates/VoucherPrint";
import CreditNotePrint from "../../components/PrintTemplates/CreditNotePrint";
import { useNavigate } from "react-router-dom";
import DataCache from "../../utilities/DataCache";
import TillStockCorrectionModal from "./TillStockCorrectionModal";

const REASONS = ["Other", "Wrong Size", "Damaged", "Unwanted"];

const getSetting = (settings, name) => {
    var match = settings.find(s => s.name === name);
    if(!match) return null;
    return match.value;
}

const OrderDetails = ({ order, discounts, itemDiscounts, vouchers, settings, till, setOrder, parseOrder, setShowDiscount, setItemFrom, isPrint = false, isRefund = false, itemsOnly = false, transactionsOnly = false }) => {
    const navigate = useNavigate();

    const [message, setMessage] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [voucherToPrint, setVoucherToPrint] = useState(null);
    const [creditNoteToPrint, setCreditNoteToPrint] = useState(null);

    const [changingStockSource, setChangingStockSource] = useState(null);

    // Print handling code
    const [isPrinting, setIsPrinting] = useState(false);
    const printingRef = useRef(null);

    // We store the resolve Promise being used in `onBeforeGetContent` here
    const promiseResolveRef = useRef(null);

    // We watch for the state to change here, and for the Promise resolve to be available
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = useReactToPrint({
        content: () => printingRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
            setVoucherToPrint(null);
            setCreditNoteToPrint(null);
        }
    });

    const removeItem = (item) => {
        if(!waiting) {
            setWaiting(true);
            var url = `order/${order.id}/item/${item.id}`;
            var error = "An error occurred when attempting to delete this item.";

            if(item.openDepartmentId) {
                url = `order/${order.id}/dept/${item.openDepartmentId}`;
                error = "An error occurred when attempting to delete this open department entry.";
            }

            APIFetch('DELETE', url)
            .then(result => {
                if(result.ok) {
                    parseOrder(result.data);
                    setWaiting(false);
                } else {
                    setMessage(error);
                    setWaiting(false);
                }
            })
            .catch(e => {
                setMessage(error);
                setWaiting(false);
            });
        }
    }

    const removeDiscount = (discount) => {
        APIFetch("DELETE", `order/${order.id}/discount/${discount.id}`)
        .then(result => {
            if(result.ok) {
                parseOrder(result.data);
            } else {
                setMessage("An error occurred when attempting to remove the discount.");
            }
        })
        .catch(e => {
            setMessage("An error occurred when attempting to remove the discount.");
        });
    }
    
    const removeItemDiscount = (id, discountId) => {
        APIFetch("DELETE", `order/${order.id}/discount/${discountId}`)
        .then(result => {
            if(result.ok) {
                parseOrder(result.data);
            } else {
                setMessage("An error occurred when attempting to remove the discount.");
            }
        })
        .catch(e => {
            setMessage("An error occurred when attempting to remove the discount.");
        });
    }

    const removeVoucher = (voucher) => {
        APIFetch("DELETE", `order/${order.id}/voucher/${voucher.id}`)
        .then(result => {
            if(result.ok) {
                parseOrder(result.data);
            } else {
                setMessage("An error occurred when attempting to remove the voucher.");
            }
        })
        .catch(e => {
            setMessage("An error occurred when attempting to remove the voucher.");
        });
    }

    const toggleVAT = () => {
        APIFetch('POST', `order/${order.id}/vat/${order.vatPayable ? 'false' : 'true'}`)
        .then(result => {
            if(result.ok) {
                parseOrder(result.data);
            } else {
                setMessage("An error occurred when attempting to toggle VAT.");
            }
        })
        .catch(e => {
            setMessage("An error occurred when attempting to toggle VAT.");
        });
    }

    const removeTransaction = (t) => {
        if(!waiting) {
            setWaiting(true);
            APIFetch('DELETE', `transaction/${t.id}`)
            .then(result => {
                if(result.ok) {
                    var newOrder = {...order};
                    newOrder.transactions = newOrder.transactions.filter(a => a.id !== t.id).sort((a, b) => {
                        return a.createdAt.localeCompare(b.createdAt)
                    });
                    setOrder(newOrder);
                } else {
                    setMessage("An error occurred when attempting to delete the transaction.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when attempting to delete the transaction.");
            })
            .finally(() => {
                setWaiting(false);
            });
        }
    }

    const toggleTransactionType = (t) => {
        if(!waiting) {
            setWaiting(true);
            APIFetch('POST', `transaction/${t.id}/type/${t.type === 'Cash' ? 'Card' : 'Cash'}`)
            .then(result => {
                if(result.ok) {
                    var newOrder = {...order};
                    newOrder.transactions = [...newOrder.transactions.filter(a => a.id !== t.id), result.data].sort((a, b) => {
                        return a.createdAt.localeCompare(b.createdAt)
                    });
                    setOrder(newOrder);
                } else {
                    setMessage("An error occurred when attempting to change transaction type.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when attempting to change transaction type.");
            })
            .finally(() => {
                setWaiting(false);
            });
        }
    }

    const deleteNote = (id) => {
        if(!waiting) {
            setWaiting(true);
            APIFetch('DELETE', `order/${order.id}/note/${id}`)
            .then(result => {
                if(result.ok) {
                    var newOrder = {...order};
                    newOrder.notes = newOrder.notes.filter(n => n.id !== id);
                    setOrder(newOrder);
                } else {
                    setMessage("An error occurred when deleting the note.");
                }

                setWaiting(false);
            })
            .catch(e => {
                setMessage("An error occurred when deleting the note.");
                setWaiting(false);
            });
        }
    }

    const deleteShipment = (id) => {
        if(!waiting) {
            setWaiting(true);
            APIFetch('DELETE', `shipping/${id}`)
            .then(result => {
                if(result.ok) {
                    var newOrder = {...order};
                    newOrder.shipments = newOrder.shipments.filter(n => n.id !== id);
                    setOrder(newOrder);
                } else {
                    setMessage("An error occurred when deleting the shipment.");
                }

                setWaiting(false);
            })
            .catch(e => {
                setMessage("An error occurred when deleting the shipment.");
                setWaiting(false);
            });
        }
    }

    const assignStock = (item) => {
        APIFetch('POST', `order/assign`, { orderId: order.id, itemId: item.id, preferredLocationId: till.locationId})
        .then(result => {
            if(result.ok) {
                var newOrder = {...order};
                var newItems = [...newOrder.items];
                newItems.forEach(i => {
                    if(i.id == item.id) {
                        i.stockId = result.data.stockId;
                    }
                });
                setOrder(newOrder);
                setMessage("This item has been assigned stock successfully.");
            } else if(result.status == 404) {
                setMessage("One or more items are out of stock.");
            } else {
                setMessage('An error occurred when attempting to assign stock.');
            }
        })
        .catch(e => {
            setMessage('An error occurred when attempting to assign stock.');
        });
    }

    const printVoucher = (v) => {
        setVoucherToPrint(v);
        handlePrint();
    }

    const printCreditNote = (c) => {
        setCreditNoteToPrint(c);
        handlePrint();
    }

    const handleReference = (t) => {
        if(t.type.includes('Credit Note')) {
            // Get Credit Note Details, then print
            APIFetch('GET', `creditnote/${t.reference}`)
            .then((result) => {
                if(result.ok) {
                    printCreditNote(result.data);
                } else {
                    setMessage("Failed to retreive credit note details.");
                }
            })
            .catch(() => { setMessage("Failed to retreive credit note details."); });
        } else if(t.type.includes('Voucher')) {
            // Get Voucher Details, then print
            APIFetch('GET', `voucher/${t.reference}`)
            .then((result) => {
                if(result.ok) {
                    printVoucher(result.data);
                } else {
                    setMessage("Failed to retreive voucher details.");
                }
            })
            .catch(() => { setMessage("Failed to retreive voucher details."); });
        } else if(t.type === "Refund - Order Credit") {
            navigate(`/till/${till.id}/${t.reference}`, { state: { backUrl: '/tills' } });
        }
    }

    var totalPaid = order && order.transactions ? order.transactions.reduce((acc, o) => {return acc + (parseFloat(o.amount) >= 0 ? parseFloat(o.amount) : 0) }, 0)  : null;
    var balance = order ? (order.total - totalPaid) : 0;
    if(balance < 0.001 && balance > -0.001) balance = 0;

    var itemBlocks = order && order.items && order.items.length > 0 ? 
        order.items.map(i => {
            var imgPath = i.image ? i.image.startsWith("http") ? i.image : appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].ASSETS_DOMAIN + i.image : "";

            return <div key={i.id} className="flex flex-col border-b border-b-brand-grey-medium py-4 print:py-0">
                <div className="flex flex-row print:text-xs">
                    <div className="basis-1/12 p-2 print:p-1 noPrint"> {i.image ? <img src={imgPath} /> : null }</div>
                    <div className={"basis-1/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " noPrint" : " print:basis-2/12")}>{i.barcode}</div>
                    <div className={"basis-2/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " relative flex flex-col print:basis-6/12" : " relative flex flex-col")}>
                    <div className={isPrint && i.variations && i.variations.length > 0 ? "mb-3" : ""}>{i.openDepartmentId && isPrint ? i.description : i.productCode}</div>
                        {isPrint && i.variations && i.variations.length > 0 ? <div className="absolute top-5 w-[100vw] text-[10px]">
                            {i.variations ? i.variations.sort((a, b) => a.name.localeCompare(b.name)).map((v, i) => {
                                if(i === 0) {
                                    return <span>{v.name}: {v.value}</span>
                                }

                                return <span className=""> / {v.name}: {v.value}</span>
                            }) : null }
                        </div> : null }
                    </div>
                    <div className={"basis-4/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " noPrint" : " print:basis-4/12")}>
                        <div>{i.description}</div>
                        <div className="flex flex-row text-sm print:text-xs">
                            {i.variations ? i.variations.sort((a, b) => a.name.localeCompare(b.name)).map((v, i) => {
                                if(i === 0) {
                                    return <div>{v.name}: <span className="font-medium">{v.value}</span></div>
                                }

                                return <div className="whitespace-pre">  /  {v.name}: <span className="font-medium">{v.value}</span></div>
                            }) : null }
                        </div>
                        { i.inStock || i.openDepartmentId ? null : <div className="text-sm print:text-xs text-red-600 noPrint">Insufficient stock</div>}
                        { i.inStock && !i.openDepartmentId && !i.stockId && order.status === 'Deposit Paid' ? <div className="text-sm print:text-xs text-blue-600 cursor-pointer noPrint" onClick={() => { assignStock(i) }}>Assign Stock</div> : null }
                        { i.shipmentId ? <div className="text-sm print:text-xs text-blue-600 noPrint">Shipped - {i.shipmentDate ? new Date(i.shipmentDate).toLocaleDateString() : "Unknown Date"}</div> : null}
                        { i.returnItemId ? <div className="text-sm print:text-xs text-red-600 noPrint">Returned - {REASONS[i.returnReason] || 'Other'}</div> : null}
                        { i.stockFrom ? <div className="text-sm print:text-xs text-blue-600 noPrint cursor-pointer" onClick={() => { setChangingStockSource(i) }}>
                            Stock from {DataCache.locationLookup[i.stockFrom].name}
                        </div> : null}
                    </div>
                    <div className="basis-1/12 p-2 print:p-1 noPrint">{i.status}</div>
                    <div className={"basis-1/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " print:basis-3/12" : " print:basis-2/12")}>£{i.price ? i.price.toFixed(2) : '0.00'}</div>
                    <div className={"basis-1/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " print:basis-3/12" : " print:basis-2/12")}>£{i.price && i.vat ? (i.price + i.vat).toFixed(2) : '0.00'}</div>
                    <div className="basis-1/12 p-2 print:p-1 flex flex-row noPrint">
                        {order.status !== 'Completed' && !i.openDepartmentId ? <FontAwesomeIcon icon={faTags} className="cursor-pointer mr-2 ml-auto" onClick={() => { setShowDiscount(i.id) }}/> : null }
                        {order.status !== 'Completed' ? <FontAwesomeIcon icon={faTrash} className={i.openDepartmentId ? "cursor-pointer mr-2 ml-auto" : "cursor-pointer mr-2"} onClick={() => { removeItem(i) }}/> : null }
                    </div>
                </div>
                {itemDiscounts && itemDiscounts[i.id] ?
                    itemDiscounts[i.id].map(d => {
                        return <div className="flex flex-row print:text-xs">
                            <div className={"basis-9/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " py-0 print:basis-6/12" : " py-0 print:basis-8/12")}></div>
                            <div className={"basis-1/12 p-2 print:p-1 font-medium italic" + (!isPrint ? "" :  !itemsOnly ? " py-0 print:basis-3/12" : " py-0 print:basis-2/12")}>{d.discount ? "Sale" : "Discount"}</div>
                            {d.type === "fixed" ? <div className={"basis-1/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " py-0 print:basis-3/12" : " py-0 print:basis-2/12")}>-£{d.currentValue ? d.currentValue.toFixed(2) : '0.00'}</div> : <div className={"basis-1/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " py-0 print:basis-3/12" : " py-0 print:basis-2/12")}>-£{d.currentValue ? d.currentValue.toFixed(2) : '0.00'} ({d.amount}%)</div> }
                            <div className="basis-1/12 p-2 print:p-1 flex flex-row noPrint">
                                {order.status !== "Completed" && (!d.discount || d.discount.code) ? <FontAwesomeIcon icon={faTrash} className="cursor-pointer ml-auto mr-2" onClick={() => { removeItemDiscount(i.id, d.id) }}/> : null }
                            </div>
                        </div>
                    })
                : null
                }
            </div>
        })
        : (!vouchers || vouchers.length === 0 ? [<div className="flex flex-row justify-center items-center p-4 font-medium">No items in order</div>] : [])

    itemBlocks = [<div className="flex flex-row bg-brand-grey-medium font-bold mb-5 print:mb-0 print:text-xs print:bg-brand-grey-light print:border-b print:border-b-black">
        <div className="basis-1/12 p-2 print:p-1 noPrint">Image</div>
        <div className={"basis-1/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " noPrint" : " print:basis-2/12")}>Barcode</div>
        <div className={"basis-2/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " print:basis-6/12" : "")}>Product Code</div>
        <div className={"basis-4/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " noPrint" : " print:basis-4/12")}>Description</div>
        <div className="basis-1/12 p-2 print:p-1 noPrint">Status</div>
        <div className={"basis-1/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " print:basis-3/12" : " print:basis-2/12")}>Price</div>
        <div className={"basis-1/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " print:basis-3/12" : " print:basis-2/12")}>Total</div>
        <div className="basis-1/12 p-2 print:p-1 noPrint"></div>
    </div>].concat(itemBlocks);

    var totalBlocks = [
        <div className="flex flex-row justify-end">
            <div className={"basis-1/12 p-2 print:p-1 font-medium italic" + (!isPrint ? "" :  !itemsOnly ? " print:basis-3/12" : " pb-1 pt-2 font-bold print:basis-2/12")}>Subtotal</div>
            <div className={"basis-2/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " print:basis-3/12" : " pb-1 pt-2 print:basis-2/12")}>£{order.subTotal ? order.subTotal.toFixed(2) : '0.00'}</div>
        </div>,
        <div className="flex flex-row justify-end">
            {order.status !== 'Completed' ? <div className="basis-1/12 p-2 print:p-1 font-medium underline cursor-pointer noPrint" onClick={() => { toggleVAT() }}>{!order.vatPayable ? "Include" : "Exclude"}</div> : null }
            <div className={"basis-1/12 p-2 print:p-1 font-medium italic" + (!isPrint ? "" :  !itemsOnly ? " py-0 print:basis-3/12" : " py-0 font-bold print:basis-2/12") + (!order.vatPayable ? " line-through" : "")}>VAT</div>
            <div className={"basis-2/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " py-0 print:basis-3/12" : " py-0 print:basis-2/12") + (!order.vatPayable ? " line-through" : "")}>£{order.subVAT ? order.subVAT.toFixed(2) : '0.00'}</div>
        </div>,
        discounts.map(d => {
            return <div className="flex flex-row justify-end print:text-xs">
                <div className={"basis-1/12 p-2 print:p-1 font-medium italic" + (!isPrint ? "" :  !itemsOnly ? " py-0 print:basis-3/12" : " py-0 font-bold print:basis-2/12")}>{d.discount ? d.discount.code || d.discount.name || "Sale" : "Discount"}</div>
                <div className={"basis-1/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " py-0 print:basis-3/12" : " py-0 print:basis-2/12")}>-£{d.currentValue ? d.currentValue.toFixed(2) : '0.00'}{d.type === 'percentage' ? ` (${d.amount}%)` : ''}</div>
                <div className="basis-1/12 p-2 print:p-1 flex flex-row noPrint">
                    {order.status !== 'Completed' ? <FontAwesomeIcon icon={faTrash} className="cursor-pointer ml-auto mr-2" onClick={() => { removeDiscount(d) }}/> : null }
                </div>
            </div>
        }),
        <div className="flex flex-row justify-end border-t-2 border-t-brand-grey-alt mt-2">
            <div className={"basis-1/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " print:basis-3/12" : " pb-1 pt-1 font-bold print:basis-2/12")}>Total</div>
            <div className={"basis-2/12 p-2 print:p-1" + (!isPrint ? "" :  !itemsOnly ? " print:basis-3/12" : " pb-1 pt-1 print:basis-2/12")}>£{order.total ? order.total.toFixed(2) : '0.00'}</div>
        </div>,
        order.vatPayable ? <div className="flex flex-row justify-end">
            <div className={"basis-1/12 px-2 py-1 italic text-sm print:text-xs" + (!isPrint ? "" :  !itemsOnly ? " py-0 print:basis-3/12" : " py-0 font-bold print:basis-2/12")}>VAT</div>
            <div className={"basis-2/12 px-2 py-1 text-sm print:text-xs" + (!isPrint ? "" :  !itemsOnly ? " py-0 print:basis-3/12" : " py-0 print:basis-2/12")}>£{order.vat ? order.vat.toFixed(2) : '0.00'}</div>
        </div> : null,
        <div className="flex flex-row justify-end border-t-2 border-t-brand-grey-alt mt-2 pt-1">
            <div className={"basis-10/12 px-2 py-1 italic text-sm print:text-xs text-right" + (!isPrint ? "" :  !itemsOnly ? " py-0 " : " py-0 font-bold")}>{order.customer ? 'Loyalty Points Earned: ' : 'Missed loyalty points on this order:'}</div>
            <div className={"basis-2/12 px-2 py-1 text-sm print:text-xs" + (!isPrint ? "" :  !itemsOnly ? " py-0 print:basis-3/12" : " py-0 print:basis-2/12")}>{Math.floor(order.total * (getSetting(settings, 'point-rate') || 1))} (£{((Math.floor(order.total * parseInt(getSetting(settings, 'point-rate') || 1)) * (getSetting(settings, 'point-value') || 1)) / 100).toFixed(2)})</div>
        </div>
    ]

    var voucherBlocks = vouchers && vouchers.length > 0 ?
        vouchers.map(v => {
            return <div className="flex flex-col">
                <div className="flex flex-row print:text-xs">
                    <div className="basis-4/12 p-2 print:p-1 noPrint"></div>
                    <div className={"basis-4/12 p-2 print:p-1 " + (itemsOnly ? "print:basis-8/12" : "print:basis-6/12")}>
                        <div>Voucher <span className="noPrint">({v.code} - {v.void ? "£0.00" : `£${parseFloat(v.currentValue || 0).toFixed(2)}`})</span></div>
                    </div>
                    <div className="basis-1/12 p-2 print:p-1 noPrint">{v.status || ""}</div>
                    <div className={"basis-1/12 p-2 print:p-1 " + (itemsOnly ? "print:basis-2/12" : "print:basis-3/12")}>£{parseFloat(v.amount || v.initialValue).toFixed(2)}</div>
                    <div className={"basis-1/12 p-2 print:p-1 " + (itemsOnly ? "print:basis-2/12" : "print:basis-3/12")}>£{parseFloat(v.amount || v.initialValue).toFixed(2)}</div>
                    <div className="basis-1/12 p-2 print:p-1 flex flex-row noPrint">
                        { !v.void && v.currentValue > 0 ? <FontAwesomeIcon icon={faPrint} className="cursor-pointer mr-2 ml-auto" onClick={() => { printVoucher(v) }}/> : null }
                        <FontAwesomeIcon icon={faTrash} className="cursor-pointer mr-2 ml-auto" onClick={() => { removeVoucher(v) }}/>
                    </div>
                </div>
            </div>
    }) : [];

    if(itemsOnly) {
        return itemBlocks.concat(voucherBlocks).concat(totalBlocks);
    }

    // TODO: Seperate Component 
    var transactionsBlock = <div className="flex flex-col">
        <div className="flex flex-row bg-white text-brand-grey p-2 print:p-0 font-bold mt-8 print:mt-1 print:ml-auto print:mr-auto">
            Transactions
        </div>
        <div className="flex flex-row bg-brand-grey-medium font-bold my-5 print:my-1 print:text-xs print:bg-brand-grey-light print:border-b print:border-b-black">
            <div className="basis-2/12 p-2 print:p-1 print:basis-3/12">Date</div>
            <div className="basis-2/12 p-2 print:p-1 print:basis-3/12">Type</div>
            <div className="basis-2/12 p-2 print:p-1 print:basis-3/12">Reference</div>
            <div className="basis-3/12 p-2 print:p-1 noPrint">Details</div>
            <div className="basis-2/12 p-2 print:p-1 print:basis-3/12">Amount</div>
            <div className="basis-1/12 p-2 print:p-1 noPrint"></div>
        </div>
        {order && order.transactions && order.transactions.length > 0 ? order.transactions.map(t => {
            return <div className="flex flex-row justify-end print:text-xs">
                <div className="basis-2/12 p-2 print:p-1 print:py-1 print:basis-3/12">{format(new Date(t.createdAt), isPrint ? "dd/MM/yy HH:mmaaa" : "eee, MMM do HH:mmaaa")}</div>
                <div className="basis-2/12 p-2 print:p-1 print:py-1 print:basis-3/12">{t.type}{t.type === "Cash" || t.type === "Card" ? <span className="cursor-pointer ml-2 text-sm print:text-xs text-blue-600 noPrint" onClick={() => { toggleTransactionType(t) }}> Change to {t.type === "Cash" ? "Card" : "Cash"}</span> : null }</div>
                <div className="basis-2/12 p-2 print:p-1 print:py-1 print:basis-3/12"><div className="cursor-pointer text-blue-600 underline" onClick={() => { handleReference(t) }}>{t.reference}</div></div>
                <div className="basis-3/12 p-2 print:p-1 noPrint">{t.return ? t.return.itemCount + " items returned" : ""}</div>
                <div className="basis-2/12 p-2 print:p-1 print:py-1 print:basis-3/12">£{t.amount.toFixed(2)}</div>
                <div className="basis-1/12 p-2 print:p-1 flex flex-row noPrint">
                    { order.status !== 'Completed' ? <FontAwesomeIcon icon={faTrash} className="cursor-pointer ml-auto mr-2" onClick={() => { removeTransaction(t) }}/> : null}
                </div>
            </div>
        })
        : <div className="flex flex-row justify-center items-center p-4 font-medium">No transactions made</div> }
        <div className="flex flex-row justify-end border-t border-t-brand-grey-alt print:mt-2">
            <div className="basis-1/12 p-2 print:p-1 font-medium italic">Balance</div>
            <div className="basis-3/12 p-2 print:p-1">£{balance.toFixed(2)}</div>
        </div>
    </div>;

    if(transactionsOnly) {
        return transactionsBlock;
    }

    var printObject = null;

    if(isPrinting) {
        if(voucherToPrint) {
            printObject = <VoucherPrint ref={printingRef} id={voucherToPrint.id} expiry={voucherToPrint.expires} value={voucherToPrint.currentValue} code={voucherToPrint.code} settings={settings}/>;
        } else if(creditNoteToPrint) {
            printObject = <CreditNotePrint ref={printingRef} value={creditNoteToPrint.currentValue} code={creditNoteToPrint.code} expiry={creditNoteToPrint.expires} settings={settings}/>;
        }
    }

    var returnedItems = order && order.items ? order.items.filter(i => i.returnId && !i.openDepartmentId).sort((a, b) => (a.returnId > b.returnId) ? 1 : -1) : [];

    // Name search, VAT status, from/to
    return <div className="flex flex-col w-full">
        {printObject}
        {changingStockSource ? <TillStockCorrectionModal onClose={() => { setChangingStockSource(null); }} orderItem={changingStockSource} onSuccess={(locId) => { setItemFrom(changingStockSource, locId); setChangingStockSource(null) }}/> : null}
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-col">
            {itemBlocks}
            {voucherBlocks}
            {totalBlocks}
        </div>
        { !isPrint || isRefund ? <div className="flex flex-col onlyPrint">
            <div className="flex flex-row bg-white text-brand-grey p-2 print:p-1 font-bold mt-8 print:mt-4 print:ml-auto print:mr-auto">
                Returned / Cancelled Items
            </div>
            <div className="flex flex-row bg-brand-grey-medium font-bold my-5 print:mb-2 print:text-xs print:bg-brand-grey-light print:border-b print:border-b-black">
                <div className="basis-4/12 p-2 print:p-1">Barcode</div>
                <div className="basis-4/12 p-2 print:p-1">Product Code</div>
                <div className="basis-4/12 p-2 print:p-1">Amount</div>
            </div>
            {returnedItems && returnedItems.length > 0 ? 
                returnedItems.map((i, idx) => {
                    var last = !returnedItems.slice(idx + 1).some(r => r.returnId === i.returnId);
                    return <div key={i.id} className="flex flex-col border-b border-b-brand-grey-medium py-4 print:py-1">
                        <div className="flex flex-row print:text-xs">
                            <div className={"p-2 print:p-1 basis-4/12"}>{i.barcode}</div>
                            <div className={"p-2 print:p-1 basis-4/12"}>{i.productCode}</div>
                            <div className={"p-2 print:p-1 basis-4/12"}>{last ? `£${i.returnValue}` : ""}</div>
                        </div>
                    </div>;
                }) : <div className="flex flex-row justify-center items-center p-4 font-medium">No refunds given.</div>}
        </div> : null }
        {transactionsBlock}
        <div className="flex flex-col noPrint">
            <div className="flex flex-row bg-white text-brand-grey p-2 print:p-1 font-bold mt-8">
                Shipments
            </div>
            <div className="flex flex-row bg-brand-grey-medium font-bold my-5">
                <div className="basis-3/12 p-2 print:p-1">Date</div>
                <div className="basis-2/12 p-2 print:p-1">Courier</div>
                <div className="basis-3/12 p-2 print:p-1">Tracking Code</div>
                <div className="basis-3/12 p-2 print:p-1">Items</div>
                <div className="basis-1/12 p-2 print:p-1"></div>
            </div>
            {!order || !order.shipments || order.shipments.length === 0 ? <div className="flex flex-row justify-center items-center p-4 font-medium">No shipments sent</div> :
                order.shipments.map(s => {
                    return <div className="flex flex-row mb-6">
                        <div className="basis-3/12 p-2 print:p-1">{format(new Date(s.createdAt), "eee, MMM do HH:mmaaa")}</div>
                        <div className="basis-2/12 p-2 print:p-1">{s.shippingMethod.name}</div>
                        <div className="basis-3/12 p-2 print:p-1"><a className="cursor-pointer underline text-blue-600 " target="_blank" href={"https://" + s.shippingMethod.urlTemplate.replace("http://", "").replace("https://", "").replace("{code}", s.code)}>{s.code}</a></div>
                        <div className="basis-3/12 p-2 print:p-1 flex flex-col">
                            {s.items.map(i => {
                                var match = order.items.find(item => item.id === i);
                                
                                if(!match) return <div>Unknown Item</div>;

                                return <div><span className="font-medium">{match.barcode}</span> - {match.productCode}</div>;
                            })}
                        </div>
                        <div className="basis-1/12 p-2 print:p-1 flex flex-row">
                            <FontAwesomeIcon icon={faTrash} className="cursor-pointer ml-auto mr-2" onClick={() => deleteShipment(s.id)}/>
                        </div>
                    </div>
                })
            }
        </div>
        {!isPrint || (order.notes && order.notes.some(n => n.type == "Receipt")) ? <div className="flex flex-col">
            <div className="flex flex-row bg-white text-brand-grey p-2 print:p-1 font-bold mt-8 items-center print:mt-4 print:ml-auto print:mr-auto">
                <div>Notes</div>
            </div>
            <div className="flex flex-row bg-brand-grey-medium font-bold my-5 noPrint">
                <div className="basis-2/12 p-2 print:p-1">Date</div>
                <div className="basis-7/12 p-2 print:p-1">Note</div>
                <div className="basis-2/12 p-2 print:p-1">User</div>
                <div className="basis-1/12 p-2 print:p-1"></div>
            </div>
            {!order || !order.notes || order.notes.length == 0 ? <div className="flex flex-row justify-center items-center p-4 font-medium mb-8">No notes attached</div> :
            order.notes.filter(n => !isPrint || n.type === "Receipt").map(n => {
                return <div className="flex flex-row mb-6 print:mb-0">
                    <div className="basis-2/12 p-2 print:p-1 print:basis-3/12">{format(new Date(n.createdAt), isPrint ? "dd/MM/yy HH:mmaaa" : "eee, MMM do HH:mmaaa")}</div>
                    <div className="basis-7/12 p-2 print:p-1 print:basis-9/12">{n.content}</div>
                    <div className="basis-2/12 p-2 print:p-1 noPrint">{n.user.name}</div>
                    <div className="basis-1/12 p-2 print:p-1 flex flex-row noPrint">
                        <FontAwesomeIcon icon={faTrash} className="cursor-pointer ml-auto mr-2" onClick={() => deleteNote(n.id)}/>
                    </div>
                </div>
            })}
        </div> : null }
    </div>
}

export default OrderDetails;