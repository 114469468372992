import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import APIFetch from "../utilities/APIFetch";
import MessageModal from "./MessageModal";

const ROLES = [ 'None', 'Staff', 'Admin' ];
function EditAccountPanel({ account, onClose, onChange }) {
    const [name, setName] = useState(account.name);
    const [email, setEmail] = useState(account.email);
    const [role, setRole] = useState(account.role.id);
    const [tillPIN, setTillPIN] = useState(account.tillPIN);
    const [forcePasswordChange, setForcePasswordChange] = useState(false);

    const [newPassword, setNewPassword] = useState('');
    const [forcePasswordChange2, setForcePasswordChange2] = useState(false);

    const changed = (name !== account.name) || (email !== account.email) || (role !== account.role) || (tillPIN !== account.tillPIN) || forcePasswordChange;

    const [message, setMessage] = useState(null);

    useEffect(() => {
        setName(account.name);
        setEmail(account.email);
        setRole(account.role.id);
        setTillPIN(account.tillPIN);
        setForcePasswordChange(false);
        setNewPassword("");
        setForcePasswordChange2(false);
    }, [account])

    const saveChanges = () => {
        if(name.length === 0) {
            setMessage("Name is required.");
            return;
        }

        if(email.length === 0) {
            setMessage("Email is required.");
            return;
        }

        APIFetch('POST', 'account/update', { userId: account.userId, name, email, role: role.id ? role.id : role , tillPIN, forcePasswordChange })
        .then(result => {
            if(result.ok) {
                onChange(result.data);
                onClose();
            } else {
                setMessage("An error occurred while saving your changes.");
            }
        })
        .catch(e => {
            setMessage("An error occurred while saving your changes.");
        })
    }

    const savePasswordChanges = () => {
        if(newPassword.length < 8) {
            setMessage("Password must be 8 characters long.");
            return;
        }

        APIFetch('POST', 'account/reset', { userId: account.userId, newPassword, forcePasswordChange: forcePasswordChange2 })
        .then(result => {
            if(result.ok) {
                setMessage("Password changed successfully.");
            } else {
                setMessage("An error occurred while saving your changes.");
            }
        })
        .catch(e => {
            setMessage("An error occurred while saving your changes.");
        })
    }

    return <div className={"flex text-left border-l border-l-brand-grey my-8 text-sm"}>
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { onClose ? <FontAwesomeIcon icon={faTimes} className="text-2xl absolute top-0 right-0 mr-6 mt-5 cursor-pointer hover:text-brand-grey-alt" onClick={onClose}/> : null }
        <div className="overflow-y-auto bg-red w-96 p-8">
            <div className="mt-5 text-lg font-medium text-center mb-12">Edit Account</div>
            <div className="flex flex-col">
                <label>Created: </label>
                <div className="">{new Date(account.createdAt).toLocaleDateString()}</div>
            </div>
            <div className="flex flex-col mt-4">
                <label>Name: </label>
                <input type="text" value={name} className="flex-1" onChange={(e) => setName(e.target.value)}/>
            </div>
            <div className="flex flex-col mt-4">
                <label>Email: </label>
                <input type="text" value={email} className="flex-1" onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className="flex flex-col mt-4">
                <label>Role: </label>
                <select className="flex-1" value={role} onChange={(e) => { setRole(parseInt(e.target.value)) }}>
                    {ROLES.map((r, i) => {
                        if(i == 0) return null;
                        return <option value={i} key={i}>{r}</option>
                    })}
                </select>
            </div>
            <div className="flex flex-col mt-4">
                <label>Till PIN: </label>
                <input type="text" value={tillPIN} className="flex-1" onChange={(e) => setTillPIN(e.target.value)}/>
            </div>
            <div className="flex flex-row mt-6">
                <input type="checkbox" value={forcePasswordChange} className="mr-2 relative top-[-1px]" onChange={(e) => setForcePasswordChange(!forcePasswordChange)}/>
                <label>Force User to Change Password</label>
            </div>
            <div className={"flex ml-auto mr-auto w-32 mt-8 btn" + (changed ? "" : " invisible")} onClick={saveChanges}>Save</div>
            <div className="text-lg font-medium text-center mb-12 mt-16">Set New Password</div>
            <div className="flex flex-col">
                <label>Password: </label>
                <input type="password" value={newPassword} className="flex-1" onChange={(e) => setNewPassword(e.target.value)}/>
            </div>
            <div className="flex flex-row mt-6">
                <input type="checkbox" value={forcePasswordChange2} className="mr-2 relative top-[-1px]" onChange={(e) => setForcePasswordChange2(!forcePasswordChange2)}/>
                <label>Force User to Change Password</label>
            </div>
            <div className={"flex ml-auto mr-auto w-32 mt-8 btn" + (newPassword.length > 0 ? "" : " invisible")} onClick={savePasswordChanges}>Save</div>
        </div>
    </div>;
}

export default EditAccountPanel;