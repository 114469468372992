import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { SpinnerCircular } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import ItemCreationModal from "../../components/ItemCreationModal";
import Modal from "../../components/Modal";
import ItemEditPanel from "../../components/ItemEditPanel";
import ItemCard from "../../components/ItemCard";

function ManufacturerManagement() {
    const [manufacturers, setManufacturers] = useState(null);
    const [message, setMessage] = useState(null);
    const [creation, setCreation] = useState(false);
    const [selected, setSelected] = useState(null);
    const [pendingDelete, setPendingDelete] = useState(false);

    useEffect(() => {
        APIFetch("GET", "manufacturer")
        .then(result => {
            if(result.ok) {
                setManufacturers(result.data);
            } else {
                setManufacturers([]);
            }
        })
        .catch(() => {
            setManufacturers([]);
        })
    }, []);

    const onDelete = (manufacturer) => {
        if(!pendingDelete || pendingDelete !== manufacturer) {
            setPendingDelete(manufacturer);
        } else {
            APIFetch("DELETE", `manufacturer/${manufacturer.id}`)
            .then(result => {
                if(result.ok) {
                    var newManufacturers = [...manufacturers];
                    newManufacturers = newManufacturers.filter(l => l.id !== manufacturer.id);
                    setManufacturers(newManufacturers);
                    setPendingDelete(null);
                    setMessage("Manufacturer deleted successfully.");
                } else {
                    setMessage("An error occurred when attempting to delete the manufacturer.");
                }
            })
            .catch(() => {
                setMessage("An error occurred when attempting to delete the manufacturer.");
            })
        }
    }

    const createManufacturer = (manufacturer) => {
        var newManufacturers = [...manufacturers];
        newManufacturers.push(manufacturer);
        newManufacturers.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        setManufacturers(newManufacturers);
        setCreation(false);
    }

    const onEdit = (manufacturer) => {
        var newManufacturers = [...manufacturers];
        var index = newManufacturers.findIndex(a => a.id === manufacturer.id);
        newManufacturers[index] = manufacturer;
        setManufacturers(newManufacturers);
    }

    return (
        <div className="App">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { creation ? <ItemCreationModal onClose={() => setCreation(false)} itemType="manufacturer" heading="Create New Manufacturer" onCreate={createManufacturer}/> : null }
        { pendingDelete ? <Modal heading="Confirm Manufacturer Deletion" className="text-black" onClose={() => setPendingDelete(null)} windowClassName="w-[450px]">
            <div className="text-sm">Are you sure you wish to delete '{pendingDelete.name}'?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {onDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        <div className="flex flex-col md:flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="flex flex-row items-center ml-2 mt-9 mb-16">
                    <h1 className="text-left font-medium text-lg">Manufacturers</h1>
                    <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => setCreation(true)}/>
                </div>
                <div className="flex flex-row px-3 font-bold mb-2 text-sm mt-2">
                    <div className="flex flex-1 basis-full">Name</div>
                    <div className="flex flex-1 basis-[45px]"></div>
                </div>
                <div className="flex flex-col flex-grow overflow-y-auto">
                    { manufacturers && manufacturers.length === 0 ? <div>No manufacturers to display.</div> : null }
                    { manufacturers ? manufacturers.map(d => <ItemCard key={d.id} item={d} onEdit={() => setSelected(d)} onDelete={onDelete} selected={selected === d} nameClass="basis-full"/>) : null }
                    <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular enabled={!manufacturers} size={50} color="#24272b" secondaryColor="white" />
                    </div>
                </div>
            </div>
            { selected ? <ItemEditPanel key={selected.id + "edit"} itemType="manufacturer" item={selected} onDelete={onDelete} onChange={(a) => { onEdit(a); }} onClose={() => { setSelected(null) }}/> : null }
        </div>
        </div>
    );
}

export default ManufacturerManagement;