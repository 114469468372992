import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const TillCustomerModal = ({ customer, onClose, onSuccess, orderId }) => {
    const [message, setMessage] = useState(null);
    const [waiting, setWaiting] = useState(false);

    const [id, setId] = useState(customer ? customer.id : null);
    const [firstName, setFirstName] = useState(customer ? customer.firstName : "");
    const [surname, setSurname] = useState(customer ? customer.surname : "");
    const [email, setEmail] = useState(customer ? customer.email : "");
    const [phoneNumber, setPhoneNumber] = useState(customer ? customer.phoneNumber : "");

    const [pendingLookup, setPendingLookup] = useState(null);
    const [customerMatches, setCustomerMatches] = useState([]);

    useEffect(() => {
        if(!firstName && !surname && !email && !phoneNumber) {
            setCustomerMatches([]);
            return;
        }

        if(pendingLookup) {
            clearTimeout(pendingLookup);
        }

        setPendingLookup(setTimeout(() => {
            setPendingLookup(null);

            APIFetch('POST', 'customer/search', {
                firstName,
                surname,
                phoneNumber,
                email,
                dob: null
            })
            .then(result => {
                if(result.ok) {
                    setCustomerMatches(result.data);
                }
            })
            .catch(e => {

            });
        }, 500));
    }, [firstName, surname, email, phoneNumber]);

    const createCustomer = () => {
        if(firstName.length <= 0) {
            setMessage("Customer first name is required.");
            return;
        }

        if(!id) {
            APIFetch('POST', 'customer', { firstName, surname, phoneNumber, email, dob: null })
            .then(result => {
                if(result.ok) {
                    updateCustomer(result.data);
                } else {
                    setMessage("An error occurred when attempting to create the customer.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when attempting to create the customer.");
            });
        } else {
            APIFetch('POST', `customer/${id}`, { firstName, surname, phoneNumber, email, dob: null })
            .then(result => {
                if(result.ok) {
                    onSuccess(result.data);
                } else {
                    setMessage("An error occurred when attempting to update the customer.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when attempting to update the customer.");
            });
        }
    }

    const updateCustomer = (c) => {
        APIFetch('POST', `customer/${c.id}/assign/${orderId}`)
        .then(result => {
            if(result.ok) {
                onSuccess(c);
            } else {
                setMessage("An error occurred when assigning the customer to the order.");
            }
        })
        .catch(e => {
            setMessage("An error occurred when assigning the customer to the order.");
        });
    }

    const clearCustomer = () => {
        setId(null);
        setFirstName("");
        setSurname("");
        setPhoneNumber("");
        setEmail("");
        setCustomerMatches([]);
    }

    return <Modal onClose={onClose} heading={'Update/Replace Customer'} className="">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="max-h-[60vh] overflow-y-auto flex flex-row mt-2">
            <div className="flex flex-col p-2 text-sm w-full">
                <div className="text-lg text-bold text-center mb-4 min-w-[275px]">{id ? `${customer.firstName} ${customer.surname}` : "Customer Details"}</div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">First Name*:</div>
                    <input type="text"
                        value={firstName} 
                        className="mb-1 text-sm flex-1" 
                        onChange={(e) => { setFirstName(e.target.value) }} placeholder=""/>
                </div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">Surname:</div>
                    <input type="text"
                        value={surname} 
                        className="mb-1 text-sm flex-1" 
                        onChange={(e) => { setSurname(e.target.value) }} placeholder=""/>
                </div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">Phone Number:</div>
                    <input type="text"
                        value={phoneNumber} 
                        className="mb-1 text-sm flex-1" 
                        onChange={(e) => { setPhoneNumber(e.target.value) }} placeholder=""/>
                </div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">Email:</div>
                    <input type="text"
                        value={email} 
                        className="mb-1 text-sm flex-1" 
                        onChange={(e) => { setEmail(e.target.value) }} placeholder=""/>
                </div>
                {id ? <div className="text-sm text-bold text-center underline mt-8 mb-[-10px] cursor-pointer" onClick={clearCustomer}>New Customer</div> : null }
            </div>
            {!id ? <div className="flex flex-col p-2 pl-8 border-l border-l-brand-grey ml-8 min-h-[300px]">
                <div className="text-lg text-bold text-center mb-4 min-w-[275px]">Customer Search</div>
                <div className="flex flex-col first-letter:overflow-y-scroll">
                    {customerMatches.filter(c => {
                        return !id || c.id !== id;
                    }).map(c => {
                        return <div className="border-b border-b-brand-grey p-2 hover:bg-brand-grey hover:text-white cursor-pointer text-sm" onClick={() => { updateCustomer(c) }}>
                            <div>{c.firstName} {c.surname}</div>
                            {c.phoneNumber ? <div>{c.phoneNumber}</div> : null }
                            {c.email ? <div>{c.email}</div> : null }
                        </div>
                    })}
                </div>
            </div> : null }
        </div>
        <div className="flex flex-row mt-8 justify-center">
            <div className="btn mr-4" onClick={() => { createCustomer() }}>{id ? "Update Customer" : "Create Customer"}</div>
            <div className="btn" onClick={() => onClose()}>Cancel</div>
        </div>
    </Modal>;
}

export default TillCustomerModal;