import { format } from "date-fns";
import { forwardRef } from "react";
import DataCache from "../../utilities/DataCache";

function PickListPrint({ items }, ref) {
    return <div ref={ref} className="onlyPrint absolute top-0 left-0 w-full max-w-full m-0 p-2 text-xs">
        <div className="font-bold w-100 mb-2 text-2xl p-2">Pick List - {format(Date.now(), "dd/MM/yy")}</div>
        { items && items.length > 0 ? <div className="text-left grid grid-cols-[auto_auto_auto_auto_auto_90px_120px]">
            <div className="p-3 font-bold sticky top-0 bg-white">Barcode</div>
            <div className="p-3 font-bold sticky top-0 bg-white">Code</div>
            <div className="p-3 font-bold sticky top-0 bg-white">Product</div>
            <div className="p-3 font-bold sticky top-0 bg-white">Options</div>
            <div className="p-3 font-bold sticky top-0 bg-white text-center">Location</div>
            <div className="p-3 font-bold sticky top-0 bg-white text-center">Quantity</div>
            <div className="p-3 font-bold sticky top-0 bg-white text-center">Picked</div>
            {
                items.map(s => {
                    let bgClass = "flex p-3 border-b border-brand-grey text-sm font-medium";
        
                    return [
                        <div key={s.id + "barcode"} className={"items-center " + bgClass}>{s.item.barcode}</div>,
                        <div key={s.id + "productcode"} className={"items-center " + bgClass}>{s.item.productCode}</div>,
                        <div key={s.id + "productname"} className={"items-center " + bgClass}>{s.item.description}</div>,
                        <div key={s.id + "options"} className={" flex flex-col justify-center " + bgClass}>
                            {s.item.variations.map((v, i) => {
                                return <div key={s.id + "options" + i} className="whitespace-nowrap">
                                    {v.name}: {v.value}
                                </div>
                            })}
                        </div>,
                        <div key={s.id + "location"} className={"items-center justify-center " + bgClass}>{s.locationId > 0 ? DataCache.locationLookup[s.locationId].name : ""}</div>,
                        <div key={s.id + "topick"} className={"items-center justify-center " + bgClass}>{s.quantity}</div>,
                        <div key={s.id + "selector"} className={"justify-center " + bgClass}>
                            <input type="checkbox" checked={false} className=""></input>
                        </div>
                    ]
                })
            }
        </div> : null }
    </div>;
}

export default forwardRef(PickListPrint);