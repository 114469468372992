import { useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const TillAdjustmentModal = ({ onClose, onSuccess, till, type = "adjustment" }) => {
    const [message, setMessage] = useState(null);
    const [amount, setAmount] = useState("");
    const [comment, setComment] = useState("");
    const [banking, setBanking] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const onSubmit = () => {
        if(!waiting) {
            setWaiting(true);
            APIFetch('POST', 'tilladjust', { 
                tillId: till.id,
                comment: comment,
                adjustment: type === 'adjustment' ? parseFloat(amount) : 0,
                newBalance: type === 'float' ? parseFloat(amount) : 0,
                banking: type === 'adjustment' && banking ? true : false
            })
            .then(result => {
                if(result.ok) {
                    onSuccess(type === 'adjustment' ? parseFloat(amount) : parseFloat(amount) - till.balance, comment, type === 'float' ? parseFloat(amount) : till.balance + parseFloat(amount));
                } else {
                    setMessage("An error occurred during the adjustment.");
                    setWaiting(false);
                }
            })
            .catch(e => {
                setMessage("An error occurred during the adjustment.");
                setWaiting(false);
            });
        }
    }

    return <Modal onClose={onClose} heading={type === 'adjustment' ? 'Till Adjustment' : 'Till Float'} className="text-black text-left flex flex-col" windowClassName="w-[450px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-col mt-4">
            <label>{type === 'adjustment' ? "Amount (£):" : "New Balance (£):" }</label>
            <input type="number" value={amount} className="flex-1" onChange={(e) => setAmount(e.target.value)}/>
        </div>
        <div className="flex flex-col mt-4">
            <label>Comment:</label>
            <textarea value={comment} className="h-[24px]" onChange={(e) => setComment(e.target.value)}/>
        </div>
        <div className="flex flex-col mt-6">
            {type === 'adjustment' ? <label className="mb-2">New Balance: £{((parseFloat(till.balance || 0) + parseFloat(amount || 0)) || 0).toFixed(2)}</label>
            : <label className="mb-2">Discrepancy: £{((parseFloat(amount || 0)) - parseFloat(till.balance || 0)).toFixed(2)}</label> }
        </div>
        {type === 'adjustment' ? <div className="flex flex-row mt-6 justify-center">
            <input type="checkbox" checked={banking} className="h-[24px]" onChange={(e) => setBanking(!banking)}/>
            <label className="flex items-center ml-2">Cash is being banked</label>
        </div> : null }
        <div className="flex flex-row mt-8 items-center justify-center">
            <div className="btn mr-4" onClick={() => onClose()}>Cancel</div>
            <div className="btn" onClick={() => onSubmit()}>OK</div>
        </div>
    </Modal>;
}

export default TillAdjustmentModal;