import ChartDataLabels from 'chartjs-plugin-datalabels';
import { 
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    Colors,
    LinearScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useEffect, useState } from 'react';
import { format } from 'date-fns';

const DAYS_OF_WEEK = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
const MONTHS_OF_YEAR = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

ChartJS.register(
    CategoryScale,
    ChartDataLabels,
    Colors,
    LinearScale,
    BarElement
  );

const valueOptions = {
    responsive: true,
    plugins: {
        datalabels: {
            color: '#000000',
            anchor: 'end',
            align: 'end',
            font: {
                weight: 'bold',
                size: 14,
            },
            formatter: function(value, context) {
                return '£' + value;
            }
        }
    },
    layout: {
        padding: {
            top: 30,
        }
    },
    scale: {
        ticks: {
        precision: 0
        }
    }
};

const options = {
    responsive: true,
    plugins: {
        datalabels: {
            color: '#000000',
            anchor: 'end',
            align: 'end',
            font: {
                weight: 'bold',
                size: 14,
            }
        }
    },
    layout: {
        padding: {
            top: 30,
        }
    },
    scale: {
        ticks: {
        precision: 0
        }
    }
};

function OrderStatsWidget({ rangeType, chartData }) {
    const [graphData, setGraphData] = useState(null);
    const [valueGraphData, setValueGraphData] = useState(null);

    useEffect(() => {
        if(chartData) {
            // Build graph data object
            const labels = [];
            const datasets = [
                {
                    label: "Web Quantity",
                    data: []
                },
                {
                    label: "EPOS Quantity",
                    data: []
                },
                {
                    label: "Total Web Spend",
                    data: []
                },
                {
                    label: "Total EPOS Spend",
                    data: []
                },
            ];

            chartData.forEach(d => {
                if(rangeType === 0) {
                    labels.push(DAYS_OF_WEEK[d.key]);
                } else if(rangeType === 1) {
                    labels.push(format(new Date(d.key), "dd/MM/yy"));
                } else if(rangeType === 2) {
                    labels.push(MONTHS_OF_YEAR[d.key - 1]);
                }
                
                datasets[0].data.push(d.webQuantity);
                datasets[1].data.push(d.eposQuantity);
                datasets[2].data.push(d.totalWebSpend);
                datasets[3].data.push(d.totalEposSpend);
            });

            datasets[0].backgroundColor = ['#0000CC'];
            datasets[1].backgroundColor = ['#00CC00'];
            datasets[2].backgroundColor = ['#0000CC'];
            datasets[3].backgroundColor = ['#00CC00'];

            setGraphData({ labels, datasets: [datasets[0], datasets[1]] });
            setValueGraphData({ labels, datasets: [datasets[2], datasets[3]] });
        }
    }, [chartData]);

    return <div className="flex flex-col p-4 shadow border border-black">
        <div className='flex flex-row'>
            <div className='flex flex-col w-1/2 mr-4'>
                <div className="text-lg font-semibold text-center mb-0 min-w-[275px] uppercase">Orders Placed</div>
                <div className="flex flex-1 min-h-[10%] max-h-[150px] justify-center">
                    {  graphData ? <Bar options={options} data={graphData} /> : null }
                </div>
            </div>
            <div className='flex flex-col w-1/2 ml-4'>
                <div className="text-lg font-semibold text-center mb-0 mt-2 min-w-[275px] uppercase">Order Value</div>
                <div className="flex flex-1 min-h-[10%] max-h-[150px] justify-center">
                    {  valueGraphData ? <Bar options={valueOptions} data={valueGraphData} /> : null }
                </div>
            </div>
        </div>
    </div>
}

export default OrderStatsWidget;