import { useEffect, useState } from "react";
import MessageModal from "./MessageModal";
import Modal from "./Modal";
import APIFetch from "../utilities/APIFetch";
import DataCache from '../utilities/DataCache';
import LookupInput from "./LookupInput";

const ItemCreationModal = ({ itemType, onClose, heading, onCreate, additionalFields }) => {
    const [name, setName] = useState('');
    const [message, setMessage] = useState(null);
    const [additionalData, setAdditionalData] = useState({});
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
        if (additionalFields) {
            additionalFields.forEach(f => {
                if(additionalData[f.propertyName] == null && f.defaultValue !== null) {
                    additionalData[f.propertyName] = f.defaultValue;
                }
            });
        }
    }, [additionalFields, additionalData]);

    const updateAdditionalData = (propertyName, value) => {
        var newData = {...additionalData};
        newData[propertyName] = value;
        setAdditionalData(newData);
    }

    const create = async () => {
        var formattedData = {};

        if(waiting) return;

        if(name.length === 0) {
            setMessage("A name is required.");
            return;
        }

        if(additionalFields && additionalFields.length > 0) {
            for(let i = 0; i < additionalFields.length; i++) {
                if(additionalData[additionalFields[i].propertyName] == null) {
                    setMessage(`'${additionalFields[i].name}' is required.`);
                    return;
                }

                if(additionalFields[i].type === 'cache') {
                    var currentType = additionalFields[i].name.toLowerCase();
                    var match = DataCache[currentType].filter(a => a.name.toLowerCase() === additionalData[additionalFields[i].propertyName].toLowerCase());
                    if(match.length === 0) {
                        // TODO: Create new data
                        if(currentType === 'manufacturer') {
                            let result = await createItem(currentType, { name: additionalData[additionalFields[i].propertyName] }, false);
                            if(result === false) {
                                return;
                            } else {
                                formattedData['manufacturerId'] = result.id;
                                DataCache[currentType].push(result);
                            }
                        } else {
                            setMessage("An existing category must be selected.");
                            return;
                            // TODO: request additional data
                            // TODO: Add new data to cache
                            // return;
                        }
                    } else {
                        formattedData[additionalFields[i].propertyName] = match[0].id;
                    }
                } else {
                    formattedData[additionalFields[i].propertyName] = additionalData[additionalFields[i].propertyName]
                }

            
            }
        }

        createItem(itemType, { name, ...formattedData }, true);
    }

    const createItem = (itemType, data, isFinal) => {
        setWaiting(true);
        return APIFetch('POST', `${itemType}${itemType === 'product' ? "/new" : ""}`, data)
        .then(result => {
            if(result.ok) {
                if(isFinal)  {
                    onCreate(result.data);
                } else {
                    return result.data;
                }
            } else {
                if(result.status === 409) {
                    setMessage(`A ${itemType} with this name already exists.`);
                } else {
                    setMessage(`Something went wrong when attempting to create the ${itemType}.`);
                }

                return false;
            }
        })
        .catch(e => {
            setMessage(`Something went wrong when attempting to create the ${itemType}.`);
            return false;
        })
        .finally(() => setWaiting(false))
    }

    return <Modal onClose={onClose} heading={heading} className="text-black text-left" windowClassName="w-[450px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-col mt-2">
            <label>Name: </label>
            <input type="text" value={name} className="" onChange={(e) => setName(e.target.value)}/>
        </div>
        {additionalFields && additionalFields.length > 0 ? additionalFields.map(f => {
            if(f.type === "cache") {
                return <div className="flex flex-col mt-4">
                    <label>{f.name}:</label>
                    <LookupInput className="flex-1" itemType={f.name.toLowerCase()} onChange={(value) => { updateAdditionalData(f.propertyName, value) }}/>
                </div>
            } else if(f.type === "cacheList") {
                const items = DataCache[f.name.toLowerCase()];
                return <div className="flex flex-col mt-4">
                    <label>{f.name}: </label>
                    <select className="flex-1" value={additionalData[f.propertyName] || ''} onChange={(e) => { updateAdditionalData(f.propertyName, e.target.value) }}>
                        <option value='' hidden disabled></option>
                        {items && items.length > 0 ? items.map(o => { return <option key={o.id} value={o.id.toString()}>{o.name}</option> }): null}
                    </select>
                </div>
            } else if(f.type === "string" || f.type === "number") {
                return <div className="flex flex-col mt-4">
                    <label>{f.name}: </label>
                    <input type={f.type === "string" ? "text" : f.type} value={additionalData[f.propertyName] || ''} className="flex-1" onChange={(e) => { updateAdditionalData(f.propertyName, e.target.value) }}/>
                </div>
            } else if(f.type === "bool") {
                return <div className="flex flex-row mt-6 text-left">
                    <input type="checkbox" checked={additionalData[f.propertyName] || false} className="mr-2 relative top-[-1px]" onChange={() => { updateAdditionalData(f.propertyName, !additionalData[f.propertyName]) }}/>
                    <label>{f.name}</label>
                </div>
            }
        }) : null }
        <div className="flex ml-auto mr-auto w-32 btn mt-10" onClick={create}>Create</div>
    </Modal>;
}

export default ItemCreationModal;