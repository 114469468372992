import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { SpinnerCircular } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faPlus, faScaleBalanced } from "@fortawesome/free-solid-svg-icons";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import ItemCreationModal from "../../components/ItemCreationModal";
import Modal from "../../components/Modal";
import ItemEditPanel from "../../components/ItemEditPanel";
import ItemCard from "../../components/ItemCard";
import { useNavigate } from "react-router-dom";
import { AuthStore } from "../../stores/AuthStore";
import TillAdjustmentModal from "../Till/TillAdjustmentModal";
import TillAdjustmentPrintModal from "../Till/TillAdjustmentPrintModal";

const AdditionalFields = [{ name: "Location", type: "cacheList", propertyName: "locationId" }];

function TillManagement() {
    const [tills, setTills] = useState(null);
    const [message, setMessage] = useState(null);
    const [creation, setCreation] = useState(false);
    const [selected, setSelected] = useState(null);
    const [pendingDelete, setPendingDelete] = useState(false);
    const [adjustTill, setAdjustTill] = useState(null);
    const [floatTill, setFloatTill] = useState(null);
    const [printingAdjustment, setPrintingAdjustment] = useState(null);
    const [busy, setBusy] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        APIFetch("GET", "till")
        .then(result => {
            if(result.ok) {
                setTills(result.data);
            } else {
                setTills([]);
            }
        })
        .catch(() => {
            setTills([]);
        })
    }, []);

    const onDelete = (till) => {
        if(!pendingDelete || pendingDelete !== till) {
            setPendingDelete(till);
        } else {
            APIFetch("DELETE", `till/${till.id}`)
            .then(result => {
                if(result.ok) {
                    var newTills = [...tills];
                    newTills = newTills.filter(t => t.id !== till.id);
                    setTills(newTills);
                    setPendingDelete(null);
                    setMessage("Till deleted successfully.");
                } else {
                    setMessage("An error occurred when attempting to delete the till.");
                }
            })
            .catch(() => {
                setMessage("An error occurred when attempting to delete the till.");
            })
        }
    }

    const createTill = (till) => {
        var newTills = [...tills];
        newTills.push(till);
        newTills.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        setTills(newTills);
        setCreation(false);
    }

    const onEdit = (till) => {
        var newTills = [...tills];
        var index = newTills.findIndex(a => a.id === till.id);
        newTills[index] = till;
        setTills(newTills.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    }

    const updateTill = (till, newBalance, adjustment, comment) => {
        var list = tills.filter(t => t.id !== till.id);
        var item = tills.find(t => t.id === till.id);
        item.balance = newBalance;
        setTills([...list, item].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        setPrintingAdjustment({ date: new Date(), till: till.name, user: AuthStore.currentState.name, amount: adjustment, comment: comment, newBalance: newBalance }); 
        setAdjustTill(null);
        setFloatTill(null);
    }

    const onReset = () => {
        if(!busy) {
            setBusy(true);
            APIFetch('POST', `till/reset/${selected.id}`)
            .then(result => {
                if(result.ok) {
                    var list = tills.filter(t => t.id !== selected.id);
                    var item = tills.find(t => t.id === selected.id);
                    item.estimatedBalance = item.balance;
                    setTills([...list, item].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
                    setMessage("Till discrepency reset successfully.");
                } else {
                    setMessage("An error occurred when attempting to reset discrepency.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when attempting to reset discrepency.");
            })
            .finally(() => setBusy(false))
        }
    }

    return (
        <div className="App">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { creation ? <ItemCreationModal onClose={() => setCreation(false)} itemType="till" heading="Create New Till" onCreate={createTill} additionalFields={AdditionalFields}/> : null }
        { pendingDelete ? <Modal heading="Confirm Till Deletion" className="text-black" onClose={() => setPendingDelete(null)}>
            <div className="text-sm">Are you sure you wish to delete '{pendingDelete.name}'?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {onDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        {adjustTill || floatTill ? <TillAdjustmentModal type={adjustTill ? "adjustment" : "float"} till={adjustTill || floatTill} onClose={() => { setAdjustTill(null); setFloatTill(null); }} onSuccess={(adjustment, comment, newBalance) => { updateTill(adjustTill || floatTill, newBalance, adjustment, comment) }}/> : null }
        {printingAdjustment ? <TillAdjustmentPrintModal onClose={() => { setPrintingAdjustment(null) }} adjustment={printingAdjustment} /> : null}
        <div className="flex flex-col md:flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-12 flex-grow">
                <div className="flex flex-row items-center ml-2 mt-9 mb-16">
                    <h1 className="text-left font-medium text-lg">Tills</h1>
                    <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => setCreation(true)}/>
                </div>
                <div className="flex flex-row px-3 font-bold mb-2 text-sm mt-2">
                    <div className="flex flex-1 basis-full">Name</div>
                    <div className="flex flex-1 basis-[45px]"></div>
                </div>
                <div className="h-[1000px] flex flex-col flex-grow overflow-y-auto">
                    { tills && tills.length === 0 ? <div>No tills to display.</div> : null }
                    { tills ? tills.map(t => <ItemCard key={t.id} 
                        item={t}
                        nameLink={() => { navigate(`/till/${t.id}`, { state: { till: t, backUrl: '/tills' } }) }}
                        onEdit={() => setSelected(t)} onDelete={onDelete} selected={selected === t} nameClass="basis-full"
                        additionalActions={[
                            { text: "Adjust", action: () => { setAdjustTill(t) } },
                            { text: "Float", action: () => { setFloatTill(t) } },
                            { text: "Report", action: () => { navigate('/tillreport/' + t.id) } },
                        ]}
                        />) : null }
                    <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular enabled={!tills} size={50} color="#24272b" secondaryColor="white" />
                    </div>
                </div>
            </div>
            { selected ? <ItemEditPanel
                key={selected.id + "edit"}
                itemType={'till'}
                item={selected} 
                onDelete={onDelete}
                onChange={(a) => { onEdit(a); }}
                onClose={() => { setSelected(null) }}
                additionalFields={AdditionalFields}
                children={<div>
                    <div className="flex flex-col mt-4">
                        <label>Till Balance: </label>
                        <div className="flex-1">
                            £{selected.balance.toFixed(2)}
                        </div>
                        <label>Estimated Till Balance: </label>
                        <div className="flex-1">
                            £{selected.estimatedBalance.toFixed(2)}
                        </div>
                        <label>Discrepency: </label>
                        <div className="flex-1">
                            £{(selected.balance - selected.estimatedBalance).toFixed(2)}
                        </div>
                        <div className="btn mt-8" onClick={onReset}>Reset Discrepency</div>
                    </div>
                </div>}
            /> 
            : null }
        </div>
        </div>
    );
}

export default TillManagement;