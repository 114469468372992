import { useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";

const TillDiscountModal = ({ onClose, onSuccess, isItem }) => {
    const [message, setMessage] = useState(null);
    const [type, setType] = useState('percentage');
    const [amount, setAmount] = useState(0);
    const [coupon, setCoupon] = useState("");

    const onSubmit = () => {
        onSuccess({ type, amount, coupon });
    }

    return <Modal onClose={onClose} heading={'Apply Discount'} className="text-black text-left flex flex-col"  windowClassName="w-[450px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <select className="" value={type} onChange={(e) => { setType(e.target.value) }}>
            <option key="fixed" value="fixed">Fixed Value</option>
            <option key="percentage" value="percentage">Percentage</option>
            {isItem ? null : <option key="coupon" value="coupon">Coupon</option>}
        </select>
        {type === "coupon" ? <input type="text" value={coupon} placeholder="Code..." className="mt-4" onChange={(e) => setCoupon(e.target.value)}/> : 
        <input type="number" value={amount} placeholder="Amount..." className="mt-4" onChange={(e) => setAmount(e.target.value)}/> }
        <div className="flex flex-row mt-8 justify-center">
            <div className="flex mr-6 btn" onClick={() => onClose()}>Cancel</div>
            <div className="flex btn" onClick={() => onSubmit()}>OK</div>
        </div>
    </Modal>;
}

export default TillDiscountModal;