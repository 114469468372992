import { useState } from "react";
import Modal from "../../components/Modal";

const MissingVariantModal = ({onClose, onAdd, missingVariants}) => {
    const [selected, setSelected] = useState([]);

    const deselect = (i) => {
        var newSelected = [...selected];
        newSelected.splice(i, 1);
        setSelected(newSelected);
    }

    const select = (v) => {
        var newSelected = [...selected];
        newSelected.push(v);
        setSelected(newSelected);
    }

    return <Modal onClose={onClose} heading="Select Variants To Add" className="text-black text-center" windowClassName="w-[450px]">
        <div className="overflow-y-auto">
            {!missingVariants || missingVariants.length == 0 ? <div>All variants are already included.</div> :
                missingVariants.map((v, i) => {
                    let index = selected.indexOf(v);
                    let isSelected = index > -1;
                    return <div className="flex flex-row">
                        <input className="mr-2" type="radio" checked={isSelected} onClick={isSelected ? () => deselect(index) : () => select(v)}/>
                        <div>{v.variations.map(v => v.value).join(' / ')}</div>
                    </div>;
                })
            }
        </div>
        <div className="text-center mt-8">{selected.length || 0} Variants will be added</div>
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={onClose}>Cancel</div>
            <div className="flex btn" onClick={() => { onAdd(selected) }}>Add</div>
        </div>
    </Modal>;
}

export default MissingVariantModal;