import { useEffect, useState } from "react";
import APIFetch from "../utilities/APIFetch";
import DataCache from "../utilities/DataCache";
import MessageModal from "./MessageModal";
import Modal from "./Modal";


function TransferModal({ onClose, stock, onComplete }) {
    const [locations, setLocations] = useState([...DataCache.location].sort((a, b) => a.name.localeCompare(b.name)));
    const [toLoc, setToLoc] = useState();
    const [quantity, setQuantity] = useState(1);
    const [message, setMessage] = useState(null);
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
        var loc = locations.find(l => l.id !== stock.location.id);
        setToLoc(loc ? loc.id.toString() : null);
    }, []);

    const onSubmit = () => {
        if(!waiting) {
            if(quantity > stock.quantity) {
                setMessage("Insufficient stock available.");
            } else {
                setWaiting(true);

                APIFetch('POST', 'stocktransfer', { fromLocation: stock.location.id, toLocation: parseInt(toLoc), transfers: [{ barcode: stock.variant.barcode, quantity }] })
                .then(result => {
                    if(result.ok) {
                        onComplete();
                    } else {
                        setMessage("An error occurred during the transfer.");
                        setWaiting(false);
                    }
                })
                .catch(e => {
                    setMessage("An error occurred during the transfer.");
                    setWaiting(false);
                }); 
            }
        }
    }

    return <Modal onClose={onClose} heading={'Transfer'} className="text-black text-left" windowClassName="w-[500px]">
        <MessageModal message={message} onClose={() => setMessage(null)}/>
        <div className="overflow-y-auto">
            <div className="flex flex-col">
                <label>Barcode:</label>
                <div className="input-style">{stock.variant.barcode}</div>
            </div>
            <div className="flex flex-col mt-2">
                <label>Description:</label>
                <div className="input-style">{stock.product.name}</div>
            </div>
            {stock.variant.variations.map((v, i) => {
                return <div key={stock.id + "options" + i} className="flex flex-col mt-2">
                    <label>{v.name}:</label>
                    <div className="input-style">{v.value}</div>
                </div>
            })}
            <div className="flex flex-col mt-2">
                <label>From Location:</label>
                <div className="input-style">{stock.location.name}</div>
            </div>
            <div className="flex flex-col mt-2">
                <label>Available Quantity:</label>
                <div className="input-style">{stock.quantity}</div>
            </div>
            <div className="flex flex-col mt-2">
                <label>Quantity To Transfer:</label>
                <input type="number" className="flex-1" value={quantity} onChange={(e) => { setQuantity(e.target.value) }}/>
            </div>
            <div className="flex flex-col mt-2">
                <label>To Location:</label>
                <select className="" value={toLoc} onChange={(e) => { setToLoc(e.target.value) }}>
                    {locations && locations.length > 0 ? locations.filter(l => l.id !== stock.location.id).map(o => { return <option key={o.id} value={o.id.toString()}>{o.name}</option> }): null}
                </select>
            </div>
            <div className="flex flex-row">
                <div className="flex ml-auto mr-auto btn mt-8" onClick={() => onSubmit()}>OK</div>
            </div>
        </div>
    </Modal>;
}

export default TransferModal;