import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";

function TemplateEditPanel({ item, onClose, onChange }) {
    const [changed, setChanged] = useState(false);
    const [message, setMessage] = useState(null);

    const [templateName, setTemplateName] = useState(item.templateName);
    const [variationName, setVariationName] = useState(item.variationName);
    const [valueList, setValueList] = useState([...item.values]);

    useEffect(() => {
        setValueList([...item.values]);
    }, [item])

    const saveChanges = () => {
        return APIFetch('POST', `variationtemplate/${item.id}`, { variationName, templateName, values: valueList.map((v) => { return v.value } ).filter(v => v.length > 0) })
        .then(result => {
            if(result.ok) {
                setChanged(false);
                onChange(result.data);
            } else {
                setMessage(`Something went wrong when attempting to create the size scale.`);
            }
        })
        .catch(e => {
            setMessage(`Something went wrong when attempting to create the size scale.`);
        })
    }

    const addValue = () => {
        if(!changed) setChanged(true);
        var newValues = [...valueList];
        newValues.push({ key: Math.random(), value: "" });
        setValueList(newValues);
    }

    const removeValue = (item) => {
        if(!changed) setChanged(true);
        var newValues = valueList.filter(v => v !== item);
        setValueList(newValues);
    }

    const onValueChange = (item, value) => {
        if(!changed) setChanged(true);
        var newValues = [...valueList];
        var editItem = newValues.find(v => v == item);
        editItem.value = value;
        setValueList(newValues);
    }

    return <div className={"flex flex-col w-full md:w-1/4 p-8 text-left border-l border-l-brand-grey my-8 text-sm"}>
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { onClose ? <FontAwesomeIcon icon={faTimes} className="text-2xl absolute top-0 right-0 mr-6 mt-5 cursor-pointer hover:text-brand-grey-alt" onClick={onClose}/> : null }
        <div className="mt-5 text-lg font-medium text-center mb-12">Edit Size Scale</div>
        <div className="flex flex-row items-center mt-2">
            <div className="mr-2 w-32">Size Scale Name: </div>
            <input type="text" value={templateName} className="flex-1" onChange={(e) => { setTemplateName(e.target.value); setChanged(true) }}/>
        </div>
        <div className="flex flex-row items-center mt-4">
            <div className="mr-2 w-32">Variation Name: </div>
            <input type="text" value={variationName} className="flex-1" onChange={(e) => { setVariationName(e.target.value); setChanged(true) }}/>
        </div>
        <div className="flex flex-row items-center mt-12 mb-4">
                    <label>Variation Values: </label>
                    <label className="cursor-pointer ml-6 border-b border-b-brand-grey" onClick={() => { addValue() }}>Add Value</label>
                </div>
        <div className="flex flex-col overflow-y-auto">
            {valueList.map(v => {
                return <div className="flex flex-row mb-2 items-center">
                    <input key={v.id} type="text" className="mr-4" value={v.value} onChange={(e) => { onValueChange(v, e.target.value); }} />
                    <FontAwesomeIcon icon={faTimes} className="cursor-pointer hover:text-brand-grey-alt" onClick={() => { removeValue(v) }}/>
                </div>
            })}
        </div>
        <div className={"flex ml-auto mr-auto w-32 btn mt-10" + (changed ? "" : " invisible")} onClick={saveChanges}>Save</div>
    </div>;
}

export default TemplateEditPanel;