import { useEffect, useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import DataCache from "../../utilities/DataCache";

const REASONS = ["Other", "Wrong Size", "Damaged", "Unwanted"];
const TYPES = ["Cash", "Card", "Cheque", "Credit Note", "Loyalty Points", "Order Credit", "Voucher"];

const TillReturnModal = ({ order, discounts, itemDiscounts, tillId, userId, onClose, onSuccess }) => {
    const [locations] = useState([...DataCache.location].sort((a, b) => a.name.localeCompare(b.name)));

    const [message, setMessage] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [selectingItem, setSelectingItem] = useState(false);
    const [value, setValue] = useState("");
    const [refundType, setRefundType] = useState(-1);
    const [selectedItems, setSelectedItems] = useState([]);
    const [hasFixedDiscount, setHasFixedDiscount] = useState(false);
    const [stockLookup, setStockLookup] = useState({});

    useEffect(() => {
        if(discounts.some(d => d.type === "fixed" && !d.discount)) {
            setHasFixedDiscount(true);
        } else {
            setHasFixedDiscount(false);
        }
    }, [discounts]);

    useEffect(() => {
        var validItems = order.items.filter(i => !i.returnId && !i.openDepartmentId);

        if(validItems.length === 0) {
            return;
        }

        APIFetch('POST', 'stock/lookup', validItems.map(i => i.variantId))
        .then((result) => {
            if(result.ok) {
                var newLookup = {};

                result.data.forEach(s => {
                    newLookup[s.variantId + s.locationId] = s.quantity;
                });

                setStockLookup(newLookup);
            } else {
                setMessage("Failed to load stock data for returns.");
            }
        })
        .catch(() => {
            setMessage("Failed to load stock data for returns.");
        })
    }, [order]);

    const onSubmit = () => {
        if(!waiting) {
            if(selectedItems.some(i => i.returnReason === -1)) {
                setMessage("One or more returned items are missing a return reason.");
            } else if(value < 0 || value.toString() === "") {
                setMessage("Refund value is required.");
            } else if(refundType === -1) {
                setMessage("A Refund Type must be selected.");
            } else {
                setWaiting(true);
                APIFetch('POST', 'transaction/refund', {
                    orderId: order.id,
                    items: selectedItems.map(i => {
                        return {
                            itemId: i.orderItemId,
                            reason: parseInt(i.returnReason),
                            restock: i.locationId >= 1,
                            locationId: i.locationId
                        };
                    }),
                    refundValue: value,
                    refundMethod: TYPES[refundType],
                    tillId: tillId,
                    userId: userId
                })
                .then(result => {
                    if(result.ok) {
                        onSuccess(refundType === "0" ? value : 0);
                    } else {
                        setMessage("An error occurred when attempting to create the refund/return.");
                    }
                })
                .catch(e => {
                    setMessage("An error occurred when attempting to create the refund/return.");
                })
                .finally(() => setWaiting(false));
            }
        }
    }

    const updateItem = (item, field, value) => {
        var matchIdx = selectedItems.findIndex(i => i === item);
        var match = {...item};
        match[field] = value;
        var items = [...selectedItems];
        items[matchIdx] = match;
        setSelectedItems(items);
    }

    const removeItem = (item) => {
        var newItems = selectedItems.filter(i => i !== item);
        setSelectedItems(newItems);

        if(!hasFixedDiscount) {
            setValue(getTotal(newItems));
        }
    }

    const addItem = (item) => {
        var newItems = [...selectedItems];
        newItems.push({
            orderItemId: item.id,
            productCode: item.productCode,
            returnReason: -1,
            locationId: 0,
            variations: item.variations,
            price: item.price,
            vat: item.vat,
            manufacturerId: item.manufacturerId,
            departmentId: item.departmentId,
            categoryId: item.categoryId,
            productId: item.productId
        });

        setSelectedItems(newItems);

        if(!hasFixedDiscount) {
            setValue(getTotal(newItems));
        }
    }

    const getTotal = (newItems) => {
        var total = 0;

        // Calculate total
        for(let i = 0; i < newItems.length; i++) {
            var base =  newItems[i].price + newItems[i].vat;
            var myDiscounts = itemDiscounts[newItems[i].id];
            var totalDiscount = 0;

            if(myDiscounts) {
                myDiscounts.forEach(d => {
                    totalDiscount += d.currentValue;
                });
            }

            total += (base - totalDiscount)
        }

        var additionalDiscount = 0.0;

        for(let i = 0; i < discounts.length; i ++) {
            if(discounts[i].discount && discounts[i].currentValue > 0) {
                var d = discounts[i].discount;

                for(let a = 0; a < newItems.length; a++) {
                    var isMatch = !d.categoryFilter && !d.departmentFilter && !d.manufacturerFilter && !d.productFilter;
                    var base = newItems[i].price + newItems[i].vat;

                    if(!isMatch && d.productFilter === newItems[a].productId) {
                        isMatch = true;
                    }

                    if(!isMatch && d.departmentFilter === newItems[a].departmentId) {
                        isMatch = true;
                    }

                    if(!isMatch && d.manufacturerFilter === newItems[a].manufacturerId) {
                        isMatch = true;
                    }

                    if(!isMatch && d.categoryFilter === newItems[a].categoryId) {
                        isMatch = true;
                    }

                    if(isMatch) {
                        if(d.type === 1) {
                            // Percentage
                            additionalDiscount += base * (d.value / 100);
                        } else if(d.type === 4) {
                            // Buy X Get Y
                            // = (Price * X) / Y
                            additionalDiscount += base - ((base * d.adjustmentX) / (parseInt(d.adjustmentX) + parseInt(d.adjustmentY)));
                        } else if(d.type === 5) {
                            // Buy X For Y
                            // = Y/X
                            additionalDiscount += base - (parseInt(d.adjustmentY) / parseFloat(a.adjustmentX));
                        }
                    }
                }
            } else if(!discounts[i].discount && discounts[i].type === 'percentage') {
                additionalDiscount += total * (discounts[i].amount / 100)
            }
        }

        total -= additionalDiscount;

        return total.toFixed(2);
    }

    return <Modal onClose={onClose} heading={'Create Refund / Return'} className="text-black text-left flex flex-col">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-col w-[50vw]">
            <div className="flex flex-col mb-4">
                <div className="flex flex-row py-1 mb-1 bg-brand-grey-light font-semibold">
                    <div className="flex flex-col basis-4/12 justify-center items-center">Product Code</div>
                    <div className="flex flex-col basis-4/12 justify-center items-center">Details</div>
                    <div className="flex flex-col basis-4/12 justify-center items-center">Return Reason</div>
                    <div className="flex flex-col basis-4/12 justify-center items-center">Restock To</div>
                    <div className="flex flex-col w-[40px] justify-center items-center"></div>
                </div>
                {order.items.filter(i => !i.returnId).map(i => {
                    var selected = selectedItems.find(s => s.orderItemId === i.id);

                    if(selected) {
                        return <div className="flex flex-row pb-1 mb-1 border-b border-b-brand-grey text-sm relative">
                            <div className="flex flex-col basis-4/12 justify-center items-center">{selected.productCode}</div>
                            <div className="flex flex-col basis-4/12 justify-center items-center">
                                {selected.variations ? selected.variations.sort((a, b) => a.name.localeCompare(b.name)).map((v, i) => {
                                    return <div className="text-xs">{v.name}: <span className="font-medium">{v.value}</span></div>
                                }) : i.description }
                            </div>
                            <div className="flex flex-col basis-4/12 justify-center items-center">
                                <select value={selected.returnReason} onChange={(e) => { updateItem(selected, "returnReason", e.target.value) }} className="border-b-0">
                                    <option default selected value={-1}>Please Select</option>
                                    {REASONS.map((r, i) => { return <option key={i} value={i}>{r}</option> })}
                                </select>
                            </div>
                            <div className="flex flex-col basis-4/12 justify-center items-center">
                                <select value={selected.locationId} onChange={(e) => { updateItem(selected, "locationId", e.target.value) }} className="border-b-0">
                                    <option default selected value={0}>DO NOT RESTOCK</option>
                                    {locations && locations.length > 0 ? locations.map(o => { return <option key={o.id} value={o.id}>{o.name} ({stockLookup[i.variantId + o.id] || 0})</option> }): null}
                                </select>
                            </div>
                            <div className="flex flex-col text-base w-[40px] justify-center items-center">
                                <FontAwesomeIcon className="cursor-pointer" icon={faClose} color={'red'} onClick={() => { removeItem(selected) }}/>
                            </div>
                        </div> 
                    }

                    return <div className="flex flex-row pb-1 mb-1 border-b border-b-brand-grey text-sm relative">
                        <div className="flex flex-col basis-4/12 justify-center items-center">{i.productCode}</div>
                        <div className="flex flex-col basis-4/12 justify-center items-center">
                            {i.variations ? i.variations.sort((a, b) => a.name.localeCompare(b.name)).map((v, i) => {
                                return <div className="text-xs">{v.name}: <span className="font-medium">{v.value}</span></div>
                            }) : i.description }
                        </div>
                        <div className="flex flex-col basis-4/12 justify-center items-center">-</div>
                        <div className="flex flex-col basis-4/12 justify-center items-center">-</div>
                        <div className="flex flex-col text-base w-[40px] justify-center items-center">
                            <FontAwesomeIcon className="cursor-pointer" icon={faPlus} color={'green'} onClick={() => { addItem(i) }}/>
                        </div>
                    </div>  
                })}
            </div>
            <div className="flex flex-row justify-center">
                <div className="flex flex-col mt-5 mr-5 w-[125px]">
                    <label className="mb-1">Refund Amount:</label>
                    <input className="flex-1"
                        type="number"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </div>
                <div className="flex flex-col mt-5 ml-5 w-[125px]">
                    <label className="mb-1">Refund Type:</label>
                    <select value={refundType} onChange={(e) => { setRefundType(e.target.value) }}>
                        <option disabled selected value={-1}></option>
                        <option value={0}>Cash</option>
                        <option value={1}>Card</option>
                        <option value={3}>Credit Note</option>
                        {order.customer ? <option value={4}>Loyalty Points</option> : null}
                        <option value={5}>Order Credit</option>
                        <option value={6}>Voucher</option>
                    </select>
                </div>
            </div>
        </div>
        {hasFixedDiscount ? <div className="text-center text-sm mt-4">Refund cannot be automatically calculated as order has a fixed discount, Buy X For Y or Buy X Get Y discount.</div> : <div className="text-center text-sm mt-4">Please ensure the refund value is correct before confirming.</div>}
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={() => onClose()}>Cancel</div>
            <div className="flex btn" onClick={() => onSubmit()}>OK</div>
        </div>
    </Modal>;
}

export default TillReturnModal;