import APIFetch from "./APIFetch";

const Initialise = () => {
    return Promise.all([
        APIFetch("GET", "department")
        .then(result => {
            if(result.ok) {
                DataCache.department = result.data;
                var lookup = {};
                result.data.forEach(d => {
                    lookup[d.id] = d;
                })
                DataCache.departmentLookup = lookup;
                return result.data;
            } else {
                return null;
            }
        })
        .catch(() => {
            return null;
        }),

        APIFetch("GET", "supplier")
        .then(result => {
            if(result.ok) {
                DataCache.supplier = result.data;
                var lookup = {};
                result.data.forEach(d => {
                    lookup[d.id] = d;
                })
                DataCache.supplierLookup = lookup;
                return result.data;
            } else {
                return null;
            }
        })
        .catch(() => {
            return null;
        }),

        APIFetch("GET", "category")
        .then(result => {
            if(result.ok) {
                DataCache.category = result.data;
                var lookup = {};
                result.data.forEach(d => {
                    lookup[d.id] = d;
                })
                DataCache.categoryLookup = lookup;
                return result.data;
            } else {
                return null;
            }
        })
        .catch(() => {
            return null;
        }),

        APIFetch("GET", "manufacturer")
        .then(result => {
            if(result.ok) {
                DataCache.manufacturer = result.data;
                var lookup = {};
                result.data.forEach(d => {
                    lookup[d.id] = d;
                })
                DataCache.manufacturerLookup = lookup;
                return result.data;
            } else {
                return null;
            }
        })
        .catch(() => {
            return null;
        }),

        APIFetch("GET", "location")
        .then(result => {
            DataCache.location = result.data;
            var lookup = {};
            result.data.forEach(d => {
                lookup[d.id] = d;
            })
            DataCache.locationLookup = lookup;
            return result.data;
        })
        .catch(() => {
            return null;
        }),

        APIFetch("GET", "product/catalog")
        .then(result => {
            DataCache.product = result.data;
            var lookup = {};
            result.data.forEach(d => {
                lookup[d.id] = d;
            })
            DataCache.productLookup = lookup;
            return result.data;
        })
        .catch(() => {
            return null;
        }),

        APIFetch("GET", "producttag")
        .then(result => {
            if(result.ok) {
                DataCache.tag = result.data;
                var lookup = {};
                result.data.forEach(d => {
                    lookup[d.id] = d;
                })
                DataCache.tagLookup = lookup;
                return result.data;
            } else {
                return null;
            }
        })
        .catch(() => {
            return null;
        }),

        APIFetch("GET", 'settings')
        .then(result => {
            if(result.ok) {
                var formattedSettings = [...result.data];
                formattedSettings.forEach(s => {
                    if(s.name === 'vat-rate') {
                        DataCache.vatRate = 1 + (s.value / 100);
                    }
                })
            }
        })
        .catch(e => {
        })
    ]);
}

const DataCache = {
    initialise: Initialise,
    department: null,
    manufacturer: null,
    category: null,
    supplier: null,
    product: null,
    tag: null,
    departmentLookup: null,
    categoryLookup: null,
    manufacturerLookup: null,
    supplierLookup: null,
    productLookup: null,
    tagLookup: null,
    vatRate: '1.2'
};

export default DataCache;