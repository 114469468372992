import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import OrderManagementPanel from "./OrderManagementPanel";
import TillSelectModal from "./TillSelectModal";

function OrderManagementPage() {
    const navigate = useNavigate();
    const [selectingTill, setSelectingTill] = useState(null);

    const onSelect = (orderId) => {
        setSelectingTill(orderId);
    }

    const openTill = (tillId, orderId) => {
        navigate(`/till/${tillId}/${orderId}`, { state: { backUrl: '/orders' } });
    }

    return (
        <div className="App">
            {selectingTill ? <TillSelectModal onClose={() => setSelectingTill(null)} onSuccess={(id) => { openTill(id, selectingTill); setSelectingTill(null); }}/> : null}
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <Sidebar />
                <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                    <OrderManagementPanel onSuccess={(id) => { onSelect(id); }} heading={true}/>
                </div>
            </div>
        </div>
    )
}

export default OrderManagementPage;