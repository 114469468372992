import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { SpinnerCircular } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import ItemCreationModal from "../../components/ItemCreationModal";
import Modal from "../../components/Modal";
import ItemEditPanel from "../../components/ItemEditPanel";
import ItemCard from "../../components/ItemCard";

function SupplierManagement() {
    const [suppliers, setSuppliers] = useState(null);
    const [message, setMessage] = useState(null);
    const [creation, setCreation] = useState(false);
    const [selected, setSelected] = useState(null);
    const [pendingDelete, setPendingDelete] = useState(false);

    useEffect(() => {
        APIFetch("GET", "supplier")
        .then(result => {
            if(result.ok) {
                setSuppliers(result.data);
            } else {
                setSuppliers([]);
            }
        })
        .catch(() => {
            setSuppliers([]);
        })
    }, []);

    const onDelete = (supplier) => {
        if(!pendingDelete || pendingDelete !== supplier) {
            setPendingDelete(supplier);
        } else {
            APIFetch("DELETE", `supplier/${supplier.id}`)
            .then(result => {
                if(result.ok) {
                    var newSuppliers = [...suppliers];
                    newSuppliers = newSuppliers.filter(l => l.id !== supplier.id);
                    setSuppliers(newSuppliers);
                    setPendingDelete(null);
                    setMessage("Supplier deleted successfully.");
                } else {
                    setMessage("An error occurred when attempting to delete the supplier.");
                }
            })
            .catch(() => {
                setMessage("An error occurred when attempting to delete the supplier.");
            })
        }
    }

    const createSupplier = (supplier) => {
        var newSuppliers = [...suppliers];
        newSuppliers.push(supplier);
        newSuppliers.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        setSuppliers(newSuppliers);
        setCreation(false);
    }

    const onEdit = (supplier) => {
        var newSuppliers = [...suppliers];
        var index = newSuppliers.findIndex(a => a.id === supplier.id);
        newSuppliers[index] = supplier;
        setSuppliers(newSuppliers);
    }

    return (
        <div className="App">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { creation ? <ItemCreationModal onClose={() => setCreation(false)} itemType="supplier" heading="Create New Supplier" onCreate={createSupplier} additionalFields={[{ name: "Address Line 1", type: "string", propertyName: "address1", defaultValue: creation.address1 }, { name: "Address Line 2", type: "string", propertyName: "address2", defaultValue: creation.address2 }, { name: "Postcode", type: "string", propertyName: "postcode", defaultValue: creation.postcode }, { name: "Telephone", type: "string", propertyName: "telephone", defaultValue: creation.telephone }]}/> : null }
        { pendingDelete ? <Modal heading="Confirm Supplier Deletion" className="text-black" onClose={() => setPendingDelete(null)}>
            <div className="text-sm">Are you sure you wish to delete '{pendingDelete.name}'?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {onDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        <div className="flex flex-col md:flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="flex flex-row items-center ml-2 mt-9 mb-16">
                    <h1 className="text-left font-medium text-lg">Suppliers</h1>
                    <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => setCreation(true)}/>
                </div>
                <div className="min-w-[1000px] md:min-w-[0px] flex flex-row px-3 font-bold mb-2 text-sm mt-2">
                    <div className="flex flex-1 basis-3/12">Name</div>
                    <div className="flex flex-1 basis-4/12">Address 1</div>
                    <div className="flex flex-1 basis-3/12">Address 2</div>
                    <div className="flex flex-1 basis-2/12">Postcode</div>
                    <div className="flex flex-1 basis-2/12">Telephone</div>
                    <div className="flex flex-1 basis-[45px]"></div>
                </div>
                <div className="min-w-[1000px] md:min-w-[0px] flex flex-col flex-grow overflow-y-auto">
                    { suppliers && suppliers.length === 0 ? <div>No suppliers to display.</div> : null }
                    { suppliers ? suppliers.map(d => <ItemCard key={d.id} item={d} additionalFields={[{ value: d.address1, className: "basis-4/12" }, { value: d.address2, className: "basis-3/12" }, { value: d.postcode, className: "basis-2/12" }, { value: d.telephone, className: "basis-2/12" }]} nameClass={"basis-3/12"} onEdit={() => setSelected(d)} onDelete={onDelete} selected={selected === d}/>) : null }
                    <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular enabled={!suppliers} size={50} color="#24272b" secondaryColor="white" />
                    </div>
                </div>
            </div>
            { selected ? <ItemEditPanel key={selected.id + "edit"} itemType="supplier" item={selected} additionalFields={[{ name: "Address Line 1", type: "string", propertyName: "address1", defaultValue: selected.address1 }, { name: "Address Line 2", type: "string", propertyName: "address2", defaultValue: selected.address2 }, { name: "Postcode", type: "string", propertyName: "postcode", defaultValue: selected.postcode }, { name: "Telephone", type: "string", propertyName: "telephone", defaultValue: selected.telephone }]} onDelete={onDelete} onChange={(a) => { onEdit(a); }} onClose={() => { setSelected(null) }}/> : null }
        </div>
        </div>
    );
}

export default SupplierManagement;