import { useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";

const TillVoucherModal = ({ onClose, onSuccess }) => {
    const [message, setMessage] = useState(null);
    const [amount, setAmount] = useState(0);

    const onSubmit = () => {
        if(amount.length === 0 || parseFloat(amount) < 0.01) {
            setMessage("Voucher requires a value.");
            return;
        }
        onSuccess(amount);
    }

    return <Modal onClose={onClose} heading={'Buy Voucher'}  className="text-black text-left" windowClassName="w-[450px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-col mt-2 mb-4">
            <label>Value (£)</label>
            <input type="number" value={amount} placeholder="Amount..." className="flex-1" onChange={(e) => setAmount(e.target.value)}/>
        </div>
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={() => onClose()}>Cancel</div>
            <div className="flex btn" onClick={() => onSubmit()}>OK</div>
        </div>
    </Modal>;
}

export default TillVoucherModal;