import { useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const TillUserModal = ({ onClose, onSuccess }) => {
    const [message, setMessage] = useState(null);
    const [PIN, setPIN] = useState("");
    const [waiting, setWaiting] = useState(false);

    const onSubmit = () => {
        if(!PIN || PIN.length == 0) {
            setMessage("Please enter a PIN.");
            return;
        }
        if(!waiting) {
            setWaiting(true);
            APIFetch('GET', `account/${PIN}`)
            .then(result => {
                if(result.ok) {
                    onSuccess(result.data);
                } else if(result.status === 404) {
                    setMessage("Invalid PIN provided.");
                    setWaiting(false);
                } else {
                    setMessage("An error occurred during the user switch.");
                    setWaiting(false);
                }
            })
            .catch(e => {
                setMessage("An error occurred during the user switch.");
                setWaiting(false);
            });
        }
    }

    return <Modal onClose={onClose} heading={'Switch User'} className="text-black text-left flex flex-col" windowClassName="w-[450px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-col mt-4">
            <label>User PIN:</label>
            <input type="text" value={PIN} className="flex-1" onChange={(e) => setPIN(e.target.value)}/>
        </div>
        <div className="flex flex-row mt-12 justify-center">
            <div className="flex mr-4 btn" onClick={() => onClose()}>Cancel</div>
            <div className="flex mr-4 btn" onClick={() => onSubmit()}>OK</div>
        </div>
    </Modal>;
}

export default TillUserModal;