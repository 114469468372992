import { useState } from "react";
import LookupInput from "../../components/LookupInput";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const AddTagModal = ({onClose, onAdd, productId }) => {
    const [tag, setTag] = useState(null);
    const [category, setCategory] = useState(null);
    const [message, setMessage] = useState(null);

    const addTag = () => {
        APIFetch('POST', `producttag/add/${productId}/${category}/${tag}`)
        .then(result => {
            if(result.ok) {
                onAdd(result.data);
            } else if(result.status === 409) {
                onAdd();
            } else {
                setMessage("An error occurred when attempting to apply the tag.");
            }
        })
        .catch(e => {
            setMessage("An error occurred when attempting to apply the tag.");
        });
    }

    return <Modal onClose={onClose} heading="Select/Create Tag" className="text-black text-center" windowClassName="w-[450px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-col mt-2">
            <label>Tag Type:</label>
            <LookupInput className="flex flex-1" itemType={'tag'} keyName="category" onChange={(value) => { setCategory(value) }}/>
        </div>
        <div className="flex flex-col mt-2">
            <label>Tag Value:</label>
            <LookupInput className="flex flex-1" itemType={'tag'} onChange={(value) => { setTag(value) }}/>
        </div>
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={onClose}>Cancel</div>
            <div className="flex btn" onClick={() => { addTag() }}>Add</div>
        </div>
    </Modal>;
}

export default AddTagModal;