import { faChevronDown, faChevronUp, faLink, faPlus, faSave, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { SpinnerCircular } from "spinners-react";
import MessageModal from "../../components/MessageModal";
import APIFetch, { uploadFiles } from "../../utilities/APIFetch";
import MediaLinkModal from "./MediaLinkModal";
import appConfig from "../../appConfig.json";

const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
  
    // change background colour if dragging
    background: isDragging ? '#1B76FA' : null,
    color: isDragging ? 'white' : null,
  
    // styles we need to apply on draggables
    ...draggableStyle,
});

const objectToFormData = (obj, form, namespace) => {
    var fd = form || new FormData();
    var formKey;
    
    for(var property in obj) {
      if(obj.hasOwnProperty(property)) {

        if((obj[property] instanceof File)) {
            fd.append('files', obj[property]);
        }
        
        if(namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = property;
        }
       
        // if the property is an object, but not a File,
        // use recursivity.
        if(typeof obj[property] === 'object') {
          objectToFormData(obj[property], fd, formKey);
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
        
      }
    }
    
    return fd;
};

const MediaSection = forwardRef(({ productId, variations }, ref) => {
    const [loading, setLoading] = useState(true);
    const [sets, setSets] = useState([]);
    const [message, setMessage] = useState(null);
    const [addingLink, setAddingLink] = useState(null);
    const [mediaVisible, setMediaVisible] = useState(false);
    const inputFile = useRef(null);
    const [inputSet, setInputSet] = useState(null);
    const [processing, setProcessing] = useState(false);

    useImperativeHandle(ref, () => ({
        saveChanges() {
          saveMedia(true);
        },
      }))

    useEffect(() => {
        setLoading(true);

        APIFetch('GET', `productmedia/${productId}`)
        .then(result => {
            if(result.ok) {
                setSets([].concat(result.data).sort((a,b) => {
                    if (b.variantValues.length === 0)
                        return 1;
                    if (a.variantValues.length === 0)
                        return -1;
                    return a.variantValues[0].value.localeCompare(b.variantValues[0].value);
                }));
            } else {
                setMessage("An error occured when loading product media.");
            }
        })
        .catch((e) => {
            setMessage("An error occured when loading product media.");
        });

        setLoading(false);
    }, [productId]);

    const saveMedia = async (parent) => {
        if(!processing) {
            setProcessing(true);

            var dataObject = {
                sets: [],
                files: []
            };
            
            dataObject.sets = sets.map(s => {
                return { 
                    id: s.id,
                    variantValues: s.variantValues,
                    media: s.media.map(m => {
                        if(m.tempId) {
                            dataObject.files.push(new File([m.blob], m.tempId));
                        }

                        return {
                            id: m.id,
                            tempId: m.tempId,
                            order: m.order
                        }
                    })
                }
            });

            var fd = objectToFormData(dataObject);

            uploadFiles('POST', `productmedia/${productId}`, fd, null)
            .then(result => {
                if(result.ok) {
                    setSets([].concat(result.data).sort((a,b) => {
                        if (b.variantValues.length === 0)
                            return 1;
                        if (a.variantValues.length === 0)
                            return -1;
                        return a.variantValues[0].value.localeCompare(b.variantValues[0].value);
                    }));
                    if(!parent) setMessage("Images successfully saved.");
                } else {
                    setMessage("An error occurred while attempting to save the product images.");
                }
                setProcessing(false);
            })
            .catch(() => {
                setMessage("An error occurred while attempting to save the product images.");
                setProcessing(false);
            });
        }
    }

    const addMediaSet = () => {
        var newSets = sets.concat([{
            id: null,
            media: [],
            variantValues: []
        }]);

        setSets(newSets);
    }

    const removeMediaSet = (set) => {
        setSets(sets.filter(s => s != set));
    }

    const addMedia = (set) => {
        setInputSet(set);
        inputFile.current.click();
    }

    const handleFile = (e) => {
        var newMedia = [];

        for(let i = 0; i < e.target.files.length; i++) {
            let f = e.target.files[i];

            newMedia.push({
                id: null,
                path: URL.createObjectURL(f),
                blob: f,
                order: Math.max(...inputSet.media.map(o => o.order), ...newMedia.map(o => o.order), -1) + 1,
                tempId: (Math.random() * 10000).toFixed().toString()
            });
        }

        inputSet.media = inputSet.media.concat(newMedia);

        setSets([...sets]);
    }

    const removeMedia = (set, media) => {
        set.media = set.media.filter(m => m != media);

        set.media.forEach((m, i) => {
            m.order = i;
        });

        setSets([...sets]);
    }

    const setLinks = (set, links) => {
        var newLinks = [];
        
        Object.keys(links).forEach(key => {
            if(links[key] !== '-1') {
                var variation = variations.find(v => v.id === key);
                newLinks.push({
                    fieldId: key,
                    fieldName: variation.name,
                    value: links[key]
                });
            }
        });

        var newFlatLinks = [];
        newLinks.forEach(l => newFlatLinks.push(l.fieldId + l.fieldName + l.value));

        // Check for conflict
        for(let i = 0; i < sets.length; i++) {
            let s = sets[i];
            let myFlatLinks = [];
            s.variantValues.forEach(l => myFlatLinks.push(l.fieldId + l.fieldName + l.value));

            if(myFlatLinks.sort().join(',') === newFlatLinks.sort().join(',')){
                setMessage("A media set with these linked variations already exists.");
                return;
            }
        }

        set.variantValues = newLinks;

        setSets([...sets]);
        setAddingLink(null);
    }

    const onDragEnd = (result, set) => {
        if (!result.destination) {
            return;
        }
    
        array_move(set.media, result.source.index, result.destination.index);

        set.media.forEach((m, i) => {
            m.order = i;
        });
    
        setSets([...sets]);
    }

    return <div className="flex flex-col flex-1 justify-start p-4 max-w-full">
        {addingLink ? <MediaLinkModal onClose={() => { setAddingLink(null); }} onLink={(links) => { setLinks(addingLink, links) }} variations={variations || []} existingVariations={addingLink.variantValues}/> : null}
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-row items-center border-b border-b-brand-grey pb-2">
            <FontAwesomeIcon icon={mediaVisible ? faChevronUp : faChevronDown} className="mr-2 cursor-pointer hover:text-brand-grey-alt" onClick={() => { setMediaVisible(!mediaVisible) }}/>
            <h2 className="font-bold">Media Sets ({sets.length})</h2>
            <FontAwesomeIcon icon={faPlus} className="ml-2 cursor-pointer hover:text-brand-grey-alt" onClick={() => { addMediaSet(); setMediaVisible(true); }}/>
            <FontAwesomeIcon icon={faSave} className="ml-2 cursor-pointer hover:text-brand-grey-alt" onClick={() => { saveMedia(); }}/>
        </div>
        <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={handleFile} multiple/>
        {mediaVisible ? <div className="w-full flex flex-grow flex-shrink flex-col mb-6 mt-1 relative">
            <SpinnerCircular enabled={loading} size={50} color="#24272b" secondaryColor="white" />
            {sets && sets.map ? sets.map(s => {
                return <div className="flex flex-col my-2 border-b border-b-brand-grey pb-4">
                    <div className="flex flex-row font-medium mb-2 items-center">
                        <div>{s.variantValues.length === 0 ? "Default" : (s.variantValues.length === 1 ? s.variantValues[0].value : s.variantValues[0].value + ', ' + s.variantValues[1].value )} ({s.media.length})</div>
                        <FontAwesomeIcon icon={faPlus} className="ml-2 cursor-pointer hover:text-brand-grey-alt" onClick={() => { addMedia(s) }}/>
                        <FontAwesomeIcon icon={faLink} className="ml-1 cursor-pointer hover:text-brand-grey-alt" onClick={() => { setAddingLink(s) }}/>
                        <FontAwesomeIcon icon={faTrash} className="ml-1 cursor-pointer hover:text-brand-grey-alt" onClick={() => { removeMediaSet(s) }}/>
                    </div>
                    <DragDropContext onDragEnd={(result) => { onDragEnd(result, s) }}>
                        <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div 
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="flex flex-row overflow-x-auto p-2">
                                {s.media.map((m, index) => {
                                    var imgPath = m.path && m.path.startsWith("http") ? m.path : appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].ASSETS_DOMAIN + m.path;

                                    return <Draggable key={m.id || m.tempId} draggableId={m.id || m.tempId} index={index}>
                                        {(provided, snapshot) => (
                                            <div 
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="flex flex-col relative items-center mr-2 rounded-lg bg-white shadow p-6"
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                                <FontAwesomeIcon icon={faTimes} className="absolute right-3 top-3 ml-2 cursor-pointer hover:text-brand-grey-alt" onClick={() => { removeMedia(s, m) }}/>
                                                <div>
                                                    <img src={m.tempId ? m.path : imgPath} className="object-contain" width={150} height={150}/>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                        </Droppable>
                    </DragDropContext>
                </div>
            }) : null}
        </div> : null }
    </div>
});

export default MediaSection;