import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { SpinnerCircular } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import ItemCreationModal from "../../components/ItemCreationModal";
import Modal from "../../components/Modal";
import ItemEditPanel from "../../components/ItemEditPanel";
import ItemCard from "../../components/ItemCard";
import DataCache from '../../utilities/DataCache';

const AdditionalFields = [{ name: "Department", type: "cache", propertyName: "departmentId" }];

function CategoryManagement() {
    const [categories, setCategories] = useState(null);
    const [message, setMessage] = useState(null);
    const [creation, setCreation] = useState(false);
    const [selected, setSelected] = useState(null);
    const [search, setSearch] = useState('');
    const [filteredCategories, setFilteredCategories] = useState(null)
    const [pendingDelete, setPendingDelete] = useState(false);

    useEffect(() => {
        APIFetch("GET", "category")
        .then(result => {
            if(result.ok) {
                setCategories(result.data);
            } else {
                setCategories([]);
            }
        })
        .catch(() => {
            setCategories([]);
        })
    }, []);

    useEffect(() => {
        if(!search || search.length === 0) {
            setFilteredCategories(null);
        } else {
            var searchLower = search.toLowerCase();
            setFilteredCategories(categories.filter(c => {
                return c.name.toLowerCase().includes(searchLower) || c.departmentName.toLowerCase().includes(searchLower);
            }));
        }
    }, [search]);

    const reapplyFilter = (newCategories) => {
        if(filteredCategories !== null) {
            var searchLower = search.toLowerCase();
            setFilteredCategories(newCategories.filter(c => {
                return c.name.toLowerCase().includes(searchLower) || c.departmentName.toLowerCase().includes(searchLower);
            }));
        }
    }

    const onDelete = (category) => {
        if(!pendingDelete || pendingDelete !== category) {
            setPendingDelete(category);
        } else {
            APIFetch("DELETE", `category/${category.id}`)
            .then(result => {
                if(result.ok) {
                    var newCategories = [...categories];
                    newCategories = newCategories.filter(l => l.id !== category.id);
                    setCategories(newCategories);
                    reapplyFilter(newCategories);
                    setPendingDelete(null);
                    setMessage("Category deleted successfully.");
                } else {
                    setMessage("An error occurred when attempting to delete the category.");
                }
            })
            .catch(() => {
                setMessage("An error occurred when attempting to delete the category.");
            })
        }
    }

    const createCategory = (category) => {
        var newCategories = [...categories];
        newCategories.push(category);
        newCategories.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        setCategories(newCategories);
        reapplyFilter(newCategories);
        setCreation(false);
        window.location.reload();
    }
    
    const onEdit = (category) => {
        var newCategories = [...categories];
        var index = newCategories.findIndex(a => a.id === category.id);
        newCategories[index] = category;
        newCategories[index].departmentName = DataCache.departmentLookup[category.departmentId] ? DataCache.departmentLookup[category.departmentId].name : "Unknown";
        setCategories(newCategories);
        reapplyFilter(newCategories);
        window.location.reload();
    }

    const detectEnter = (e) => {
        if(e.key === "Enter") {
            setSearch(e.target.value);
        }
    }

    return (
        <div className="App">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { creation ? <ItemCreationModal onClose={() => setCreation(false)} itemType="category" heading="Create New Category" onCreate={createCategory} additionalFields={AdditionalFields}/> : null }
        { pendingDelete ? <Modal heading="Confirm Category Deletion" className="text-black" onClose={() => setPendingDelete(null)} windowClassName="w-[450px]">
            <div className="text-sm">Are you sure you wish to delete '{pendingDelete.name}'?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {onDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        <div className="flex flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="flex flex-row items-center ml-2 mt-9 mb-16">
                    <h1 className="text-left font-medium text-lg">Categories</h1>
                    <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => setCreation(true)}/>
                    <input type="text" placeholder="Search..." className="ml-4 mb-1 w-48" onKeyUp={detectEnter}/>
                </div>
                <div className="min-w-[1000px] md:min-w-[0px] flex flex-row px-3 font-bold mb-2 text-sm mt-2">
                    <div className="flex flex-1 basis-6/12">Name</div>
                    <div className="flex flex-1 basis-6/12">Department</div>
                    <div className="flex flex-1 basis-[45px]"></div>
                </div>
                <div className="min-w-[1000px] md:min-w-[0px] flex flex-col flex-grow overflow-y-auto">
                    { categories && categories.length === 0 ? <div>No categories to display.</div> : null }
                    { categories ? (filteredCategories || categories).map(d => <ItemCard key={d.id} item={d} additionalFields={[{ value: d.departmentName, className: "basis-6/12" }]} onEdit={() => setSelected(d)} onDelete={onDelete} selected={selected === d} nameClass={"basis-6/12"}/>) : null }
                    <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular enabled={!categories} size={50} color="#24272b" secondaryColor="white" />
                    </div>
                </div>
            </div>
            { selected ? <ItemEditPanel key={selected.id + "edit"} itemType="category" item={selected} additionalFields={[{ name: "Department", type: "cache", propertyName: "departmentId", defaultValue: selected.departmentId }]} onDelete={onDelete} onChange={(a) => { onEdit(a); }} onClose={() => { setSelected(null) }}/> : null }
        </div>
        </div>
    );
}

export default CategoryManagement;