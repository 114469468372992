import { useEffect, useState } from "react";
import { SpinnerCircular } from "spinners-react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";
import sortSizes from "../../utilities/SizeSort";

const TillVariantSelector = ({ onClose, onSuccess, product, location }) => {
    const [message, setMessage] = useState(null);
    const [variants, setVariants] = useState(null);
    const [variations, setVariations] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [stockWarning, setStockWarning] = useState(null);

    useEffect(() => {
        getProductData();
    }, []);

    const updateOption = (id, value) => {
        var newOptions = {...selectedOptions};
        newOptions[id] = value;
        setSelectedOptions(newOptions);
    }

    const getProductData = () => {
        // Get product variants
        APIFetch("GET", `variant/${product.id}`)
        .then(result => {
            if(result.ok) {
                setVariants(result.data.variants);
                setVariations(result.data.variations);
                setLoading(false);
            } else {
                setMessage("An error occurred when loading the product data.");
                setLoading(false);
            }
        })
        .catch(() => {
            setMessage("An error occurred when loading the product data.");
            setLoading(false);
        });
    }

    const findVariant = () => {
        var match = variants.find(v => {
            var success = true;

            v.variations.forEach(vVariation => {
                if(selectedOptions[vVariation.variationId] !== vVariation.value) {
                    success = false;
                }
            })

            return success;
        });

        if(!match) {
            setMessage("No item available for the selected options.");
        } else {
            var hasStock = match.stock.some(s => s.quantity > 0);
            
            if(hasStock) {
                onSuccess(match, product);
            } else {
                setStockWarning({ match, product });
            }
        }
    }

    return <Modal onClose={onClose} heading={'Select Variant'} className="text-black text-left" windowClassName="w-[450px]">
        { stockWarning ? <Modal heading="Item out of stock" className="text-black text-center" onClose={() => setStockWarning(null)} windowClassName="w-[450px]">
            <div className="text-sm">Continue to add to order?</div>
            <div className="flex flex-row mt-4 justify-center">
                <div className="btn mr-4" onClick={() => { onSuccess(stockWarning.match, stockWarning.product) }}>Continue</div>
                <div className="btn" onClick={() => { setStockWarning(null) }}>Cancel</div>
            </div>
        </Modal> : null }
        <MessageModal message={message} onClose={() => setMessage(null)} />
        {loading ? <SpinnerCircular size={50} color="#24272b" secondaryColor="white" /> :
        <div className="flex flex-col">
            {variations.map(v => {
                return <div className="flex flex-col mt-4 mb-2">
                    <label>{v.name}: </label>
                    <select className="flex-1" value={selectedOptions[v.id] || ''} onChange={(e) => { updateOption(v.id, e.target.value) }}>
                        <option value='' hidden disabled></option>
                        {v.values ? sortSizes(v.values, "value").map((o, idx) => { return <option key={o.value} value={o.value}>{o.value}</option> }): null}
                    </select>
                </div>
            })}
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn mr-4" onClick={() => findVariant()}>OK</div>
                <div className="btn" onClick={() => onClose()}>Cancel</div>
            </div>
        </div>
        }
    </Modal>;
}

export default TillVariantSelector;