import { format } from "date-fns";
import { forwardRef } from "react";

const groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
};

function ReplenishmentPrint({ items }, ref) {
    const getElements = () => {
        var groups = groupBy(items, 'departmentName');
        var keys = Object.keys(groups).sort((a, b) => a.localeCompare(b));
    
        return keys.map(k => {
            var group = groups[k];
            return <div key={k}>
                <div className="text-lg font-bold p-2 border-t border-brand-grey">Department: {k}</div>
                {group.sort((a, b) => a.product.name.localeCompare(b.product.name)).map((s, idx) => {
                    let bgClass = "flex p-2 text-xs font-medium";

                    return <div className={"text-left grid grid-cols-[14%_37%_17.5%_9.5%_7.5%_7.5%_6.5%] border-brand-grey-light" + (idx === 0 ? "" : " border-t")}>
                        <div key={s.id + "productcode"} className={"items-center " + bgClass}>{s.product.productCode}</div>
                        <div key={s.id + "productname"} className={"cursor-pointer underline items-center " + bgClass}>{s.product.name}</div>
                        <div key={s.id + "options"} className={" flex flex-col justify-center " + bgClass}>
                            {s.variant.variations.map((v, i) => {
                                return <div key={s.id + "options" + i} className="whitespace-nowrap">
                                    {v.name}: {v.value}
                                </div>
                            })}
                        </div>
                        <div key={s.id + "warehouse"} className={"items-center justify-center " + bgClass}>{s.warehouseQuantity}</div>
                        <div key={s.id + "shop"} className={"items-center justify-center " + bgClass}>{s.quantity}</div>
                        <div key={s.id + "missing"} className={"items-center justify-center " + bgClass}>{s.variant.replenishmentMinimum - s.quantity}</div>
                        <div key={s.id + "actions"} className={"cursor-pointer underline items-center " + bgClass}></div>
                    </div>
                })}
            </div>
        });
    };

    return <div ref={ref} className="onlyPrint absolute top-0 left-0 w-full max-w-full m-0 p-2 text-xs">
        <div className="font-bold w-100 mb-2 text-2xl p-2">Replenishment Report - {format(Date.now(), "dd/MM/yy")}</div>
        { items && items.length > 0 ? <div className="text-left grid grid-cols-[14%_37%_17.5%_9.5%_7.5%_7.5%_6.5%]">
            <div className="p-2 font-bold bg-white">Product Code</div>
            <div className="p-2 font-bold bg-white">Description</div>
            <div className="p-2 font-bold bg-white">Options</div>
            <div className="p-2 font-bold bg-white items-center justify-center text-center">Warehouse</div>
            <div className="p-2 font-bold bg-white items-center justify-center text-center">Shop</div>
            <div className="p-2 font-bold bg-white items-center justify-center text-center">To Pick</div>
            <div className="p-2 font-bold bg-white items-center justify-center text-center">Picked</div>
        </div> : null }
        { items && items.length > 0 ? 
           getElements() : null
        }
    </div>;
}

export default forwardRef(ReplenishmentPrint);