import { faMaximize, faMinimize } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import StockGridItem from "./StockGridItem";
import DoubleScrollbar from 'react-double-scrollbar';

function StockGrid({ xAxisId, xAxisValues, yAxisId, yAxisValues, product, variants, locations, onVariantChange, onStockChange, onSelect, onToggleAll, selected }) {
    const [missingEntries, setMissingEntries] = useState([]);
    const [variantLookup, setVariantLookup] = useState({});

    useEffect(() => {
        var yAxisOverride = yAxisValues || [];
        var entries = xAxisValues.flatMap(x => yAxisOverride.map(y => { return x + '/' + y; }));
        var newLookup = {};

        variants.forEach(v => {
            var xField = v.variations.find(a => a.variationId === xAxisId);
            var yField = v.variations.find(a => a.variationId === yAxisId);

            if(xField && yField) {
                var index = entries.findIndex(v => v === (xField.value + '/' + yField.value));
                if(index > -1) {
                    entries.splice(index, 1);
                }

                newLookup[xField.value + '/' + yField.value] = v;
            } else if(xField) {
                newLookup[xField.value] = v;
            }
        });

        setMissingEntries(entries);
        setVariantLookup(newLookup);
    }, [variants, xAxisValues, yAxisValues]);

    const toggleVariant = (variant, isSelected) => {
        onSelect(variant, isSelected);
    }

    const toggleVariantValue = (value) => {
        var matches = variants.filter(v => v.variations.find(a => a.value === value));
        onSelect(matches, matches.some(v => !selected.includes(v)) ? false : true);
    }

    const updateStock = (variant, location, adjustment) => {
        // Allow partially complete negative number
        if(adjustment === '-' || adjustment === "") {
            onStockChange(variant, location, adjustment);
            return;
        }

        let parsed = parseInt(adjustment);
        // Check text is a valid number
        if(parsed.toString() === adjustment) {
            onStockChange(variant, location, parsed);
        }
    }

    const updateVariant = (variant, field, value) => {
        onVariantChange(variant, field, value);
    }

    return <div className="max-h-stock-grid pb-4 mt-4">
        <DoubleScrollbar>
            <table className="max-h-full bordered-table-sticky relative">
                <tr>
                <td className="col sticky top-0 left-0 text-center text-sm z-[11] bg-white min-w-[50px] min-h-[50px]">
                    <FontAwesomeIcon icon={selected.length === variants.length ? faMinimize : faMaximize} className="cursor-pointer hover:text-brand-grey-alt" onClick={onToggleAll}/>
                </td>
                {xAxisValues && xAxisValues.length > 0 ? xAxisValues.map((v, i) => {
                    return <th scope="col"  className={"col sticky top-0 p-4 bg-white text-center text-sm z-[10] pointer-events-none"}>
                        <div onClick={() => { toggleVariantValue(v) }} className="cursor-pointer pointer-events-auto">
                            {v}
                        </div>
                    </th>;
                }) : <th scope="col" className={"col sticky top-0 p-4 bg-white text-center text-sm z-[8] pointer-events-none"}>Default</th>}
                </tr>
                {(yAxisValues && yAxisValues.length > 0 ? yAxisValues : [""]).map((y, yI) => {
                    return <tr className="">
                        <th scope="row" className={(y ? "row sticky left-0 p-4 bg-white pointer-events-none text-sm z-[10]" : "row pointer-events-none z-[9]")}>
                            <div onClick={() => { toggleVariantValue(y) }} className="cursor-pointer pointer-events-auto">
                                {y || ""}
                            </div>
                        </th>
                        {xAxisValues.map((x, xI) => {
                            let key = y === "" ? x : x + '/' + y;
                            let variant = variantLookup[key];

                            if(missingEntries.includes(key)) {
                                return <td className={"border border-brand-soft-borders p-4 cursor-pointer"}>N/A</td>;
                            }

                            if(!selected.includes(variant)) {
                                return <td className={"border border-brand-soft-borders p-4 cursor-pointer"} onClick={() => toggleVariant(variant, false)}>
                                    {locations.map(l => {
                                        let locStock = variant && variant.stock ? variant.stock.find(s => s.locationId == l.id) : null;
                                        
                                        return <div className="flex flex-row">
                                            <div className="text-sm font-medium">{l.name}: <strong>{locStock ? locStock.quantity : "0"}</strong></div>
                                        </div>
                                    })}
                                </td>
                            }

                            // Bad hack for saving order
                            variant.lastY = yI;
                            variant.lastX = xI;

                            return <StockGridItem product={product} variant={variant} toggleVariant={toggleVariant} updateVariant={updateVariant} updateStock={updateStock} locations={locations}/>;
                        })}
                    </tr>
                })}
            </table>
        </DoubleScrollbar>
    </div>;
}

export default StockGrid;