import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import appConfig from '../appConfig.json';

function ItemCard({ item, onEdit, onDelete, selected, additionalFields, onClick, nameClass, nameLink, hasImage, additionalActions, slim }) {
    var imgPath = item.image ? item.image.startsWith("http") ? item.image : appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].ASSETS_DOMAIN + item.image : "";

    return <div className={"flex flex-row items-center border-b border-brand-grey mt-1 text-left font-medium text-sm " + (slim ? " p-1" : "p-3") + (selected ? " bg-brand-grey text-white" : "") + (onClick ? " cursor-pointer" : "")} onClick={onClick ? () => { onClick(item) } : null}>
        {hasImage? <div className="basis-1/12 cursor-pointer p-2" onClick={nameLink}>
            {item.image ? <img className="max-h-24" src={imgPath} /> : null }
        </div> : null }
        {item.name ? <div className={" " + (nameLink ? " underline cursor-pointer " : "") + nameClass} onClick={nameLink}>
            {item.name}
        </div> : null }
        {additionalFields && additionalFields.length > 0 ? additionalFields.map(f => {
            if(f.component) return f.component;
            
            let value = f.value !== undefined ? f.value : f;
            let className = f.className ? " " + f.className : "";

            return <div className={className + (f.onClick ? " cursor-pointer" : "")} onClick={f.onClick}>
                {value}
            </div>
        }) : null }
        {onEdit || onDelete || (additionalActions && additionalActions.length > 0) ? <div className="flex flex-row basis-[45px]">
            {additionalActions && additionalActions.length ? additionalActions.map(a => {
                if(a.icon) {
                    return <FontAwesomeIcon icon={a.icon} onClick={(e) => { e.stopPropagation(); a.action(); }} className="cursor-pointer mr-4 hover:text-brand-grey-alt"/>;
                } else {
                    return <div className="text-sm text-bold relative top-[-2px] underline mr-4 cursor-pointer" onClick={(e) => { e.stopPropagation(); a.action(); }}>{a.text}</div>
                }
            }) : null}
            {onEdit ? <FontAwesomeIcon icon={faEdit} onClick={(e) => { e.stopPropagation(); onEdit(item); }} className="cursor-pointer mr-4 hover:text-brand-grey-alt"/> : null}
            {onDelete ? <FontAwesomeIcon icon={faTrash} onClick={(e) => { e.stopPropagation(); onDelete(item); }} className="cursor-pointer hover:text-brand-grey-alt"/> : null }
        </div> : null }
    </div>;
}

export default ItemCard;