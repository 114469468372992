import Modal from "../../components/Modal";
import OrderManagementPanel from "../OrderManagement/OrderManagementPanel";

const TillOrderLookupModal = ({ onClose, onSuccess }) => {
    // Name search, VAT status, from/to
    return <Modal onClose={onClose} heading={'Orders'} className="text-black text-left flex flex-col" windowClassName="w-[90vw] h-[90vh]">
        <OrderManagementPanel onSuccess={onSuccess} />
    </Modal>;
}

export default TillOrderLookupModal;