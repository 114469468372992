import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { SpinnerCircular } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import ItemCreationModal from "../../components/ItemCreationModal";
import Modal from "../../components/Modal";
import ItemEditPanel from "../../components/ItemEditPanel";
import ItemCard from "../../components/ItemCard";

const AdditionalFields = [{ name: "Open Department", type: "bool", propertyName: "openOnly", defaultValue: false }];

function DepartmentManagement() {
    const [departments, setDepartments] = useState(null);
    const [message, setMessage] = useState(null);
    const [creation, setCreation] = useState(false);
    const [selected, setSelected] = useState(null);
    const [search, setSearch] = useState('');
    const [filteredDepartments, setFilteredDepartments] = useState(null)
    const [pendingDelete, setPendingDelete] = useState(false);

    useEffect(() => {
        APIFetch("GET", "department")
        .then(result => {
            if(result.ok) {
                setDepartments(result.data);
            } else {
                setDepartments([]);
            }
        })
        .catch(() => {
            setDepartments([]);
        })
    }, []);

    useEffect(() => {
        if(!search || search.length === 0) {
            setFilteredDepartments(null);
        } else {
            var searchLower = search.toLowerCase();
            setFilteredDepartments(departments.filter(d => {
                return d.name.toLowerCase().includes(searchLower);
            }));
        }
    }, [search]);

    const reapplyFilter = (newDepartments) => {
        if(filteredDepartments !== null) {
            var searchLower = search.toLowerCase();
            setFilteredDepartments(newDepartments.filter(d => {
                return d.name.toLowerCase().includes(searchLower);
            }));
        }
    }

    const onDelete = (department) => {
        if(!pendingDelete || pendingDelete !== department) {
            setPendingDelete(department);
        } else {
            APIFetch("DELETE", `department/${department.id}`)
            .then(result => {
                if(result.ok) {
                    var newDepartments = [...departments];
                    newDepartments = newDepartments.filter(l => l.id !== department.id);
                    setDepartments(newDepartments);
                    reapplyFilter(newDepartments);
                    setPendingDelete(null);
                    setMessage("Department deleted successfully.");
                } else {
                    setMessage("An error occurred when attempting to delete the department.");
                }
            })
            .catch(() => {
                setMessage("An error occurred when attempting to delete the department.");
            })
        }
    }

    const createDepartment = (department) => {
        var newDepartments = [...departments];
        newDepartments.push(department);
        newDepartments.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        setDepartments(newDepartments);
        reapplyFilter(newDepartments);
        setCreation(false);
        window.location.reload();
    }

    const onEdit = (department) => {
        var newDepartments = [...departments];
        var index = newDepartments.findIndex(a => a.id === department.id);
        newDepartments[index] = department;
        setDepartments(newDepartments);
        reapplyFilter(newDepartments);
        window.location.reload();
    }

    const detectEnter = (e) => {
        if(e.key === "Enter") {
            setSearch(e.target.value);
        }
    }

    return (
        <div className="App">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { creation ? <ItemCreationModal onClose={() => setCreation(false)} itemType="department" heading="Create New Department" onCreate={createDepartment} additionalFields={AdditionalFields}/> : null }
        { pendingDelete ? <Modal heading="Confirm Department Deletion" className="text-black" onClose={() => setPendingDelete(null)} windowClassName="w-[450px]">
            <div className="text-sm">Are you sure you wish to delete '{pendingDelete.name}'?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {onDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        <div className="flex flex-col md:flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="flex flex-row items-center ml-2 mt-9 mb-16">
                    <h1 className="text-left font-medium text-lg">Departments</h1>
                    <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => setCreation(true)}/>
                    <input type="text" placeholder="Search..." className="ml-4 mb-1 w-48" onKeyUp={detectEnter}/>
                </div>
                <div className="flex flex-row px-3 font-bold mb-2 text-sm mt-2">
                    <div className="flex flex-1 basis-full">Name</div>
                    <div className="flex flex-1 basis-[45px]"></div>
                </div>
                <div className="flex flex-col flex-grow overflow-y-auto">
                    { departments && departments.length === 0 ? <div>No departments to display.</div> : null }
                    { departments ? (filteredDepartments || departments).map(d => {
                        var formatted = {...d};
                        formatted.name = formatted.name + (formatted.openOnly ? " (Open Department)" : "");
                        return <ItemCard key={formatted.id} item={formatted} onEdit={() => setSelected(d)} onDelete={onDelete} selected={selected === d} nameClass="basis-full"/>
                    }) : null }
                    <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular enabled={!departments} size={50} color="#24272b" secondaryColor="white" />
                    </div>
                </div>
            </div>
            { selected ? <ItemEditPanel key={selected.id + "edit"} itemType="department" item={selected} onDelete={onDelete} onChange={(a) => { onEdit(a); }} onClose={() => { setSelected(null) }} additionalFields={AdditionalFields}/> : null }
        </div>
        </div>
    );
}

export default DepartmentManagement;