import { useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const CustomerEditModal = ({ customer, onClose, onSuccess }) => {
    const [message, setMessage] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [editAddress, setEditAddress] = useState();

    const [firstName, setFirstName] = useState(customer ? customer.firstName : "");
    const [surname, setSurname] = useState(customer ? customer.surname : "");
    const [email, setEmail] = useState(customer ? customer.email : "");
    const [phoneNumber, setPhoneNumber] = useState(customer ? customer.phoneNumber : "");

    
    const [name, setName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [postcode, setPostcode] = useState("");
    const [telephone, setTelephone] = useState("");
    const [country, setCountry] = useState("");

    const updateCustomer = () => {
        if(!waiting) {
            setWaiting(true);
            
            APIFetch('POST', customer.id ? `customer/${customer.id}` : 'customer', {
                firstName,
                surname,
                phoneNumber,
                email
            })
            .then(result => {
                if(result.ok) {
                    onSuccess({
                        id: customer.id || result.data.id,
                        firstName,
                        surname,
                        phoneNumber,
                        email,
                        dob: customer.dob,
                        pointBalance: customer.pointBalance,
                        addresses: customer.addresses
                    });
                } else {
                    setMessage("An error occurred when assigning the customer to the order.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when assigning the customer to the order.");
            })
            .finally(() => setWaiting(false));
        }
    }

    const updateAddress = (a) => {
        var update = false;
        if(a.id) {
            // Update
            update = true;
        }

        if(!name || !address1 || !postcode || !country) {
            setMessage("Missing one or more required fields.");
            return;
        }

        if(!waiting) {
            setWaiting(true);
            APIFetch('POST', update ? `address/${customer.id}` : 'address', {
                id: a.id,
                customerId: customer.id,
                name,
                address1,
                address2,
                city,
                postcode,
                telephone,
                country
            })
            .then(result => {
                if(result.ok) {
                    saveAddress(result.data);
                } else {
                    setMessage("An error occurred when attempting to create the address.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when attempting to create the address.");
            })
            .finally(() => {
                setWaiting(false);
            })
        }
    }

    const saveAddress = (newAddress) => {
        var addresses = [newAddress, ...customer.addresses.filter(a => a.id !== newAddress.id)];

        setEditAddress(false);

        onSuccess({...customer, addresses: addresses});
    }

    const beginEdit = (a) => {
        setEditAddress(a)
        setName(a.name);
        setAddress1(a.address1);
        setAddress2(a.address2);
        setPostcode(a.postcode);
        setCity(a.city);
        setTelephone(a.telephone);
        setCountry(a.country);
    }

    const deleteAddress = (address) => {
        if(!waiting) {
            setWaiting(true);
            APIFetch('delete', `address/${address.id}`)
            .then(result => {
                if(result.ok) {
                    var addresses = customer.addresses.filter(a => a.id !== address.id);
                    onSuccess({...customer, addresses: addresses});
                } else {
                    setMessage("An error occurred when attempting to delete the address.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when attempting to delete the address.");
            })
            .finally(() => {
                setWaiting(false);
            })
        }
    }

    return <Modal onClose={onClose} className="" windowClassName="px-12 pb-10 pt-10 w-[95vw] max-w-[875px] max-h-[597px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="text-xl text-brand-grey mt-6 mb-6 text-left">
            Update Customer Details
        </div>
        <div className="flex flex-row mt-2 text-left min-h-0">
            <div className="flex flex-col p-2 pb-8 text-xs w-[300px] pr-14">
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">First Name*:</div>
                    <input type="text"
                        value={firstName} 
                        className="mb-1 text-sm flex-1" 
                        onChange={(e) => { setFirstName(e.target.value) }} placeholder=""/>
                </div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">Surname:</div>
                    <input type="text"
                        value={surname} 
                        className="mb-1 text-sm flex-1" 
                        onChange={(e) => { setSurname(e.target.value) }} placeholder=""/>
                </div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">Phone Number:</div>
                    <input type="text"
                        value={phoneNumber} 
                        className="mb-1 text-sm flex-1" 
                        onChange={(e) => { setPhoneNumber(e.target.value) }} placeholder=""/>
                </div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">Email:</div>
                    <input type="text"
                        value={email} 
                        className="mb-1 text-sm flex-1" 
                        onChange={(e) => { setEmail(e.target.value) }} placeholder=""/>
                </div>
                <div className="btn btn-sm mt-8 text-sm mr-auto" onClick={() => { updateCustomer() }}>Save Changes</div>
            </div>
            <div className="flex flex-col p-2 pb-8 pl-14 border-l border-l-brand-grey min-h-[300px] flex-1">
                {editAddress ? <div className="flex flex-col text-sm flex-grow">
                    <div className="text-xs text-bold text-left mb-4 absolute top-[110px]">Edit Address</div>
                    <div className="flex flex-row flex-wrap max-w-full gap-x-6">
                        <div className="flex flex-col flex-1">
                            <div className="flex flex-col mr-2 mt-2">
                                <div className="mr-2 mt-2 mb-2 text-xs">Name*:</div>
                                <input type="text"
                                    value={name} 
                                    className="mb-1 text-sm" 
                                    onChange={(e) => { setName(e.target.value) }} placeholder=""/>
                            </div>
                            <div className="flex flex-col mr-2 mt-2">
                                <div className="mr-2 mt-2 mb-2 text-xs">Postcode*:</div>
                                <input type="text"
                                    value={postcode} 
                                    className="mb-1 text-sm" 
                                    onChange={(e) => { setPostcode(e.target.value) }} placeholder=""/>
                            </div>
                            <div className="flex flex-col mr-2 mt-2">
                                <div className="mr-2 mt-2 mb-2 text-xs">Telephone:</div>
                                <input type="text"
                                    value={telephone} 
                                    className="mb-1 text-sm" 
                                    onChange={(e) => { setTelephone(e.target.value) }} placeholder=""/>
                            </div>
                        </div>
                        <div className="flex flex-col flex-1">
                            <div className="flex flex-col mr-2 mt-2">
                                <div className="mr-2 mt-2 mb-2 text-xs">Address 1*:</div>
                                <input type="text"
                                    value={address1} 
                                    className="mb-1 text-sm" 
                                    onChange={(e) => { setAddress1(e.target.value) }} placeholder=""/>
                            </div>
                            <div className="flex flex-col mr-2 mt-2">
                                <div className="mr-2 mt-2 mb-2 text-xs">Address 2:</div>
                                <input type="text"
                                    value={address2} 
                                    className="mb-1 text-sm" 
                                    onChange={(e) => { setAddress2(e.target.value) }} placeholder=""/>
                            </div>
                            <div className="flex flex-col mr-2 mt-2">
                                <div className="mr-2 mt-2 mb-2 text-xs">City:</div>
                                <input type="text"
                                    value={city} 
                                    className="mb-1 text-sm" 
                                    onChange={(e) => { setCity(e.target.value) }} placeholder=""/>
                            </div>
                            <div className="flex flex-col mr-2 mt-2">
                                <div className="mr-2 mt-2 mb-2 text-xs">Country*:</div>
                                <input type="text"
                                    value={country} 
                                    className="mb-1 text-sm" 
                                    onChange={(e) => { setCountry(e.target.value) }} placeholder=""/>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row mt-8">
                        <div className="btn btn-sm mr-1 text-sm" style={{ minWidth: "130px" }}onClick={() => { updateAddress(editAddress) }}>Save Changes</div>
                        <div className="btn btn-sm mr-auto ml-1 text-sm" style={{ minWidth: "130px" }}onClick={() => { setEditAddress(null) }}>Cancel</div>
                    </div>
                </div> :
                <div className="flex flex-col min-h-0">
                    <div className="text-sm text-left mb-4 min-w-[275px]">Addresses</div>
                    <div className="overflow-y-auto">
                        {customer.addresses.filter(a => a.active === true).map(a => {
                            return <div className="flex flex-row border-b border-b-brand-grey p-2 hover:bg-brand hover:text-white cursor-pointer">
                                <div className="flex flex-col text-sm text-left">
                                    <div>{a.name}</div>
                                    <div className="">{a.address1}</div>
                                    {a.address2 ? <div className="">{a.address2}</div> : null }
                                    {a.city ? <div className="">{a.city}</div> : null }
                                    <div className="">{a.postcode}</div>
                                    {a.telephone ? <div className="">{a.telephone}</div> : null }
                                    <div className="">{a.country}</div>
                                </div>
                                <div className="flex justify-center items-center ml-auto">
                                    <FontAwesomeIcon icon={faEdit} className="flex cursor-pointer mr-2" onClick={(e) => { beginEdit(a) }} />
                                    <FontAwesomeIcon icon={faTrash} className="flex cursor-pointer" onClick={(e) => { deleteAddress(a) }} />
                                </div>
                            </div>
                        })}
                    </div>
                    <div className="btn btn-sm mt-4 mr-auto" onClick={() => { beginEdit({}) }}>Add New Address</div>
                </div> }
            </div>
        </div>
        <div className="border-b border-b-black"></div>
        <div className="flex flex-row mt-6 ml-auto">
            <div className="btn btn-sm text-sm" onClick={() => onClose()}>Close</div>
        </div>
    </Modal>;
}

export default CustomerEditModal;