import { useEffect, useState } from "react";
import APIFetch from "../../utilities/APIFetch";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

function CustomerLookupWidget() {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [orderText, setOrderText] = useState("");

    const [pendingLookup, setPendingLookup] = useState(null);
    const [customerMatches, setCustomerMatches] = useState([]);

    useEffect(() => {
        if(!firstName && !surname && !email && !phoneNumber) {
            setCustomerMatches([]);
            return;
        }

        if(pendingLookup) {
            clearTimeout(pendingLookup);
        }

        setPendingLookup(setTimeout(() => {
            setPendingLookup(null);
            // TODO: 
            APIFetch('POST', 'customer/search', {
                firstName,
                surname,
                phoneNumber,
                email,
                dob: null
            })
            .then(result => {
                if(result.ok) {
                    setCustomerMatches(result.data);
                }
            })
            .catch(e => {

            });
        }, 500));
    }, [firstName, surname, email, phoneNumber]);

    const detectEnter = (e) => {
        if(e.key === "Enter") {
            navigate(`/orders?search=${orderText}`)
        }
    }

    return (
        <div className="flex flex-col mt-4 ml-0 p-6 mb-auto flex-shrink shadow bg-white">
            <div className="text-lg text-bold font-semibold uppercase text-left mb-2 min-w-[275px]">Customer Lookup</div>
            <div className="flex flex-col mt-0 relative">
                <div className="flex flex-col text-sm">
                    <div className="flex flex-row">
                        <input type="text"
                            value={firstName} 
                            className="mb-1 pl-9 p-2 text-sm flex-1 border border-black"
                            onChange={(e) => { setFirstName(e.target.value) }}
                            placeholder="Quick search..."/>
                        <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute text-lg ml-[9px] mt-[9px]"/>
                        {firstName && firstName.length > 0 ? <FontAwesomeIcon icon={faClose} onClick={() => { setFirstName(""); setCustomerMatches(null) }} className="absolute right-0 text-lg mr-[9px] mt-[9px] cursor-pointer"/> : null }
                    </div>
                </div>
                { customerMatches && customerMatches.length ? <div className="flex flex-col p-2 absolute bg-white w-100 top-[37px] z-10 border border-black left-0 right-0">
                    <div className="flex flex-col overflow-y-scroll max-h-[66vh]">
                        {customerMatches.map(c => {
                            return <div className="border-b border-b-brand-grey p-2 hover:bg-brand-grey hover:text-white cursor-pointer text-sm" onClick={() => { navigate(`/orders?search=customer:${c.id}`) }}>
                                <div>{c.firstName} {c.surname}</div>
                                {c.phoneNumber ? <div>{c.phoneNumber}</div> : null }
                                {c.email ? <div>{c.email}</div> : null }
                            </div>
                        })}
                    </div>
                </div> : null }
            </div>
            <div className="text-lg text-bold font-semibold uppercase text-left mt-4 mb-2 min-w-[275px]">Order Lookup</div>
            <div className="flex flex-col">
                <div className="flex flex-col text-sm">
                    <div className="flex flex-row relative">
                        <input type="text"
                            value={orderText} 
                            className="mb-1 pl-9 p-2 text-sm flex-1 border border-black" 
                            onChange={(e) => { setOrderText(e.target.value) }}
                            onKeyUp={detectEnter}
                            placeholder="Search ID..."/>
                        <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute text-lg ml-[9px] mt-[9px]"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerLookupWidget;