import { useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const TillConfirmCompletionModal = ({ onSuccess, onClose }) => {
    return <Modal onClose={onClose} heading={'Mark this order as completed?'} className="text-black text-left flex flex-col" windowClassName="w-[500px]">
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={() => onClose()}>No</div>
            <div className="flex btn" onClick={() => onSuccess()}>Yes</div>
        </div>
    </Modal>;
}

export default TillConfirmCompletionModal;