import ReactDatePicker from "react-datepicker";
import APIFetch from "../../utilities/APIFetch";
import { useState } from "react";
import { useEffect } from "react";
import MessageModal from "../../components/MessageModal";
import OverlaySpinner from "../../components/OverlaySpinner";
import ItemCard from "../../components/ItemCard";
import Sidebar from "../../components/Sidebar/Sidebar";
import { format } from "date-fns";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { 
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    Colors,
    LinearScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    ChartDataLabels,
    Colors,
    LinearScale,
    BarElement
  );

const options = {
    responsive: true,
    plugins: {
        datalabels: {
            color: '#000000',
            anchor: 'end',
            align: 'end',
            font: {
                weight: 'bold',
                size: 16,
            }
        }
    },
    layout: {
        padding: {
            top: 30,
        }
    },
    scale: {
        ticks: {
        precision: 0
        }
    }
};  

function ChartTrend() {
    const [fromDate, setFromDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [toDate, setToDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sourceData, setSourceData] = useState(null);
    const [message, setMessage] = useState(null);
    const [graphData, setGraphData] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = (clear = false, filterOverride = null) => {
        setLoading(true);

        var query = {}

        if(fromDate) {
            query.fromDate = fromDate.toISOString();
        }

        if(toDate) {
            query.toDate = toDate.toISOString();
        }

        if(filterOverride) {
            query = {...query, ...filterOverride};
        }

        if(query.fromDate === null) delete query.fromDate
        if(query.toDate === null) delete query.toDate

        APIFetch("POST", "sourcereport", query)
        .then(result => {
            if(result.ok) {
                setSourceData(result.data);

                // Build graph data object
                const labels = [];
                const datasets = [
                    {
                        label: "Source Data",
                        data: []
                    }
                ];

                result.data.forEach(d => {
                    labels.push(d.source);
                    datasets[0].data.push(d.quantity);
                });

                const colors = [];

                for(let i = 0; i < datasets[0].data.length; i++){
                    colors.push('#'+Math.floor(Math.random()*16777215).toString(16));
                }

                datasets[0].backgroundColor = colors;

                setGraphData({ labels, datasets });
            } else {
                setSourceData([]);
                setMessage("An error occurred loading customer source data.");
            }
            setLoading(false);
        })
        .catch(() => {
            setSourceData([]);
            setLoading(false);
            setMessage("An error occurred loading customer source data.");
        })
    };

    const clearFilters = () => {
        setFromDate(null);
        setToDate(null);
        getData(true, { fromDate: null, toDate: null });
    }

    const exportData = () => {
        let csvContent = "data:text/csv;charset=utf-8,Source,Responses, Total Spend\n" + sourceData.map(b => [b.source, b.quantity, b.totalSpend.toFixed(2)].join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${format(Date.now(), "ddMMyyyy")}-CustomerSourceReport.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return <div className="App">
        <div className="flex flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="max-h-full min-h-0 flex flex-1">
                    <MessageModal message={message} onClose={() => setMessage(null)} />
                    <div className="flex flex-col max-h-full flex-1">
                        {loading ? <OverlaySpinner /> : null }
                        <div className="flex flex-row items-end ml-2 mt-9 mb-2 flex-wrap text-left">
                            <h1 className="text-left font-medium text-lg mr-8 self-center">Customer Source Report</h1>
                            <div className="flex flex-col mb-3 mr-6">
                                <div className="text-sm text-brand-grey">From</div>
                                <ReactDatePicker onChange={(e) => { setFromDate(e); getData(true, { fromDate: e ? e.toISOString() : null }) }} selected={fromDate} dateFormat="P" placeholderText="dd/mm/yyyy" />
                            </div>
                            <div className="flex flex-col mb-3 mr-8">
                                <div className="text-sm text-brand-grey">To</div>
                                <ReactDatePicker onChange={(e) => { setToDate(e); getData(true, { toDate: e ? e.toISOString() : null }) }} selected={toDate} dateFormat="P" placeholderText="dd/mm/yyyy"/>
                            </div>
                            <div className="cursor-pointer text-sm border-b border-b-brand-grey mt-1 mb-3 text-brand-grey" onClick={clearFilters}>Clear All</div>
                            <div className="cursor-pointer text-sm border-b border-b-brand-grey ml-6 mt-1 mb-3 text-brand-grey" onClick={exportData}>Export</div>
                        </div>
                        <div className="flex flex-col mt-8 text-brand-grey min-h-0 mb-4 flex-1">
                            <div className="flex-shrink-1 overflow-y-auto mb-4">
                                <div className="flex flex-row px-3 font-bold mb-2 text-sm mt-0 text-left">
                                    <div className="flex flex-1 basis-8/12">Source</div>
                                    <div className="flex flex-1 basis-2/12">Responses</div>
                                    <div className="flex flex-1 basis-2/12">Total Spend</div>
                                </div>
                                <div className="flex flex-col">
                                    { sourceData && sourceData.length === 0 ? <div>No data to display.</div> : null }
                                    {sourceData ? sourceData.map(b => {
                                        return <ItemCard 
                                        key={b.id} 
                                        item={b}
                                        additionalFields={[
                                            { value: b.source, className: "basis-8/12"},
                                            { value: b.quantity, className: "basis-2/12"},
                                            { value: '£' + b.totalSpend.toFixed(2), className: "basis-2/12"}
                                        ]}
                                        slim={true}/>;
                                    }) : null}
                                </div>
                            </div>
                            <div className="flex flex-1 min-h-[50%] justify-center">
                                {  graphData ? <Bar options={options} data={graphData} /> : null }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ChartTrend;