import appConfig from "../appConfig.json";
import { AuthStore } from "../stores/AuthStore";
import History from "./History";

var awaitingToken = false; // Queue all requests while awaiting a new token
var pendingRequests = []; // Request awaiting a new token
var failureCount = 0; // Catch possibility of 401 loop

const APIFetch = (method, url, requestBody, multipartBody) => {
    var token = AuthStore.currentState.accessToken;
    var refreshToken = AuthStore.currentState.refreshToken;

    if(!token && !refreshToken) {
        SignOut();
    }

    if(!token && !awaitingToken) {
        auth();
    }

    if(awaitingToken) {
        return new Promise((resolve, reject) => {
            pendingRequests.push({ 
                request: () => { 
                    return fetchBody(method, url, requestBody, multipartBody)
                    .then(result => resolve(result))
                    .catch(error => reject(error));
                },
                reject
            });
        });
    }

    return fetchBody(method, url, requestBody, multipartBody);
}

const fetchBody = (method, url, requestBody, multipartBody) => {
    return new Promise((resolve, reject) => {
        var token = AuthStore.currentState.accessToken;

        var headers = {
            'Authorization': 'Bearer ' + token
        };

        if(requestBody) {
            headers['Content-Type'] = 'application/json';
        }

        return fetch(appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_DOMAIN + url, {
            method: method,
            mode: 'cors',
            
            headers: headers,
            body: multipartBody ? multipartBody : (requestBody ? JSON.stringify(requestBody) : null)
        })
        .then((response) => {
            if(response.ok) {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json()
                    .then(result => {
                        resolve({ ok: response.ok, status: response.status, data: result });
                        return;
                    })
                    .catch(e => {
                        reject(e);
                        return;
                    });
                } else if (contentType && contentType.indexOf("text/plain") !== -1) {
                    return response.text()
                    .then(result => {
                        resolve({ ok: response.ok, status: response.status, data: result });
                        return;
                    })
                    .catch(e => {
                        reject(e);
                        return;
                    });
                } else {
                    resolve({ ok: response.ok, status: response.status, data: null });
                    return;
                }
            } else {
                if(response.status === 401) {
                    pendingRequests.push({
                        request: () => { 
                            return fetchBody(method, url, requestBody, multipartBody)
                            .then(result => resolve(result))// Reset failure count after completing a queue item
                            .catch(error => reject(error));
                        },
                        reject
                    });

                    if(!awaitingToken) {
                        failureCount++;

                        if(failureCount > 1) {
                            console.log('fetchBody failed to refresh token for ' + url);
                            reject({ ok: response.ok, status: response.status, data: null });
                            return;
                        } else {
                            console.log('fetchBody attempting to refresh token for ' + url + ": " + response.status);
                            auth();
                            return;
                        }
                    }

                    return;
                } else if(response.status === 403) {
                    AuthStore.update(s => { s.accessToken = null });
                    AuthStore.update(s => { s.refreshToken = null });
                    AuthStore.update(s => { s.name = null });
                    AuthStore.update(s => { s.userId = null });
                    sessionStorage.removeItem('refreshToken');
                    History.navigate('/');
                    return;
                } else if(response.status === 400 || response.status === 409) {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        return response.json()
                        .then(result => {
                            resolve({ ok: response.ok, status: response.status, data: result });
                            return;
                        })
                        .catch(e => {
                            reject(e);
                            return;
                        });
                    } else if (contentType && contentType.indexOf("text/plain") !== -1) {
                        return response.text()
                        .then(result => {
                            resolve({ ok: response.ok, status: response.status, data: result });
                            return;
                        })
                        .catch(e => {
                            reject(e);
                            return;
                        });
                    } else {
                        resolve({ ok: response.ok, status: response.status, data: null });
                        return;
                    }
                } else {
                    resolve({ ok: response.ok, status: response.status, data: response });
                    return;
                }
            }
        })
        .catch((e) => {
            console.log(e);
            reject(e);
        });
    });
}

export const uploadFiles = (method, url, formData, progressListener) => {
    return new Promise((resolve, reject) => {
        var token = AuthStore.currentState.accessToken;
        var refreshToken = AuthStore.currentState.refreshToken;

        if(!token && !refreshToken) {
            SignOut();
            return Promise.reject();
        }

        if(!token && !awaitingToken) {
            auth();
        }

        if(awaitingToken) {
            pendingRequests.push({ 
                request: () => { 
                    return uploadFiles(method, url, formData, progressListener)
                    .then(result => resolve(result))
                    .catch(error => reject(error));
                },
                reject
            });
            return;
        }
    
        
        var xhr = new XMLHttpRequest();
        xhr.open(method, appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_DOMAIN + url, true);
        //xhr.setRequestHeader("Content-Type", "multipart/form-data");
        xhr.setRequestHeader("Authorization", 'Bearer ' + token);
    
        xhr.addEventListener("readystatechange", function (e) {
            // upload completed
            if (xhr.readyState === 4) {
                if(xhr.status === 401) {
                    pendingRequests.push({ 
                        request: () => { 
                            return uploadFiles(method, url, formData, progressListener)
                            .then(result => resolve(result))
                            .catch(error => reject(error));
                        },
                        reject
                    });

                    if(!awaitingToken) {
                        failureCount++;

                        if(failureCount > 1) {
                            console.log('uploadFiles failed to refresh token for ' + url);
                            reject({ ok: xhr.status === 200, status: xhr.status, data: null });
                            return;
                        } else {
                            console.log('uploadFiles attempting to refresh token for ' + url + ": " + xhr.status);
                            auth();
                            return;
                        }
                    }

                    return;
                } else if(xhr.status === 403) {
                    AuthStore.update(s => { s.accessToken = null });
                    AuthStore.update(s => { s.refreshToken = null });
                    AuthStore.update(s => { s.name = null });
                    AuthStore.update(s => { s.userId = null });
                    sessionStorage.removeItem('refreshToken');
                    History.navigate('/');
                    reject();
                    return;
                } else if(xhr.status === 200) {
                    resolve({ ok: xhr.status === 200, status: xhr.status, data: JSON.parse(xhr.responseText) });
                } else {
                    resolve({ ok: false, status: xhr.status, data: null });
                }
            }
        });
    
        xhr.addEventListener("error", function (e) {
            reject({ ok: false, status: xhr.status, data: "An error occurred during upload." });
        });
        
        xhr.addEventListener("abort", function (e) {
            reject({ ok: false, status: xhr.status, data: "An error caused the upload to be aborted." });
        });
    
        xhr.send(formData);
        if(progressListener) xhr.upload.addEventListener("progress", progressListener);
    });
}

const auth = () => {
    awaitingToken = true;

    return new Promise(async (resolve, reject) => {
        var onReject = async () => {
            pendingRequests.forEach(r => {
                r.reject();
            });
            pendingRequests = [];
            failureCount = 0;
            SignOut();
            reject();
        }

        fetch(appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV].API_DOMAIN + `auth/refresh`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                refreshToken: AuthStore.currentState.refreshToken
            })
        })
        .then((response) => {
            if(response.ok) {
                return response.json()
                .then(async result => {
                    AuthStore.update(s => { s.accessToken = result.token });
                    AuthStore.update(s => { s.refreshToken = result.refreshToken });
                    AuthStore.update(s => { s.name = result.name });
                    AuthStore.update(s => { s.userId = result.id });
                    sessionStorage.setItem('refreshToken', result.refreshToken);
                    awaitingToken = false;

                    while(pendingRequests.length > 0) {
                        await pendingRequests[0].request()
                        .then(() => {
                            failureCount = 0;
                            pendingRequests.shift();
                        })
                        .catch(async (e) => {
                            if(e.status === 401) {
                                pendingRequests.forEach(r => {
                                    r.reject();
                                });
                                pendingRequests = [];
                                failureCount = 0;
                                SignOut();
                                reject();
                            }
                        });
                    }

                    resolve();
                })
                .catch((e) => {
                    console.log('auth got error parsing JSON: ' + e);
                    onReject();
                });
            } else {
                onReject();
            }
        })
        .catch((e) => {
            console.log('auth got error on sign-in attempt: ' + e);
            onReject();
        });
    });
}

const SignOut = () => {
    AuthStore.update(s => { s.accessToken = null });
    AuthStore.update(s => { s.refreshToken = null });
    AuthStore.update(s => { s.name = null });
    AuthStore.update(s => { s.userId = null });
    sessionStorage.removeItem('refreshToken');
    if(History && History.navigate) {
        History.navigate('/');
    } else {
        if(window.location.pathname !== '/') { window.location = "/"; };
    }
}

export default APIFetch;