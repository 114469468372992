import { forwardRef } from "react";

function PostLabel({ addressLines, multiPrint = false }, ref) {
    return <div ref={ref} className={multiPrint ? "onlyPrint w-[100vw] h-[100vh] m-0 p-3 py-4 inline-block" : "onlyPrint absolute top-0 left-0 w-full max-w-full m-0 p-3 py-4"}>
        <div className="flex flex-col">
            {addressLines.map((l, i) => {
                if(l == null || l.length === 0) return null;
                return <div className={`leading-6 text-black text-2xl ${i === 0 ? "font-bold" : ""}`}>{l}</div>
            })}
        </div>
    </div>;
}

export default forwardRef(PostLabel);