import { useEffect, useState } from "react";
import MessageModal from "../../components/MessageModal";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useParams } from "react-router-dom";
import APIFetch from "../../utilities/APIFetch";
import ReactDatePicker from "react-datepicker";

const TillReport = () => {
    let { tillId } = useParams();
    const [fromDate, setFromDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [toDate, setToDate] = useState(null);
    const [typeText, setTypeText] = useState("");
    const [message, setMessage] = useState(null);
    const [reportData, setReportData] = useState(null);

    useEffect(() => {
        if(tillId) {
            getData();
        }
    }, []);

    const clearFilters = () => {
        setFromDate(null);
        setToDate(null);
        getData(true, { fromDate: null, toDate: null });
    }

    const getData = (filterOverride = null) => {
        var queryBase = {};

        if(fromDate) queryBase.fromDate = fromDate;
        if(toDate) queryBase.toDate = toDate;

        APIFetch('POST', `tillreport/${tillId}`, { ...queryBase, ...filterOverride })
        .then(result => {
            if(result.ok) {
                setReportData(result.data);
            } else {
                setMessage("An error occurred when loading till data.");
            }
        })
        .catch(() => {
            setMessage("An error occurred when loading till data.");
        });
    }

    return <div className="app">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="relative content flex flex-col p-8 flex-grow h-[100vh]">
                <div className="flex flex-row items-center ml-2 mt-9 mb-0 flex-wrap">
                    <h1 className="ext-left font-medium text-lg mr-8 self-center">{reportData ? reportData.tillName : 'Till #' + tillId} Report</h1>
                    <div className="flex flex-col mb-3 mr-6">
                        <div className="text-sm text-brand-grey">From</div>
                        <ReactDatePicker onChange={(e) => { setFromDate(e); getData({ fromDate: e ? e.toISOString() : null }) }} selected={fromDate} dateFormat="P" placeholderText="dd/mm/yyyy" />
                    </div>
                    <div className="flex flex-col mb-3 mr-8">
                        <div className="text-sm text-brand-grey">To</div>
                        <ReactDatePicker onChange={(e) => { setToDate(e); getData({ toDate: e ? e.toISOString() : null }) }} selected={toDate} dateFormat="P" placeholderText="dd/mm/yyyy"/>
                    </div>
                    <select className={"mr-6 mb-3 text-sm text-brand-grey"} value={typeText}  onChange={(e) => { setTypeText(e.target.value); getData({ type: e.target.value }); }}>
                        <option value={""}>Any Type</option>
                        <option value={"EPOS"}>EPOS</option>
                        <option value={"Web"}>Web</option>
                    </select>
                    <div className="cursor-pointer text-sm border-b border-b-brand-grey mt-1 mb-3 text-brand-grey" onClick={clearFilters}>Clear All</div>
                </div>
                { reportData ? <div className="flex flex-col flex-grow overflow-y-auto">
                    {/* SALES */}
                    <div className="flex flex-row bg-brand-grey-medium font-bold mt-4 mb-0 print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-5/12 p-2">Sales</div>
                        <div className="basis-5/12 p-2">Qty</div>
                        <div className="basis-2/12 p-2">Value</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="basis-5/12 p-2">Items Sold</div>
                        <div className="basis-5/12 p-2">{reportData.itemsCount}</div>
                        <div className="basis-2/12 p-2">£{reportData.itemsValue.toFixed(2)}</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="basis-5/12 p-2">Total Sales</div>
                        <div className="basis-5/12 p-2">{reportData.ordersCount}</div>
                        <div className="basis-2/12 p-2">£{reportData.ordersValue.toFixed(2)}</div>
                    </div>
                    {/* DISCOUNTS */}
                    <div className="flex flex-row bg-brand-grey-medium font-bold mt-4 mb-0 print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-5/12 p-2">Discounts</div>
                        <div className="basis-5/12 p-2">Qty</div>
                        <div className="basis-2/12 p-2">Value</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="basis-5/12 p-2">Item Discounts</div>
                        <div className="basis-5/12 p-2">{reportData.itemDiscountCount}</div>
                        <div className="basis-2/12 p-2">£{reportData.itemDiscountValue.toFixed(2)}</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="basis-5/12 p-2">Order Discounts</div>
                        <div className="basis-5/12 p-2">{reportData.orderDiscountCount}</div>
                        <div className="basis-2/12 p-2">£{reportData.orderDiscountValue.toFixed(2)}</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="basis-5/12 p-2">Total Discounts</div>
                        <div className="basis-5/12 p-2">{reportData.orderDiscountCount + reportData.itemDiscountCount}</div>
                        <div className="basis-2/12 p-2">£{(reportData.itemDiscountValue + reportData.orderDiscountValue).toFixed(2)}</div>
                    </div>
                    {/* DEPOSIT ORDERS */}
                    <div className="flex flex-row bg-brand-grey-medium font-bold mt-4 mb-0 print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-5/12 p-2">Deposit Orders</div>
                        <div className="basis-5/12 p-2">Qty</div>
                        <div className="basis-2/12 p-2">Value</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="basis-5/12 p-2">Total Deposit Orders</div>
                        <div className="basis-5/12 p-2">{reportData.depositsPaid}</div>
                        <div className="basis-2/12 p-2">£{reportData.depositsPaidValue.toFixed(2)}</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="basis-5/12 p-2">Deposit Payments Taken</div>
                        <div className="basis-5/12 p-2"></div>
                        <div className="basis-2/12 p-2">£{reportData.depositTransactionValue.toFixed(2)}</div>
                    </div>
                    {/* TENDER */}
                    <div className="flex flex-row bg-brand-grey-medium font-bold mt-4 mb-0 print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-5/12 p-2">Tender</div>
                        <div className="basis-5/12 p-2">Count</div>
                        <div className="basis-2/12 p-2">Value</div>
                    </div>
                    {reportData.transactionsByType ? reportData.transactionsByType.map(tender => {
                        return <div className="flex flex-row">
                            <div className="basis-5/12 p-2">{tender.type}</div>
                            <div className="basis-5/12 p-2">{tender.count}</div>
                            <div className="basis-2/12 p-2">£{(tender.total).toFixed(2)}</div>
                        </div>;
                    }) : null }
                    <div className="flex flex-row">
                        <div className="basis-5/12 p-2">Total Tender</div>
                        <div className="basis-5/12 p-2">{reportData.transactionCount}</div>
                        <div className="basis-2/12 p-2">£{reportData.transactionValue.toFixed(2)}</div>
                    </div>
                    {/* RETURN DATA */}
                    <div className="flex flex-row bg-brand-grey-medium font-bold mt-4 mb-0 print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-5/12 p-2">Returns</div>
                        <div className="basis-5/12 p-2">Qty</div>
                        <div className="basis-2/12 p-2">Value</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="basis-5/12 p-2">Returned Items</div>
                        <div className="basis-5/12 p-2">{reportData.returnsCount}</div>
                        <div className="basis-2/12 p-2">£{reportData.returnsValue}</div>
                    </div>
                    {reportData.returnsByType ? reportData.returnsByType.map(r => {
                        return <div className="flex flex-row">
                            <div className="basis-5/12 p-2">{r.type} Returns</div>
                            <div className="basis-5/12 p-2">{r.count}</div>
                            <div className="basis-2/12 p-2">£{r.total}</div>
                        </div>;
                    }) : null }
                    <div className="flex flex-row">
                        <div className="basis-5/12 p-2">Total Tender Minus Returns</div>
                        <div className="basis-5/12 p-2"></div>
                        <div className="basis-2/12 p-2">£{(reportData.transactionValue - reportData.returnsValue).toFixed(2)}</div>
                    </div>
                    {/* POST & ALTERATIONS */}
                    <div className="flex flex-row bg-brand-grey-medium font-bold mt-4 mb-0 print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-5/12 p-2">Post & Alterations</div>
                        <div className="basis-5/12 p-2">Qty</div>
                        <div className="basis-2/12 p-2">Total</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="basis-5/12 p-2">Alterations</div>
                        <div className="basis-5/12 p-2">{reportData.alterationsCount}</div>
                        <div className="basis-2/12 p-2">£{reportData.alterationsValue}</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="basis-5/12 p-2">Post</div>
                        <div className="basis-5/12 p-2">{reportData.postCount}</div>
                        <div className="basis-2/12 p-2">£{reportData.postValue}</div>
                    </div>
                    {/* TILL ADJUSTMENTS */}
                    <div className="flex flex-row bg-brand-grey-medium font-bold mt-5 print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-full p-2">Till Adjustments</div>
                    </div>
                    <div className="flex flex-row bg-brand-grey-medium font-bold print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-10/12 p-2">Opening</div>
                        <div className="basis-2/12 p-2">£{reportData.tillOpeningBalance.toFixed(2)}</div>
                    </div>
                    <div className="flex flex-row bg-brand-grey-medium font-bold print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="p-2">Adjustments</div>
                    </div>
                    {reportData.adjustments ? reportData.adjustments.map(a => {
                        return <div className="flex flex-row">
                            <div className="basis-5/12 p-2">{a.userName ? a.userName : "Unknown"}</div>
                            <div className="basis-5/12 p-2">{a.comment}</div>
                            <div className="basis-2/12 p-2">£{a.value.toFixed(2)}</div>
                        </div>;
                    }) : null }
                    <div className="flex flex-row bg-brand-grey-medium font-bold print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-8/12 p-2"></div>
                        <div className="basis-2/12 p-2">Total</div>
                        <div className="basis-2/12 p-2">£{reportData.adjustmentTotal.toFixed(2)}</div>
                    </div>
                    <div className="flex flex-row bg-brand-grey-medium font-bold print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="p-2">Banked</div>
                    </div>
                    {reportData.banked ? reportData.banked.map(a => {
                        return <div className="flex flex-row">
                            <div className="basis-5/12 p-2">{a.userName ? a.userName : "Unknown"}</div>
                            <div className="basis-5/12 p-2">{a.comment}</div>
                            <div className="basis-2/12 p-2">£{a.value}</div>
                        </div>;
                    }) : null }
                    <div className="flex flex-row bg-brand-grey-medium font-bold print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-8/12 p-2"></div>
                        <div className="basis-2/12 p-2">Total</div>
                        <div className="basis-2/12 p-2">£{reportData.bankedTotal.toFixed(2)}</div>
                    </div>
                    <div className="flex flex-row bg-brand-grey-medium font-bold mb-5 print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-10/12 p-2">Closing</div>
                        <div className="basis-2/12 p-2">£{reportData.tillClosingBalance.toFixed(2)}</div>
                    </div>
                    {/* OPERATOR STATS */}
                    <div className="flex flex-row bg-brand-grey-medium font-bold mt-4 mb-0 print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-5/12 p-2">Operator</div>
                        <div className="basis-5/12 p-2">Orders / Items</div>
                        <div className="basis-2/12 p-2">Value</div>
                    </div>
                    {reportData.users ? reportData.users.map(u => {
                        return <div className="flex flex-row">
                            <div className="basis-5/12 p-2">{u.name}</div>
                            <div className="basis-5/12 p-2">{u.orders} / {u.items}</div>
                            <div className="basis-2/12 p-2">£{u.value.toFixed(2)}</div>
                        </div>;
                    }) : null }
                    {/* RETURN ITEMS */}
                    <div className="flex flex-row bg-brand-grey-medium font-bold mt-4 mb-0 print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-5/12 p-2">Return List</div>
                    </div>
                    <div className="flex flex-row bg-brand-grey-medium font-bold mt-0 mb-0 print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="p-2">Description</div>
                    </div>
                    {reportData.returns ? reportData.returns.map(r => {
                        return <div className="flex flex-col p-2 border-b border-b-brand-grey-medium">
                            <div className="font-bold">Order: {r.orderNumber}</div>
                            <div className="">{r.barcode} - {r.name}</div>
                            <div className="">{r.userName} - {new Date(r.date).toLocaleString()}</div>
                        </div>;
                    }) : null }
                    {/* TILL FLOATS */}
                     <div className="flex flex-row bg-brand-grey-medium font-bold mt-0 mb-0 print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-5/12 p-2">Till Floats</div>
                    </div>
                    <div className="flex flex-row bg-brand-grey-medium font-bold mt-0 mb-0 print:mb-2 print:text-sm print:bg-brand-grey-light print:border-b print:border-b-black">
                        <div className="basis-2/12 p-2">Date</div>
                        <div className="basis-4/12 p-2">Comment</div>
                        <div className="basis-2/12 p-2">Count</div>
                        <div className="basis-2/12 p-2">Till Closing</div>
                        <div className="basis-2/12 p-2">Discrepency</div>
                    </div>
                    {reportData.floats ? reportData.floats.map(f => {
                        return <div className="flex flex-row">
                            <div className="basis-2/12 p-2">{new Date(f.date).toLocaleDateString()}</div>
                            <div className="basis-4/12 p-2">{f.comment}</div>
                            <div className="basis-2/12 p-2">£{f.count.toFixed(2)}</div>
                            <div className="basis-2/12 p-2">£{f.closing.toFixed(2)}</div>
                            <div className="basis-2/12 p-2">£{f.discrepency.toFixed(2)}</div>
                        </div>;
                    }) : null }
                </div> : null }
            </div>
        </div>
    </div>
}

export default TillReport;