import ReactDatePicker from "react-datepicker";
import APIFetch from "../../utilities/APIFetch";
import { useState } from "react";
import { useEffect } from "react";
import MessageModal from "../../components/MessageModal";
import OverlaySpinner from "../../components/OverlaySpinner";
import ItemCard from "../../components/ItemCard";
import Sidebar from "../../components/Sidebar/Sidebar";
import { format } from "date-fns";

function BestSellers() {
    const [fromDate, setFromDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [toDate, setToDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [bestSellers, setBestSellers] = useState(null);
    const [message, setMessage] = useState(null);
    const [showVariants, setShowVariants] = useState(false);
    const [columnClasses, setColumnClasses] = useState(["hidden", "hidden", "hidden", "hidden", "hidden", "hidden"])

    useEffect(() => {
        if(!showVariants) {
            setColumnClasses(["mr-2 basis-8/12", "mr-2 basis-4/12", "hidden", "mr-2 basis-[120px]", "mr-2 basis-[120px]", "mr-2 basis-[120px]"]);
        } else {
            setColumnClasses(["mr-2 basis-6/12", "mr-2 basis-3/12", "mr-2 basis-3/12", "mr-2 basis-[120px]", "mr-2 basis-[120px]", "mr-2 basis-[120px]"]);
        }

        setBestSellers(null);
        getData();
    }, [showVariants])

    const getData = (clear = false, filterOverride = null) => {
        setLoading(true);

        var query = {}

        if(fromDate) {
            query.fromDate = fromDate.toISOString();
        }

        if(toDate) {
            query.toDate = toDate.toISOString();
        }

        if(filterOverride) {
            query = {...query, ...filterOverride};
        }

        query.showVariants = showVariants;

        if(query.fromDate === null) delete query.fromDate
        if(query.toDate === null) delete query.toDate

        APIFetch("POST", "bestsellers", query)
        .then(result => {
            if(result.ok) {
                setBestSellers(result.data);
            } else {
                setBestSellers([]);
                setMessage("An error occurred loading best sellers.");
            }
            setLoading(false);
        })
        .catch(() => {
            setBestSellers([]);
            setLoading(false);
            setMessage("An error occurred loading best sellers.");
        })
    };

    const clearFilters = () => {
        setFromDate(null);
        setToDate(null);
        getData(true, { fromDate: null, toDate: null });
    }

    const exportData = () => {
        let csvContent = "data:text/csv;charset=utf-8,Name,Product Code, Total Sold\n" + bestSellers.map(b => [b.description, b.productCode, b.quantity].join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${format(Date.now(), "ddMMyyyy")}-BestSellers.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return <div className="App">
        <div className="flex flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="max-h-full min-h-0">
                    <MessageModal message={message} onClose={() => setMessage(null)} />
                    <div className="flex flex-col max-h-full">
                        {loading ? <OverlaySpinner /> : null }
                        <div className="flex flex-row items-end ml-2 mt-9 mb-4 flex-wrap text-left">
                            <h1 className="text-left font-medium text-lg mr-8 self-center">Best Sellers</h1>
                            <div>
                            </div>
                            <div className="flex flex-col mb-3 mr-6">
                                <div className="text-sm text-brand-grey">From</div>
                                <ReactDatePicker onChange={(e) => { setFromDate(e); getData(true, { fromDate: e ? e.toISOString() : null }) }} selected={fromDate} dateFormat="P" placeholderText="dd/mm/yyyy" />
                            </div>
                            <div className="flex flex-col mb-3 mr-8">
                                <div className="text-sm text-brand-grey">To</div>
                                <ReactDatePicker onChange={(e) => { setToDate(e); getData(true, { toDate: e ? e.toISOString() : null }) }} selected={toDate} dateFormat="P" placeholderText="dd/mm/yyyy"/>
                            </div>
                            <div className="cursor-pointer text-sm border-b border-b-brand-grey mt-1 mb-3 text-brand-grey" onClick={clearFilters}>Clear All</div>
                            <div className="cursor-pointer text-sm border-b border-b-brand-grey ml-6 mt-1 mb-3 text-brand-grey" onClick={exportData}>Export</div>
                        </div>
                        <div className="flex btn px-2 mr-auto" onClick={() => { setShowVariants(!showVariants) }}>
                            {showVariants ? "Show Products" : "Show Variants"}
                        </div>
                        <div className="flex flex-col mt-4 text-brand-grey min-h-0">
                            <div className="min-w-[1000px] md:min-w-[0px] flex flex-row px-3 font-bold mb-2 text-sm mt-0 text-left">
                                <div className={`flex flex-1 ${columnClasses[0]}`}>Name</div>
                                <div className={`flex flex-1 ${columnClasses[1]}`}>Product Code</div>
                                { showVariants ? <div className={`flex flex-1  ${columnClasses[2]}`}>Options</div> : null }
                                <div className={`flex flex-1 ${columnClasses[3]}`}>Total Sold</div>
                                <div className={`flex flex-1 ${columnClasses[4]}`}>Total EPOS</div>
                                <div className={`flex flex-1 ${columnClasses[5]}`}>Total Web</div>
                            </div>
                            <div className="min-w-[1000px] md:min-w-[0px] flex flex-col flex-grow overflow-y-auto">
                                { bestSellers && bestSellers.length === 0 ? <div>No best sellers to display.</div> : null }
                                {bestSellers ? bestSellers.map(b => <ItemCard 
                                    key={b.id} 
                                    item={b}
                                    additionalFields={[
                                        { value: b.description, className: columnClasses[0]},
                                        { value: b.productCode, className: columnClasses[1]},
                                        { value: <div className={"flex flex-col"}>
                                            {b.variations ? b.variations.map((v, i) => {
                                                return <div key={"options" + i}>
                                                    {v.name}: {v.value}
                                                </div>
                                            }) : null}
                                        </div>, className: columnClasses[2]},
                                        { value: b.quantity, className: columnClasses[3]},
                                        { value: b.eposQuantity, className: columnClasses[4]},
                                        { value: b.webQuantity, className: columnClasses[5]},
                                    ]}/>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default BestSellers;