import { useCallback, useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { SpinnerCircular } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import ItemCreationModal from "../../components/ItemCreationModal";
import Modal from "../../components/Modal";
import ItemCard from "../../components/ItemCard";
import { useNavigate } from "react-router-dom";
import DataCache from "../../utilities/DataCache";

function ProductManagement({ isModal, onSelected }) {
    const [products, setProducts] = useState(null);
    const [complete, setComplete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [creation, setCreation] = useState(false);
    const [pendingDelete, setPendingDelete] = useState(false);
    const [locations, setLocations] = useState([...DataCache.location].sort((a, b) => a.name.localeCompare(b.name)));
    const navigate = useNavigate();
    const loader = useRef(null);

    const [categories, setCategories] = useState([...DataCache.category].sort((a, b) => a.name.localeCompare(b.name)));
    const [manufacturers, setManufacturers] = useState([...DataCache.manufacturer].sort((a, b) => a.name.localeCompare(b.name)));

    const [searchText, setSearchText] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("");
    const [manufacturerFilter, setManufacturerFilter] = useState("");

    const clearFilters = () => {
        if(searchText === "" && categoryFilter === "" && manufacturerFilter === "") return;
        setSearchText("");
        setCategoryFilter("");
        setManufacturerFilter("");
        getData(true, { TextSearch: "", categoryId: null, manufacturerId: null });
    }

    const getData = (clear = false, filterOverride = null) => {
        setLoading(true);

        var query = {
            count: 50
        }

        if(!clear && products && products.length > 0) {
            query.lastDate = products[products.length - 1].createdAt;
        }

        if(searchText.length > 0) {
            query.TextSearch = searchText;
        }

        if(categoryFilter) {
            query.categoryId = categoryFilter;
        }

        if(manufacturerFilter) {
            query.manufacturerId = manufacturerFilter;
        }

        if(filterOverride) {
            query = {...query, ...filterOverride};
        }

        APIFetch("POST", "product", query)
        .then(result => {
            if(result.ok) {
                if(clear || !products) {
                    setProducts(result.data);
                } else {
                    setProducts(products.concat(result.data));
                }

                if(result.data.length < query.count) {
                    setComplete(true);
                } else {
                    setComplete(false);
                }
            } else {
                setProducts([]);
                setComplete(true);
            }
            setLoading(false);
        })
        .catch(() => {
            setProducts([]);
            setComplete(true);
            setLoading(false);
        })
    }

    useEffect(() => {
        getData(true);
    }, []);

    const detectEnter = ({key}) => {
        if(key === "Enter") {
            getData(true);
        }
    }

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (!loading  && !complete && target.isIntersecting) {
          getData();
        }
      }, [complete, loading, getData]);

    useEffect(() => {
        const option = {
          root: null,
          rootMargin: "20px",
          threshold: 0
        };

        const observer = new IntersectionObserver(handleObserver, option);
        const current = loader.current;
  
        if (current) observer.observe(current);
    
        return () => {
          if (current) observer.unobserve(current);
        }
      }, [complete, loading, handleObserver]);

    const onDelete = (product) => {
        if(!pendingDelete || pendingDelete !== product) {
            setPendingDelete(product);
        } else {
            APIFetch("DELETE", `product/${product.id}`)
            .then(result => {
                if(result.ok) {
                    var newProducts = [...products];
                    newProducts = newProducts.filter(l => l.id !== product.id);
                    setProducts(newProducts);
                    setPendingDelete(null);
                    setMessage("Product deleted successfully.");
                } else {
                    setMessage("An error occurred when attempting to delete the product.");
                }
            })
            .catch(() => {
                setMessage("An error occurred when attempting to delete the product.");
            })
        }
    }

    const createProduct = (product) => {
        var newProducts = [product, ...products];
        setProducts(newProducts);
        setCreation(false);
        clearFilters();
    }

    const onClick = (product) => {
        navigate(`/product/${product.id}`, { state: { product } })
    }

    const updateCategoryFilter = (e) => {
        setCategoryFilter(e.target.value);
        getData(true, { categoryId: e.target.value || null });
    }

    const updateManufacturerFilter = (e) => {
        setManufacturerFilter(e.target.value);
        getData(true, { manufacturerId: e.target.value || null });
    }

    if(isModal && !products && !complete) {
        return <SpinnerCircular size={50} color="#24272b" secondaryColor="white" />
    }

    return (
        <div className="App" style={isModal ? { maxHeight: "95vh" } : null}>
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { creation ? <ItemCreationModal onClose={() => setCreation(false)} itemType="product" heading="Create New Product" onCreate={createProduct} additionalFields={[{ name: "Category", type: "cache", propertyName: "categoryId" }, { name: "Manufacturer", type: "cache", propertyName: "manufacturerId" } ]}/> : null }
        { pendingDelete ? <Modal heading="Confirm Product Deletion" className="text-black" onClose={() => setPendingDelete(null)} windowClassName="w-[450px]">
            <div className="text-sm">Are you sure you wish to delete '{pendingDelete.name}'?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {onDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        <div className="flex flex-row w-full h-full overflow-y-hidden">
            {!isModal ? <Sidebar /> : null }
            <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="flex flex-row items-center ml-2 mt-9 mb-16">
                    <h1 className="text-left font-medium text-lg">Products</h1>
                    {!isModal ? <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => setCreation(true)}/> : null }
                    <input type="text"
                        value={searchText} 
                        className="ml-10 mb-1 text-sm" 
                        onChange={(e) => { setSearchText(e.target.value) }} placeholder="Search..."
                        onKeyDown={detectEnter}/>
                    <select className="ml-auto text-sm" value={categoryFilter} onChange={updateCategoryFilter}>
                        <option value=''>Any category</option>
                        {categories && categories.length > 0 ? categories.map(o => { return <option value={o.id}>{o.name}</option> }): null}
                    </select>
                    <select className="ml-4 text-sm" value={manufacturerFilter} onChange={updateManufacturerFilter}>
                        <option value=''>Any manufacturer</option>
                        {manufacturers && manufacturers.length > 0 ? manufacturers.map(o => { return <option value={o.id}>{o.name}</option> }): null}
                    </select>
                    <div className="cursor-pointer ml-10 text-sm border-b border-b-brand-grey mt-1 mr-4" onClick={clearFilters}>Clear All</div>
                </div>
                <div className="min-w-[1000px] md:min-w-[0px] flex flex-row px-3 font-bold mb-2 text-sm mt-2">
                    <div className="flex flex-1 basis-1/12">Image</div>
                    <div className={"flex flex-1 basis-" + (5 - locations.length) + '/12'}>Title</div>
                    <div className="flex flex-1 basis-2/12">Code</div>
                    <div className="flex flex-1 basis-2/12">Category</div>
                    <div className="flex flex-1 basis-2/12">Manufacturer</div>
                    {locations ? locations.map(l => {
                        return <div className="flex flex-1 basis-1/12">{l.name}</div>
                    }) : null}
                    {!isModal ? <div className="flex flex-1 basis-[45px]"></div> : null }
                </div>
                <div className="min-w-[1000px] md:min-w-[0px] flex flex-col flex-grow overflow-y-auto">
                    { products && products.length === 0 ? <div>No products to display.</div> : null }
                    { products ? products.map(p => <ItemCard 
                        key={p.id} item={p} hasImage={true} 
                        additionalFields={[{ value: p.productCode, className: "basis-2/12" }, { value: p.categoryName, className: "basis-2/12" }, { value: p.manufacturerName, className: "basis-2/12" }, ...locations.map(l => { 
                            var loc = p.locationStock.find(s => s.locationId === l.id);
                            return { value: loc ? loc.quantity : '0', className: "basis-1/12" } })
                        ]}
                        onDelete={isModal ? null : onDelete} onClick={isModal ? () => { if(onSelected) onSelected(p); } : onClick} nameClass={"basis-" + (5 - locations.length) + '/12'}/>) : null }
                    <div ref={loader} className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular enabled={!products || !complete} size={50} color="#24272b" secondaryColor="white" />
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default ProductManagement;