import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import MessageModal from "../../components/MessageModal";
import DataCache from "../../utilities/DataCache";
import APIFetch from "../../utilities/APIFetch";
import Modal from "../../components/Modal";
import parseRow from "../../utilities/CSVParse";

function StockAdjustment() {
    const [message, setMessage] = useState(null);
    const [locations] = useState([...DataCache.location].sort((a, b) => a.name.localeCompare(b.name)));
    const [toLoc, setToLoc] = useState("");
    const [csvData, setCSVData] = useState(null);
    const [missingStock, setMissingStock] = useState(null);

    useEffect(() => {
        var warehouse = locations.find(l => l.name === "Warehouse");
        setToLoc(warehouse ? warehouse.id.toString() : locations[0].id.toString());
    }, []);

    const fileChangeListener = (e) => {
        const file = e.target.files[0];
        let processedResults = [];
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          var results = fileReader.result.split(/\r?\n/);
          results.forEach((r, i) => {
            let { barcode, quantity } = parseRow(r);

            let existing = processedResults.find(result => result.barcode === barcode);

            if(existing) {
                existing.quantity += quantity;
            } else {
                processedResults.push({ barcode: r, quantity: quantity });
            }
          });

          setCSVData(processedResults);
        }
        fileReader.readAsText(file);
    }

    const submit = (confirmed = false) => {
        APIFetch('POST', 'stocktransfer/adjust', { toLocation: toLoc, transfers: csvData, confirmedMissingStock: confirmed })
        .then(result => {
            if(result.ok) {
                setMessage("Success.");
                setMissingStock(null);
            } else {
                if(result.status === 400) {
                    if(result.data.message && result.data.missingStock) {
                        setMissingStock(result.data.missingStock);
                    }
                } else {
                    setMessage("An error occurred during the adjustment.");
                }
            }
        })
        .catch(e => {
            setMessage("An error occurred during the adjustment.");
        })
    }

    return (
        <div className="App">
            <MessageModal message={message} onClose={() => setMessage(null)} />
            { missingStock ? <Modal heading={`WARNING: Missing products`} className="text-black" onClose={() => setMissingStock(null)}>
                <div className="mb-4">Failed to add new stock as the following barcodes do not exist:</div>
                <div className="flex flex-col items-center">
                    {Object.keys(missingStock).map(key => {
                        return <div>{key} ({missingStock[key]})</div>
                    })}
                </div>
                <div className="flex flex-row justify-center">
                    <div className="flex w-32 justify-center rounded-lg p-2 px-4 mt-8 bg-brand-active cursor-pointer text-white" onClick={() => setMissingStock(null)}>OK</div>
                </div>
            </Modal> : null }
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <Sidebar />
                <div className="content overflow-y-scroll flex flex-col p-4 flex-grow text-left">
                    <div className="flex flex-col justify-center ml-2 mb-4">
                        <h1 className="text-left p-0 m-0">Stock Adjustment</h1>
                        <h2 className="text-left p-0 m-0">You can use this tool to bulk-add stock by uploading a TXT file that contains all the SKUs to add.</h2>
                    </div>
                    <div className="flex flex-col flex-grow overflow-y-auto p-3">
                        <div className="flex flex-row items-center">
                            <div>Location:</div>
                            <select className="rounded p-1 px-2 text-black border border-brand ml-4" value={toLoc} onChange={(e) => { setToLoc(e.target.value) }}>
                                {locations && locations.length > 0 ? locations.map(o => { return <option key={o.id} value={o.id.toString()}>{o.name}</option> }): null}
                            </select>
                        </div>
                        <div className="flex flex-row mt-4 items-center">
                            <div>Stock Adjustment File:</div>
                            <input type="file" accept=".csv,.txt" className="ml-2" onChange={fileChangeListener} />
                        </div>
                        {csvData && csvData.length > 0 ? 
                        <div className={"flex mr-auto justify-center rounded-lg p-2 px-4 mt-8 cursor-pointer text-white" + (csvData ? " bg-brand-active" : " bg-brand-grey")} onClick={() => { if(csvData) { submit() } }}>Add {csvData.map(i => i.quantity).reduce((a,b) => a + b)} items</div>
                        : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StockAdjustment;