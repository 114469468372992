import { faChevronDown, faChevronUp, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import DataCache from "../utilities/DataCache";

function StockGridItem({ product, variant, toggleVariant, updateVariant, updateStock, locations }) {
    const VATRate = DataCache.vatRate;
    const [detailsOpen, setDetailsOpen] = useState(false);

    return <td className={"border border-brand-soft-borders p-4 relative max-w-[230px]"}>
        <FontAwesomeIcon className="cursor-pointer absolute right-2 top-2 text-brand-grey text-lg z-[9]" icon={faXmark} onClick={() => {toggleVariant(variant, true)}}/>
        <div className="text-sm font-medium">Barcode</div>
        <div className="mb-2 w-full text-sm font-bold">{variant.barcode || "Not yet generated"}</div>
        <div className=" flex flex-row">
            <div className="text-sm font-medium flex-1 mr-1">Price</div>
            <div className="text-sm font-medium flex-1 ml-1">Cost</div>
        </div>
        <div className=" flex flex-row mb-1">
            <div className="flex flex-row mb-2 items-center flex-1 mr-1"><div className="">£</div><input type="number" className="w-full font-bold" value={variant.priceIncVAT !== undefined ? variant.priceIncVAT : variant.price ? (variant.price * VATRate).toFixed(2) : ""} onChange={(e) => { updateVariant(variant, 'priceIncVAT', e.target.value); updateVariant(variant, 'price', (e.target.value / VATRate).toFixed(2)); }} placeholder={parseFloat((product.basePriceIncVATOverride || product.basePriceIncVAT || (product.basePrice * VATRate))).toFixed(2)} onClick={(e) => { e.stopPropagation() }}/></div>
            <div className="flex flex-row mb-2 items-center flex-1 ml-1"><div className="">£</div><input type="number" className="w-full font-bold" value={variant.cost > 0 ? variant.cost : ""} onChange={(e) => { updateVariant(variant, 'cost', e.target.value) }} placeholder={parseFloat(product.baseCostPrice).toFixed(2)} onClick={(e) => { e.stopPropagation() }}/></div>
        </div>
        <div className="text-sm font-medium">Replenishment Level</div>
        <input type="number" className="mb-2 font-bold w-full" value={variant.replenishmentMinimum} onChange={(e) => { updateVariant(variant, 'replenishmentMinimum', e.target.value) }} onClick={(e) => { e.stopPropagation() }}/>
        <div className="text-sm font-bold mb-1 mt-1">Stock</div>
        <div className="flex flex-row justify-between flex-wrap">
            {locations.map(l => {
                let locStock = variant && variant.stock ? variant.stock.find(s => s.locationId === l.id) : null;
                
                return <div className="flex flex-col max-w-[45%] flex-1">
                    <div className="text-sm font-medium">{l.name}</div>
                    <div className="text-xs font-bold">({locStock ? locStock.quantity : "0"}{locStock && locStock.adjustment ? ` -> ${Math.max(locStock.adjustment + (locStock.quantity || 0), 0)}` : ''})</div>
                    <input type="number" className="font-bold mb-2" min={"-" + (locStock ? locStock.quantity : 0)} value={locStock ? locStock.adjustment : ''} onChange={(e) => { updateStock(variant, l, e.target.value) }} onClick={(e) => { e.stopPropagation() }}/>
                </div>
            })}
        </div>
        <div className="flex flex-row justify-center items-center cursor-pointer text-bold pt-2" onClick={(e) => { e.stopPropagation(e); setDetailsOpen(!detailsOpen) }}>
            <div className="text-sm font-bold">Show {detailsOpen ? "Less" : "More"}</div>
            <FontAwesomeIcon className="ml-2" icon={detailsOpen ? faChevronUp : faChevronDown} />
        </div>
        {detailsOpen ? <div className="mt-2">
            <div className="text-sm font-medium">EAN</div>
            <input type="text" className="mb-2 font-bold w-full" value={variant.ean} onChange={(e) => { updateVariant(variant, 'ean', e.target.value) }} onClick={(e) => { e.stopPropagation() }}/>
            <div className="text-sm font-medium">ASIN</div>
            <input type="text" className="mb-2 font-bold w-full" value={variant.asin} onChange={(e) => { updateVariant(variant, 'asin', e.target.value) }} onClick={(e) => { e.stopPropagation() }}/>
        </div> : null }
    </td>;
}

export default StockGridItem;