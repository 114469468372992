import { faArrowRightFromBracket, faNavicon, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthStore } from '../../stores/AuthStore';
import SidebarItem from "./SidebarItem";
import textLogo from '../../assets/images/chatleystext.png';
import { useState } from 'react';

const ITEMS = [
  {
    title: "Dashboard",
    uri: '/dashboard'
  },
  {
    title: "Tills",
    uri: '/tills'
  },
  {
    title: "Orders",
    uri: '/orders'
  },
  {
    title: "Products",
    uri: '/products'
  },
  {
    title: "Reporting",
    uri: '/reporting',
    subItems: [
      {
        title: "Best Sellers",
        uri: '/bestsellers'
      },
      {
        title: "Chart Trends",
        uri: '/sourcereport'
      },
      {
        title: "Replenishment Report",
        uri: '/replenishment'
      }
    ]
  },
  {
    title: "Stock",
    uri: '/stock',
    subItems: [
      {
        title: "Stock List",
        uri: '/stock'
      },
      {
        title: "Stock Arrivals",
        uri: '/stockarrivals'
      },
      {
        title: "Stock Takes",
        uri: '/stocktakes'
      },
      {
        title: "Stock Transfer",
        uri: '/stocktransfer'
      },
      {
        title: "Stock Value",
        uri: '/stockvalue'
      }
    ]
  },
  {
    title: "Configuration",
    uri: '/config',
    subItems: [
      {
        title: "Account Management",
        uri: '/accounts'
      },
      {
        title: "Categories",
        uri: '/categories'
      },
      {
        title: "Customers",
        uri: '/customers'
      },
      {
        title: "Chart Trends",
        uri: '/customersources'
      },
      {
        title: "Departments",
        uri: '/departments'
      },
      {
        title: "Discounts & Sales",
        uri: '/discounts'
      },
      {
        title: "Locations",
        uri: '/locations'
      },
      {
        title: "Manufacturers",
        uri: '/manufacturers'
      },
      {
        title: "Shipping Methods",
        uri: '/shippingmethods'
      },
      {
        title: "Size Scales",
        uri: '/templates'
      },
      {
        title: "Suppliers",
        uri: '/suppliers'
      },
      {
        title: "System Settings",
        uri: '/settings'
      }
    ]
  }
]

function Sidebar() {
  const navigate = useNavigate();
  const [mobVisible, setMobVisible] = useState(false);

  const logOut = () => {
    AuthStore.update(s => { s.accessToken = null });
    AuthStore.update(s => { s.refreshToken = null });
    AuthStore.update(s => { s.name = null });
    AuthStore.update(s => { s.userId = null });
    sessionStorage.removeItem('refreshToken');
    navigate('/');
  }

  const onNavigate = () => {
    setMobVisible(!mobVisible);
  }

  return (
    <div className={"flex flex-col flex-shrink-0 absolute w-full md:relative md:w-56 md:bg-white md:p-2 border-r-brand-grey border-r md:border-b-0 md:my-8" + (mobVisible ? " bg-white p-2 border-b border-b-brand-grey z-50" : "")}>
        <FontAwesomeIcon className='fixed right-8 top-3 text-2xl md:hidden cursor-pointer z-10' icon={mobVisible ? faTimes : faNavicon} onClick={onNavigate}/>
        <div className='md:hidden fixed left-0 top-0 w-full py-4 border-b border-b-brand-grey bg-white z-[9]'>
          <img src={textLogo} width={150} className="ml-auto mr-auto"/>
        </div>
        <img src={textLogo} width={150} className="hidden md:block mt-2 md:mt-8 mb-16 ml-auto mr-auto"/>
        <div className={"flex flex-col flex-grow overflow-auto" + (mobVisible ? "" : " hidden md:block")}>
          {ITEMS.map(item => <SidebarItem key={item.title} {...item} onNavigate={onNavigate}/>)}
        </div>
        <div className={"p-4 cursor-pointer text-left text-sm" + (mobVisible ? "" : " hidden md:block")} onClick={logOut}>
          <span className='mr-2 font-bold hover:text-brand-grey-alt'>Log Out</span><FontAwesomeIcon className=' hover:text-brand-grey-alt' icon={faArrowRightFromBracket} />
        </div>
    </div>
  );
}

export default Sidebar;