import { useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const TillNoteModal = ({ orderId, onClose, onSuccess }) => {
    const [message, setMessage] = useState(null);
    const [type, setType] = useState("Note");
    const [content, setContent] = useState("");
    const [waiting, setWaiting] = useState(false);

    const onSubmit = () => {
        if(!waiting) {
            setWaiting(true);

            APIFetch('POST', `order/${orderId}/note`, { type, content })
            .then(result => {
                if(result.ok) {
                    onSuccess(result.data);
                } else {
                    setMessage("An error occurred when attempting to create the note.");
                }

                setWaiting(false);
            })
            .catch(e => {
                setMessage("An error occurred when attempting to create the note.");
                setWaiting(false);
            });
        }
    }

    return <Modal onClose={onClose} heading={'Add Note'} className="text-black text-left flex flex-col" windowClassName="w-[450px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <select className="" value={type} onChange={(e) => { setType(e.target.value) }}>
            <option key="1" value="Note">Standard Note</option>
            <option key="2" value="Receipt">Receipt Note</option>
        </select>
        <textarea value={content} placeholder="Note..." className="mt-4 min-h-[75px]" onChange={(e) => setContent(e.target.value)}/>
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={() => onClose()}>Cancel</div>
            <div className="flex btn" onClick={() => onSubmit()}>OK</div>
        </div>
    </Modal>;
}

export default TillNoteModal;