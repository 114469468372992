import { forwardRef } from "react";
import Barcode from "react-barcode";
import DataCache from "../../utilities/DataCache";
import './LabelPrint.scss';

function LabelPrint({ product, labels, skip }, ref) {
    const VATRate = DataCache.vatRate;
    var skips = [];

    for(var i = 0; i < skip; i++) {
        skips.push(<div key={i} className="label"></div>);
    }

    // Create seperate grid for each page
    var remainingLabels = [...labels];
    var grids = [];

    while(remainingLabels.length > 0) {
        grids.push(
            <div ref={ref} className="label-grid">
                {skips}
                {remainingLabels.slice(0, 21 - skips.length).map(l => {
                    let v = l.variant;
                    let p = l.product || product;
                    let loc = DataCache.locationLookup[l.locationId];
                    let variations = v.variations ? v.variations.sort((a, b) => a.name.localeCompare(b.name)).map(v => v.value) : null;

                    return <div className="label">
                        <Barcode 
                            value={v.barcode.toString().padStart(9, '0')}
                            displayValue={false}
                            height={40}
                            width={1.6}
                        />
                        <div className="flex flex-col w-full px-4 relative top-[-10px]">
                            <div className="text-right text-xs relative">(BC: {v.barcode.toString().padStart(9, '0')})</div>
                            <div className="flex flex-row justify-between text-sm font-bold">
                                <div className="mb-0 leading-[0.85rem]">{p.productCode}</div>
                                <div className="mb-0 leading-[0.85rem]">£{v.priceIncVAT !== undefined ? v.priceIncVAT : v.price ? (v.price * VATRate).toFixed(2) : parseFloat((p.basePriceIncVATOverride || p.basePriceIncVAT || (p.basePrice * VATRate))).toFixed(2)}</div>
                            </div>
                            <div className="text-sm mb-0 leading-[0.85rem]">{variations.join(" | ")}</div>
                            <div className="text-sm mb-0 leading-[0.85rem]">{loc ? loc.name : l.locationId}</div>
                        </div>
                    </div>
                })}
            </div>
        );
        
        remainingLabels.splice(0, 21 - skips.length);
        skips = [];
    }

    return <div ref={ref} className="onlyPrint absolute top-0 left-0 w-full max-w-full m-0 p-0">
        {grids}
    </div>;
}

export default forwardRef(LabelPrint);