import { useEffect, useState } from "react";
import APIFetch from "../../utilities/APIFetch";
import { useNavigate } from "react-router-dom";
import MessageModal from "../../components/MessageModal";
import eposicon from '../../assets/images/eposicon.png';
import webicon from '../../assets/images/webicon.png';
import check from '../../assets/images/check.png';
import cross from '../../assets/images/cross.png';
import TillSelectModal from "../OrderManagement/TillSelectModal";
import { format } from "date-fns";

const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

function ReturnsWidget() {
    const [returns, setReturns] = useState(null);
    const [message, setMessage] = useState(null);

    const navigate = useNavigate();
    const [selectingTill, setSelectingTill] = useState(null);

    const onSelect = (orderId) => {
        setSelectingTill(orderId);
    }

    const onProductClick = (productId) => {
        navigate(`/product/${productId}`)
    }

    const openTill = (tillId, orderId) => {
        navigate(`/till/${tillId}/${orderId}`, { state: { backUrl: '/orders' } });
    }

    useEffect(() => {
        APIFetch("GET", `returns`)
        .then(result => {
            if(result.ok) {
                setReturns(result.data);
            } else {
                setMessage("An error occurred loading returns data.");
            }
        })
        .catch(() => {
            setMessage("An error occurred loading returns data.");
        })
    }, []);

    var lastDate = null;

    var returnsList = [];

    if(returns && returns.length) {
        returns.forEach(r => {
            if(r.createdAt && (!lastDate || !datesAreOnSameDay(new Date(r.createdAt), lastDate))) {
                returnsList.push(<div className="flex flex-row p-2 py-4 items-center text-sm font-semibold uppercase">
                    {format(new Date(r.createdAt), "EEEE do MMMM")}
                </div>);
                lastDate = new Date(r.createdAt);
            }
            
            returnsList.push(<div className="flex flex-row p-2 items-center text-sm odd:bg-brand-grey-light">
                <div className="w-[35px]">
                    <img src={r.orderType === "WEB" ? webicon : eposicon} className="p-1"/>
                </div>
                <div className="basis-3/12 cursor-pointer underline" onClick={() => onSelect(r.orderId)}>{r.orderId}</div>
                <div className={"basis-3/12" + (r.productId ? " cursor-pointer underline" : "")} onClick={() => { if(r.productId) { onProductClick(r.productId) }}}>{r.productCode || "-"}</div>
                <div className="basis-3/12">£{r.value.toFixed(2)}</div>
                <div className="basis-3/12">{r.type}</div>
                <div className="flex justify-center w-[70px]">
                    <img src={r.restocked ? check : cross} className={"object-contain w-[32px]" + (r.restocked ? "" : " p-[3px]")}/>
                </div>
            </div>);
        });
    }

    return (
        <div className="flex flex-col mt-4 ml-0 p-6 mb-auto flex-grow shadow bg-white min-h-0">
            <MessageModal message={message} onClose={() => setMessage(null)}/>
            {selectingTill ? <TillSelectModal onClose={() => setSelectingTill(null)} onSuccess={(id) => { openTill(id, selectingTill); setSelectingTill(null); }}/> : null}
            <div className="text-lg text-bold font-semibold uppercase text-left mb-4 min-w-[350px]">Returns</div>
            <div className="flex flex-col mt-0 overflow-y-auto">
                <div className="flex flex-row uppercase font-semibold text-xs p-2 pt-0">
                    <div className="w-[35px]"></div>
                    <div className="basis-3/12">Order</div>
                    <div className="basis-3/12">Product</div>
                    <div className="basis-3/12">Value</div>
                    <div className="basis-3/12">Type</div>
                    <div className="w-[70px]">Stock</div>
                </div>
                {returnsList}
            </div>
        </div>
    );
}

export default ReturnsWidget;