import { useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";
import TillAddressModal from "./TillAddressModal";

const TillAddressBlock = ({ order, updateAddressField, onDeleteAddress }) => {
    const [message, setMessage] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [selectingAddress, setSelectingAddress] = useState(false);

    const updateAddress = (address) => {
        APIFetch('POST', `order/${order.id}/${selectingAddress === 'delivery' ? "deliveryaddress" : "billingaddress"}/${address.id}`)
        .then(result => {
            if(result.ok) {
                updateAddressField(selectingAddress, address);
                setSelectingAddress(false);
            } else {
                setMessage("An error occurred when updating the order address.");
            }
        })
        .catch(e => {
            setMessage("An error occurred when updating the order address.");
        });
    }

    const clearAddress = (type) => {
        if(!waiting) {
            setWaiting(true);
            APIFetch('DELETE', `order/${order.id}/${type}address/clear`)
            .then(result => {
                if(result.ok) {
                    updateAddressField(type, null);
                } else {
                    setMessage("An error occurred when attempting to remove the address.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when attempting to remove the address.");
            })
            .finally(() => {
                setWaiting(false);
            })
        }
    }

    if(!order || !order.id || !order.customer) return null;

    var deliveryAddress = order.deliveryAddressId && order.customer.addresses ? order.customer.addresses.find(a => a.id === order.deliveryAddressId) : null;
    var billingAddress = order.billingAddressId && order.customer.addresses ? order.customer.addresses.find(a => a.id === order.billingAddressId) : null;

    return <div className="flex flex-col mt-4">
        {selectingAddress ? <TillAddressModal 
            onSuccess={updateAddress} 
            onClose={() => setSelectingAddress(false)} 
            onDeleteAddress={onDeleteAddress}
            customerId={order.customer.id} 
            addresses={order.customer.addresses || []}
        /> : null}
        <MessageModal message={message} onClose={() => setMessage(null)} onSuccess={updateAddress}/>
        <div className="font-medium p-2 pb-1">Address Info</div>
        {deliveryAddress ? <div className="p-2 text-sm">
            {billingAddress ? <div className="text-md font-medium">Delivery Address</div> : null }
            <div className="">{deliveryAddress.name}</div>
            <div className="">{deliveryAddress.address1}</div>
            {deliveryAddress.address2 ? <div className="">{deliveryAddress.address2}</div> : null }
            {deliveryAddress.city ? <div className="">{deliveryAddress.city}</div> : null }
            <div className="">{deliveryAddress.postcode}</div>
            {deliveryAddress.telephone ? <div className="">{deliveryAddress.telephone}</div> : null }
            <div className="">{deliveryAddress.country}</div>
            <div className="flex flex-row mt-1">
                <div className="btn btn-sm flex-1 cursor-pointer my-2 mr-1" onClick={() => { setSelectingAddress("delivery") }}>Change</div>
                <div className="btn btn-sm flex-1 cursor-pointer my-2 ml-1" onClick={() => { clearAddress("delivery") }}>Clear</div>
            </div>
        </div>
        : <div className="btn cursor-pointer m-2 btn-sm" onClick={() => { setSelectingAddress("delivery") }}>Set Delivery Address</div>}
        {billingAddress ? <div className="p-2 text-sm">
            <div className="font-medium">Billing Address</div>
            <div className="">{billingAddress.name}</div>
            <div className="">{billingAddress.address1}</div>
            {billingAddress.address2 ? <div className="">{billingAddress.address2}</div> : null }
            {billingAddress.city ? <div className="">{billingAddress.city}</div> : null }
            <div className="">{billingAddress.postcode}</div>
            {billingAddress.telephone ? <div className="">{billingAddress.telephone}</div> : null }
            <div className="">{billingAddress.country}</div>
            <div className="flex flex-row mt-1">
                <div className="btn btn-sm flex-1 cursor-pointer my-2 mr-1" onClick={() => { setSelectingAddress("billing") }}>Change</div>
                <div className="btn btn-sm flex-1 cursor-pointer my-2 ml-1" onClick={() => { clearAddress("billing") }}>Clear</div>
            </div>
        </div>
        : <div className="btn btn-sm cursor-pointer m-2 mt-0" onClick={() => { setSelectingAddress("billing") }}>{deliveryAddress ? "Change Billing Address" : "Set Billing Address"}</div>}
      </div>;
}

export default TillAddressBlock;