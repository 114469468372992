const Tabs = ({ headings, tab, onTabChange, className }) => {
    return <div className={"flex flex-row " + (className || "")}>
        {headings.map((h, i) => {
            return <div key={h} className={"cursor-pointer py-2 px-4 border-brand-medium text-sm " + (tab === i ? " bg-brand-grey-medium ": "") +  (i === headings.length - 1 ? " border-r-0" : " border-r")} onClick={() => onTabChange(i)}>
                {h}
            </div>
        })}
    </div>;
}

export default Tabs;