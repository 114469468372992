import Sidebar from "../../components/Sidebar/Sidebar";

function NotFound() {
  return (
    <div className="App">
      <div className="flex flex-row w-full h-full overflow-y-hidden">
              <Sidebar />
              <div className="content overflow-y-scroll flex flex-col p-4 flex-grow">
                <div className="text-black">Page does not exist.</div>
              </div>
      </div>
    </div>
  );
}

export default NotFound;