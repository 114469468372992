import { useState } from "react";
import LookupInput from "../../components/LookupInput";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

function DiscountEditModal({ discount, onChange, onClose }) {
  const [message, setMessage] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [d, setD] = useState(discount);

  const updateField = (name, value) => {
    var newD = { ...d };
    newD[name] = name == "type" ? parseInt(value) : value;
    setD(newD);
  }


  const saveChanges = () => {
    if(d.name.length === 0) {
        setMessage("Name is required.");
        return;
    }

    if(!waiting) {
        setWaiting(true);
        var newItem = { ...d };
        
        if(newItem.targetType != 0) {
          delete newItem.manufacturerFilter;
        }

        if(newItem.targetType != 1) {
          delete newItem.departmentFilter;
        }

        if(newItem.targetType != 2) {
          delete newItem.categoryFilter;
        }

        if(newItem.targetType != 3) {
          delete newItem.productFilter;
        }

        APIFetch('POST', `discount/${d.id}`, newItem)
        .then(result => {
            if(result.ok) {
                onChange(newItem);
            } else {
                if(result.status === 409) {
                    setMessage(`A discount with the name '${d.name}' or code '${d.code} already exists.`);
                } else {
                    setMessage("An error occurred while saving your changes.");
                }
            }
        })
        .catch(e => {
            setMessage("An error occurred while saving your changes.");
        })
        .finally(() => setWaiting(false));
    }
}

  return <Modal onClose={onClose} heading="Edit Discount" className="text-black text-center" >
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-row text-left mt-8">
          <div className="flex flex-col mr-20">
            <div className="flex flex-col">
                <label>Created:</label>
                <div className="flex-1 text-sm mt-1">{new Date(discount.createdAt).toLocaleDateString()}</div>
            </div>
            <div className="flex flex-col mt-5">
                <label>Name:</label>
                <input className="flex-1"
                    type="text"
                    value={d.name}
                    onChange={(e) => updateField("name", e.target.value)}
                />
            </div>
            <div className="flex flex-col mt-5">
                <label>Code:</label>
                <input className="flex-1"
                    type="text"
                    value={d.code}
                    onChange={(e) => updateField("code", e.target.value)}
                />
            </div>
            <div className="flex flex-col mt-5">
                <label>Discount/Sale Type:</label>
                <select value={d.type} onChange={(e) => updateField("type", e.target.value)}>
                  <option value={1}>Percentage</option>
                  <option value={2}>Fixed Price</option>
                  <option value={3}>Free Shipping</option>
                  <option value={4}>Buy X Get Y Free</option>
                  <option value={5}>Buy X For Y</option>
                </select>
            </div>
            { d.type == 4 || d.type == 5 ? <div className="flex flex-col mt-5">
                <label>Buy X:</label>
                <input className="flex-1"
                    type="number"
                    value={d.adjustmentX}
                    onChange={(e) => updateField("adjustmentX", e.target.value)}
                />
            </div> : null }
            { d.type == 4 ? <div className="flex flex-col mt-5">
                <label>Get Y Free:</label>
                <input className="flex-1"
                    type="number"
                    value={d.adjustmentY}
                    onChange={(e) => updateField("adjustmentY", e.target.value)}
                />
            </div> : null }
            { d.type == 5 ? <div className="flex flex-col mt-5">
                <label>For Y (£):</label>
                <input className="flex-1"
                    type="number"
                    value={d.adjustmentY}
                    onChange={(e) => updateField("adjustmentY", e.target.value)}
                />
            </div> : null }
            { !d.type || d.type == 1 || d.type == 2 || d.type == 4 ? <div className="flex flex-col mt-5">
                <label>{!d.type || d.type == 1 ? "Value (%)" : "Value (£)"}:</label>
                <input className="flex-1"
                    type="number"
                    value={d.value}
                    onChange={(e) => updateField("value", e.target.value)}
                />
            </div> : null }
          </div>
          <div className="flex flex-col mr-20">
            <div className="flex flex-col">
                <label>Minimum Spend:</label>
                <input className="flex-1"
                    type="number"
                    value={d.minimumSpend}
                    onChange={(e) => updateField("minimumSpend", e.target.value)}
                />
            </div>
            <div className="flex flex-col mt-5">
                <label>Total Usage Limit:</label>
                <input className="flex-1"
                    type="number"
                    value={d.overallUsageLimit}
                    onChange={(e) => updateField("overallUsageLimit", e.target.value)}
                />
            </div>
            <div className="flex flex-col mt-5">
                <label>Customer Usage Limit:</label>
                <input className="flex-1"
                    type="number"
                    value={d.customerUsageLimit}
                    onChange={(e) => updateField("customerUsageLimit", e.target.value)}
                />
            </div>
            <div className="flex flex-col mt-5">
                <label>Order Usage Limit:</label>
                <input className="flex-1"
                    type="number"
                    value={d.orderUsageLimit}
                    onChange={(e) => updateField("orderUsageLimit", e.target.value)}
                />
            </div>
            {d.code ? <div className="flex flex-row mt-5 items-center">
                <input className="flex-1 mr-2"
                    type="checkbox"
                    value={d.isStackable}
                    onChange={(e) => updateField("isStackable", e.target.value)}
                />
                <label>Allow Use With Other Offers</label>
            </div> : null }
          </div>
          <div className="flex flex-col ml-2">
            <div className="flex flex-col">
                <label>Start Date:</label>
                <input className="flex-1"
                    type="date"
                    value={d.startDate}
                    onChange={(e) => updateField("startDate", e.target.value)}
                />
            </div>
            <div className="flex flex-col mt-5">
                <label>End Date:</label>
                <input className="flex-1"
                    type="date"
                    value={d.endDate}
                    onChange={(e) => updateField("endDate", e.target.value)}
                />
            </div>
            <div className="flex flex-col mt-5">
                <label>Limit By Price:</label>
                <select value={d.pricingRestricted} onChange={(e) => updateField("pricingRestricted", e.target.value)}>
                  <option value={0}>All items</option>
                  <option value={1}>Full price items</option>
                  <option value={2}>Reduced price items</option>
                </select>
            </div>
            <div className="flex flex-col mt-5">
                <label>Limit To Type:</label>
                <select defaultValue={d.manufacturerFilter ? 0 : d.departmentFilter ? 1 : d.categoryFilter ? 2 : d.productFilter ? 3 : null} value={d.targetType} onChange={(e) => updateField("targetType", e.target.value)}>
                  <option disabled selected></option>
                  <option value={0}>Manufacturer</option>
                  <option value={1}>Department</option>
                  <option value={2}>Category</option>
                  <option value={3}>Product</option>
                </select>
            </div>
            { d.targetType == 0 ? <div className="flex flex-col mt-5">
                <label>Manufacturer:</label>
                <LookupInput
                  className="flex flex-1"
                  initialId={d.manufacturerFilter}
                  itemType={'manufacturer'}
                  onChange={(name, value) => { value ? updateField("manufacturerFilter", value.id) : updateField("manufacturerFilter", "")}}
                />
            </div> : null }
            { d.targetType == 1 ? <div className="flex flex-col mt-5">
                <label>Department:</label>
                <LookupInput
                  className="flex flex-1"
                  initialId={d.departmentFilter}
                  itemType={'department'}
                  onChange={(name, value) => { value ? updateField("departmentFilter", value.id) : updateField("departmentFilter", "")}}
                />
            </div> : null }
            { d.targetType == 2 ? <div className="flex flex-col mt-5">
                <label>Category:</label>
                <LookupInput
              className="flex flex-1"
              initialId={d.categoryFilter}
              itemType={'category'}
              onChange={(name, value) => { value ? updateField("categoryFilter", value.id) : updateField("categoryFilter", "")}}
            />
            </div> : null }
            { d.targetType == 3 ? <div className="flex flex-col mt-5">
                <label>Product:</label>
                <LookupInput
                  className="flex flex-1"
                  initialId={d.productFilter}
                  itemType={'product'}
                  keyName="productCode"
                  onChange={(name, value) => { value ? updateField("productFilter", value.id) : updateField("productFilter", "")}}
                />
            </div> : null }
          </div>
        </div>
        <div className="flex flex-row justify-center mt-16">
            <div className="flex btn mr-4" onClick={onClose}>Cancel</div>
            <div className="flex btn" onClick={() => { saveChanges() }}>Save</div>
        </div>
    </Modal>;
}

export default DiscountEditModal;