import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import Modal from "../../components/Modal";
import ItemCreationModal from "../../components/ItemCreationModal";
import { SpinnerCircular } from "spinners-react";
import ItemCard from "../../components/ItemCard";
import ItemEditPanel from "../../components/ItemEditPanel";

// Name
// Price
// URL Structure

const AdditionalFields = [
    { name: "Price", type: "number", propertyName: "price", defaultValue: "0.00" },
    { name: "Tracking URL", type: "string", propertyName: "url", defaultValue: "" },
];

function ShippingMethods() {
    const [shippingMethods, setShippingMethods] = useState(null);
    const [message, setMessage] = useState(null);
    const [pendingDelete, setPendingDelete] = useState(false);
    const [creation, setCreation] = useState(false);
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        APIFetch("GET", "shippingmethod")
        .then(result => {
            if(result.ok) {
                setShippingMethods(result.data);
            } else {
                setShippingMethods([]);
            }
        })
        .catch(() => {
            setShippingMethods([]);
        })
    }, []);

    const onDelete = (item) => {
        if(!pendingDelete || pendingDelete !== item) {
            setPendingDelete(item);
            return;
        }

        APIFetch("DELETE", `shippingmethod/${item.id}`)
        .then(result => {
            if(result.ok) {
                setShippingMethods(shippingMethods.filter(s => s !== item));
                setPendingDelete(null);
            } else {
                setMessage("An error occurred when attempting to delete shipping method.");
            }
        })
        .catch(() => {
            setMessage("An error occurred when attempting to delete shipping method.");
        })
    }

    const onCreate = (item) => {
        setShippingMethods([item, ...shippingMethods]);
        setCreation(false);
    }

    const onEdit = (item) => {
        var newItems = [...shippingMethods];
        var index = newItems.findIndex(a => a.id === item.id);
        newItems[index] = item;
        setShippingMethods(newItems);
    }

    return (
        <div className="App">
        { creation ? <ItemCreationModal onClose={() => setCreation(false)} itemType="shippingmethod" heading="Create New Shipping Method" onCreate={onCreate} additionalFields={AdditionalFields}/> : null }
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { pendingDelete ? <Modal heading="Confirm Shipping Method Deletion" className="text-black" onClose={() => setPendingDelete(null)}>
            <div className="text-sm">Are you sure you wish to delete '{pendingDelete.name}'?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {onDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        <div className="flex flex-col md:flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="flex flex-row items-center ml-2 mt-9 mb-0">
                    <h1 className="text-left font-medium text-lg">Shipping Methods</h1>
                    <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => { setCreation(true) }}/>
                </div>
                <div className="mb-12 text-sm text-left ml-2">
                    Note: {"{code}"} in URL will automatically be replaced by tracking code.
                </div>
                <div className="flex flex-col flex-grow overflow-y-auto">
                    { shippingMethods && shippingMethods.length === 0 ? <div>No shipping methods to display.</div> : null }
                    { shippingMethods ? shippingMethods.map(l => <ItemCard key={l.id} item={l} onEdit={() => setSelected(l)} onDelete={onDelete} selected={selected === l} nameClass="basis-2/12" additionalFields={[
                            { value: "£" + l.price, className: "basis-2/12"},
                            { value: l.url, className: "basis-8/12"}
                        ]}/>) : null }
                    <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular enabled={!shippingMethods} size={50} color="#24272b" secondaryColor="white" />
                    </div>
                </div>
            </div>
            { selected ? <ItemEditPanel 
                key={selected.id + "edit"}
                itemType={'shippingmethod'}
                displayName="Shipping Method"
                item={selected}
                onDelete={onDelete}
                onChange={(a) => { onEdit(a); }}
                onClose={() => { setSelected(null) }}
                additionalFields={[
                    { name: "Price", type: "number", propertyName: "price", defaultValue: selected.price },
                    { name: "URL Template", type: "string", propertyName: "url", defaultValue: selected.url }
                ]}
                /> : null }
        </div>
        </div>
    );
}

export default ShippingMethods;