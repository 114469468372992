import { useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const TillRetroactivePointsModal = ({ order, onClose }) => {
    const [message, setMessage] = useState(null);

    const onSubmit = () => {
        APIFetch("POST", `order/${order.id}/grantpoints`)
        .then(result => {
            if(result.ok) {
                onClose();
            } else {
                setMessage("An error occurred when granting loyalty points.");
            }
        })
        .catch(() => {
            setMessage("An error occurred when granting loyalty points.");
        })

        onClose();
    }

    return <Modal onClose={onClose} heading={'Reward loyalty points for this order?'} className="text-black text-left flex flex-col" windowClassName="w-[500px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={() => onClose()}>No</div>
            <div className="flex btn" onClick={() => onSubmit()}>Yes</div>
        </div>
    </Modal>;
}

export default TillRetroactivePointsModal;