import { useState } from "react";
import OrderManagementPanel from "../OrderManagement/OrderManagementPanel";
import TillSelectModal from "../OrderManagement/TillSelectModal";
import { useNavigate } from "react-router-dom";

function RecentOrdersWidget({ className }) {
  const navigate = useNavigate();
  const [selectingTill, setSelectingTill] = useState(null);

  const onSelect = (orderId) => {
    setSelectingTill(orderId);
  }

  const openTill = (tillId, orderId) => {
      navigate(`/till/${tillId}/${orderId}`, { state: { backUrl: '/orders' } });
  }

  return (
    <div className={"flex flex-col p-4 bg-white shadow min-h-0 flex-1 " + (className || "")}>
        {selectingTill ? <TillSelectModal onClose={() => setSelectingTill(null)} onSuccess={(id) => { openTill(id, selectingTill); setSelectingTill(null); }}/> : null}
        <OrderManagementPanel onSuccess={(id) => { onSelect(id); }} hideToolbar={true}/>
    </div>
  );
}

export default RecentOrdersWidget;