import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { SpinnerCircular } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import ItemCreationModal from "../../components/ItemCreationModal";
import Modal from "../../components/Modal";
import ItemEditPanel from "../../components/ItemEditPanel";
import ItemCard from "../../components/ItemCard";
import { format } from "date-fns";
import DiscountEditModal from "./DiscountEditModal";
import DataCache from "../../utilities/DataCache";

const DISCOUNT_TYPES = [
    "",
    "Percentage",
    "Fixed Price",
    "Free Shipping",
    "Buy X Get Y",
    "Buy X For Y"
]

function DiscountManagement() {
    const [discounts, setDiscounts] = useState(null);
    const [message, setMessage] = useState(null);
    const [creation, setCreation] = useState(false);
    const [selected, setSelected] = useState(null);
    const [search, setSearch] = useState('');
    const [filteredDiscounts, setFilteredDiscounts] = useState(null)
    const [pendingDelete, setPendingDelete] = useState(false);
    const [filterCode, setFilterCode] = useState(0);
    const [filterType, setFilterType] = useState(0);

    useEffect(() => {
        APIFetch("GET", "discount")
        .then(result => {
            if(result.ok) {
                setDiscounts(result.data);
            } else {
                setMessage("An error occurred when attempting to retreive discounts.");
                setDiscounts([]);
            }
        })
        .catch(() => {
            setMessage("An error occurred when attempting to retreive discounts.");
            setDiscounts([]);
        })
    }, []);

    useEffect(() => {
        if((!search || search.length === 0) && !filterCode && !filterType) {
            setFilteredDiscounts(null);
        } else {
            reapplyFilter(discounts);
        }
    }, [search, discounts, filterCode, filterType]);

    const reapplyFilter = (newDiscounts) => {
        setFilteredDiscounts(newDiscounts.filter(d => {
            var nameMatch = d.name.toLowerCase().includes(search.toLowerCase())
            var codeMatch = filterCode == 0 || (filterCode == 1 && d.code) || (filterCode == 2 && !d.code);
            var typeMatch = filterType == 0 || filterType == d.type;
            return nameMatch && codeMatch && typeMatch;
        }));
    }

    const onDelete = (discount) => {
        if(!pendingDelete || pendingDelete !== discount) {
            setPendingDelete(discount);
        } else {
            APIFetch("DELETE", `discount/${discount.id}`)
            .then(result => {
                if(result.ok) {
                    var newDiscounts = [...discounts];
                    newDiscounts = newDiscounts.filter(l => l.id !== discount.id);
                    setDiscounts(newDiscounts);
                    reapplyFilter(newDiscounts);
                    setPendingDelete(null);
                    setSelected(null);
                    setMessage("Discount deleted successfully.");
                } else {
                    setMessage("An error occurred when attempting to delete the discount.");
                }
            })
            .catch(() => {
                setMessage("An error occurred when attempting to delete the discount.");
            })
        }
    }

    const createDiscount = (discount) => {
        var newDiscounts = [...discounts];
        newDiscounts.push(discount);
        newDiscounts.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        setDiscounts(newDiscounts);
        reapplyFilter(newDiscounts);
        setCreation(false);
    }

    const onEdit = (discount) => {
        var newDiscounts = [...discounts];
        var index = newDiscounts.findIndex(a => a.id === discount.id);
        newDiscounts[index] = discount;
        setDiscounts(newDiscounts);
        reapplyFilter(newDiscounts);
        setSelected(null);
    }

    const detectEnter = (e) => {
        if(e.key === "Enter") {
            setSearch(e.target.value);
        }
    }

    return (
        <div className="App">
        { creation ? <ItemCreationModal onClose={() => setCreation(false)} itemType="discount" heading="Create New Discount" onCreate={createDiscount} /> : null }
        { pendingDelete ? <Modal heading="Confirm Discount Deletion" className="text-black" onClose={() => setPendingDelete(null)} windowClassName="w-[450px]">
            <div className="text-sm">Are you sure you wish to delete '{pendingDelete.name}'?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {onDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="flex flex-row items-center ml-2 mt-9 mb-16">
                    <h1 className="text-left font-medium text-lg">Discounts & Sales</h1>
                    <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => setCreation(true)}/>
                    <input type="text" placeholder="Search..." className="mx-6 mb-1 w-48" onKeyUp={detectEnter}/>
                    <select className={"mr-6 mb-1 text-sm text-brand-grey"} value={filterCode}  onChange={(e) => { setFilterCode(e.target.value); }}>
                        <option value={0}>Coupons or Sales</option>
                        <option value={1}>Coupons Only</option>
                        <option value={2}>Sales Only</option>
                    </select>
                    <select className={"mr-6 mb-1 text-sm text-brand-grey"} value={filterType}  onChange={(e) => { setFilterType(e.target.value); }}>
                        <option value={0}>Any Type</option>
                        <option value={1}>Percentage</option>
                        <option value={2}>Fixed Price</option>
                        <option value={3}>Free Shipping</option>
                        <option value={4}>Buy X Get Y</option>
                        <option value={5}>Buy X For Y</option>
                    </select>
                </div>
                <div className="min-w-[1000px] md:min-w-[0px] flex flex-row px-3 font-bold mb-2 text-sm mt-2">
                    <div className="flex flex-1 basis-3/12">Name</div>
                    <div className="flex flex-1 basis-2/12">Code</div>
                    <div className="flex flex-1 basis-2/12">Type</div>
                    <div className="flex flex-1 basis-3/12">Period</div>
                    <div className="flex flex-1 basis-2/12">Targets</div>
                    <div className="flex flex-1 basis-[45px]"></div>
                </div>
                <div className="min-w-[1000px] md:min-w-[0px] flex flex-col flex-grow overflow-y-auto">
                    { discounts && discounts.length === 0 ? <div className="mt-4">No discounts to display.</div> : null }
                    { discounts ? (filteredDiscounts || discounts).map(d => {
                        var filterName;

                        if(d.manufacturerFilter) {
                            filterName = DataCache.manufacturerLookup[d.manufacturerFilter]?.name;
                        } else if(d.departmentFilter) {
                            filterName = DataCache.departmentLookup[d.departmentFilter]?.name;
                        } else if(d.categoryFilter) {
                            filterName = DataCache.categoryLookup[d.categoryFilter]?.name;
                        } else if(d.productFilter) {
                            filterName = DataCache.productLookup[d.productFilter]?.productCode;
                        }

                        filterName = filterName || "All Items";

                        return <ItemCard 
                            key={d.id} 
                            item={d} 
                            onEdit={() => { setSelected(d) }} 
                            onDelete={onDelete} 
                            selected={selected === d} 
                            nameClass="basis-3/12"
                            additionalFields={[
                                { value: d.code && d.code.length > 0 ? d.code : 'Automatic', className: "basis-2/12" },
                                { value: DISCOUNT_TYPES[d.type || 1], className: "basis-2/12" },
                                { value: (d.endDate && new Date(d.endDate).getTime() < new Date().getTime()) ? "EXPIRED" : (d.startDate ? format(new Date(d.startDate), "dd/MM/yy - ") : "") + (d.endDate ? format(new Date(d.endDate), "dd/MM/yy") : ""), className: "basis-3/12" },
                                { value: filterName || "All Items", className: "basis-2/12" }
                            ]}
                        />
                    }) : null }
                    <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular enabled={!discounts} size={50} color="#24272b" secondaryColor="white" />
                    </div>
                </div>
            </div>
            { selected ? <DiscountEditModal 
                discount={selected}
                onChange={(a) => { onEdit(a); }}
                onClose={() => { setSelected(null) }}
            /> : null }
        </div>
        </div>
    );
}

export default DiscountManagement;