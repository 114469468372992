import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import DataCache from '../utilities/DataCache';

const LookupInput = ({ itemType, onChange, initialValue, initialId, className, keyName = "name" }) => {
    const items = DataCache[itemType];
    const [fuse, setFuse] = useState(null);
    const [searchValue, setSearchValue] = useState(initialValue);
    const [results, setResults] = useState([]);

    useEffect(() => {
        if(keyName && initialId) {
            setSearchValue(items.find(i => i.id === initialId)[keyName]);
        }
    }, [initialId])

    useEffect(() => {
        setFuse(new Fuse(items, {keys: [keyName || "name"]}));
    }, [itemType]);

    const searchValueChange = (e) => {
        if(searchValue === "") {
            setResults([]);
        } else {
            setResults(fuse.search(e.target.value || ""));//.filter(r => r.item.name.toLowerCase() !== e.target.value.toLowerCase()));
        }

        setSearchValue(e.target.value);
        onChange(e.target.value);
    }

    const onSelect = (name, item) => {
        setSearchValue(name);
        onChange(name, item);
    }

    const onBlur = () => {
        setResults([]);
    }

    const onFocus = () => {
        setResults(fuse.search(searchValue || ""));//.filter(r => r.item.name.toLowerCase() !== searchValue.toLowerCase()));
    }

    return <div className={'relative flex ' + (className || "")}>
        <input type="text" value={searchValue} onChange={searchValueChange} className="flex-1" onBlur={onBlur} onFocus={onFocus}/>
        {results.length > 0 ? <div className='absolute left-0 top-full z-10 bg-white w-full p-1 px-2 border border-brand'>
            {results.slice(0, 15).map((r, i) => {
                return <div key={r.item.name + i} className="hover:bg-brand-active cursor-pointer text-left text-sm text-brand" onMouseDown={() => { onSelect(r.item[keyName || "name"], r.item) }}>{r.item[keyName || "name"]}</div>
            })}
        </div>: null}
    </div>
}

export default LookupInput;