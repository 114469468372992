import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import MessageModal from "../../components/MessageModal";
import DataCache from "../../utilities/DataCache";
//import Papa from 'papaparse';
import APIFetch from "../../utilities/APIFetch";
import Modal from "../../components/Modal";
import parseRow from "../../utilities/CSVParse";

function StockTransfer() {
    const [message, setMessage] = useState(null);
    const [locations] = useState([...DataCache.location].sort((a, b) => a.name.localeCompare(b.name)));
    const [fromLoc, setFromLoc] = useState("");
    const [toLoc, setToLoc] = useState("");
    const [csvData, setCSVData] = useState(null);
    const [missingStock, setMissingStock] = useState(null);

    useEffect(() => {
        var warehouse = locations.find(l => l.name === "Warehouse");
        var shop = locations.find(l => l.name === "Shop");

        setFromLoc(warehouse ? warehouse.id.toString() : locations[0].id.toString());
        setToLoc(shop ? shop.id.toString() : locations[1].id.toString());
    }, []);

    const fileChangeListener = (e) => {
        const file = e.target.files[0];
        let processedResults = [];
        let fileReader = new FileReader();
    
        fileReader.onload = (e) => {
            // Split file content by new line
            const results = fileReader.result.split(/\r?\n/);
    
            results.forEach((r, i) => {
                if (!r.trim()) {
                    // Skip empty lines
                    return;
                }
    
                try {
                    let { barcode, quantity } = parseRow(r);
    
                    // Validate parsed data
                    if (!barcode || isNaN(quantity)) {
                        console.error(`Invalid data at line ${i + 1}: ${r}`);
                        return;
                    }
    
                    // Check if the barcode already exists in processed results
                    let existing = processedResults.find(result => result.barcode === barcode);
    
                    if (existing) {
                        existing.quantity += quantity;
                    } else {
                        processedResults.push({ barcode: barcode, quantity: quantity });
                    }
                } catch (error) {
                    console.error(`Error parsing line ${i + 1}: ${error.message}`);
                }
            });
    
            setCSVData(processedResults);
        };
    
        fileReader.onerror = (error) => {
            console.error('File reading error:', error);
        };
    
        fileReader.readAsText(file);
    };
    
    function parseRow(row) {
        const [barcode, quantityStr] = row.split(',');
        const barcodeTrimmed = barcode.trim();
        const quantity = parseInt(quantityStr, 10);
    
        return { barcode: barcodeTrimmed, quantity };
    }
    
    

    const submit = (confirmed = false) => {
        APIFetch('POST', 'stocktransfer', { fromLocation: fromLoc, toLocation: toLoc, transfers: csvData, confirmedMissingStock: confirmed })
        .then(result => {
            if(result.ok) {
                setMessage("Stock transferred successfully.");
                setMissingStock(null);
                setCSVData(null);
            } else {
                if(result.status === 400) {
                    console.error('API Response Error:', result.data); // Log detailed error
                    if(result.data.message && result.data.missingStock) {
                        setMissingStock(result.data.missingStock);
                    } else {
                        setMessage("An error occurred during the transfer.");
                    }
                } else {
                    setMessage("An error occurred during the transfer.");
                }
            }
        })
        .catch(e => {
            console.error('Submit Error:', e); // Log detailed error
            setMessage("An error occurred during the transfer.");
        });
    }

    return (
        <div className="App">
            <MessageModal message={message} onClose={() => setMessage(null)} />
            { missingStock ? <Modal heading={`WARNING: Missing stock in ${locations.find(l => l.id.toString() === fromLoc).name}`} className="text-black" onClose={() => setMissingStock(null)}>
                <div className="mb-4">The following stock is missing from the {locations.find(l => l.id.toString() === fromLoc).name}:</div>
                <div className="flex flex-col items-center">
                    {Object.keys(missingStock).map(key => {
                        return <div>{key} ({missingStock[key]})</div>
                    })}
                </div>
                <div className="mt-4">Do you wish to apply the transfer anyway?</div>
                <div className="flex flex-row justify-center">
                    <div className="flex w-32 justify-center rounded-lg p-2 px-4 mt-8 bg-green-600 cursor-pointer text-white mr-2" onClick={() => { submit(true) }}>Yes</div>
                    <div className="flex w-32 justify-center rounded-lg p-2 px-4 mt-8 bg-brand-active cursor-pointer text-white" onClick={() => setMissingStock(null)}>No</div>
                </div>
            </Modal> : null }
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <Sidebar />
                <div className="content overflow-y-scroll flex flex-col p-8 flex-grow text-left">
                    <div className="flex flex-col justify-center ml-2 mt-9 mb-10">
                        <h1 className="text-left font-medium text-lg">Stock Transfer</h1>
                        <h2 className="text-left p-0 m-0 text-sm">You can use this tool to bulk-transfer stock by uploading a TXT file that contains all the SKUs to transfer.</h2>
                    </div>
                    <div className="flex flex-col flex-grow overflow-y-auto p-3">
                        <div className="flex flex-row items-center">
                            <div>From Location:</div>
                            <select className="ml-4" value={fromLoc} onChange={(e) => { setFromLoc(e.target.value) }}>
                                {locations && locations.length > 0 ? locations.map(o => { return <option key={o.id} value={o.id.toString()}>{o.name}</option> }): null}
                            </select>
                        </div>
                        <div className="flex flex-row mt-4 items-center">
                            <div>To Location:</div>
                            <select className="ml-4" value={toLoc} onChange={(e) => { setToLoc(e.target.value) }}>
                                {locations && locations.length > 0 ? locations.map(o => { return <option key={o.id} value={o.id.toString()}>{o.name}</option> }): null}
                            </select>
                        </div>
                        <div className="flex flex-row mt-4 items-center">
                            <div>Stock Transfer File:</div>
                            <input type="file" accept=".csv,.txt" className="ml-2 border-b-0" onChange={fileChangeListener} />
                        </div>
                        {csvData && csvData.length > 0 ? 
                        <div className={"btn flex mr-auto mt-8" + ((csvData && fromLoc !== toLoc) ? " bg-brand-white" : " bg-brand-grey-alt")} onClick={() => { if(csvData && fromLoc !== toLoc) { submit() } }}>Transfer {csvData.map(i => i.quantity).reduce((a,b) => a + b)} items</div>
                        : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StockTransfer;