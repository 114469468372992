import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AccountCard({ account, selected, onEdit, onDelete }) {
    return <div className={"flex flex-row border-b border-brand-grey mt-1 p-3 text-left font-medium text-sm " + (selected ? " bg-brand-grey text-white" : "")}>
        <div className="basis-4/12">
            {account.name}
        </div>
        <div className="basis-4/12">
            {account.email}
        </div>
        <div className="basis-4/12">
            {account.role.name}
        </div>
        <div className="basis-[45px]">
            <FontAwesomeIcon icon={faEdit} onClick={() => onEdit(account)} className="cursor-pointer mr-4 hover:text-brand-grey-alt"/>
            <FontAwesomeIcon icon={faTrash} onClick={() => onDelete(account)} className="cursor-pointer hover:text-brand-grey-alt"/>
        </div>
    </div>;
}

export default AccountCard;