import { useState } from "react";
import Modal from "../../components/Modal";

const LabelConfigModal = ({onClose, onSuccess, items}) => {
    const [skipCount, setSkipCount] = useState(0);

    return <Modal onClose={onClose} heading="Configure labels to print" className="text-black text-center" windowClassName="w-[450px]">
        <div className="">
            <div className="flex flex-col mt-4">
                <label>Labels to skip on first sheet:</label>
                <input type="number" value={skipCount} className="flex-1" onChange={(e) => setSkipCount(e.target.value)}/>
            </div>
        </div>
        <div className="text-center mt-8">{items ? items.length : 0} labels will be printed</div>
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={onClose}>Cancel</div>
            <div className="flex btn" onClick={() => { onSuccess({ skipCount }) }}>Print</div>
        </div>
    </Modal>;
}

export default LabelConfigModal;