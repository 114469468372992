import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function SidebarItem({ uri, title, subItems, isChild, onNavigate }) {
  const navigate = useNavigate();
  const location = useLocation();
  const active = location.pathname === uri || (subItems && subItems.filter(i => i.uri === location.pathname).length > 0);
  const [open, setOpen] = useState(active);

  if(subItems && subItems.length > 0) {
      return (
        <div className={"mb-1 border-2 " + (active ? "border-brand-grey " : open ? " border-brand-grey" : "border-white hover:border-brand-grey")}>
            <div className={"relative flex flex-row text-left text-sm font-medium p-2 hover:bg-brand-grey hover:text-white cursor-pointer " + (active ? " bg-brand-grey text-white font-semibold" : "")} onClick={() => { setOpen(!open); }}>
                <div className="">{title || 'Ooops'}</div>
                <span className="absolute right-4 my-auto">
                    <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} className="cursor-pointer hover:text-brand-grey-alt" onClick={() =>  { setOpen(!open); }}/>
                </span>
            </div>
            {open ? subItems.map(child => <SidebarItem key={child.title} isChild={true} {...child} onNavigate={onNavigate}/>) : null }
        </div>
      );
  }

  if(isChild) {
    return (
        <div className={"text-left text-xs p-2 cursor-pointer hover:bg-brand-grey hover:text-white" + (active ? " font-bold" : " font-medium")} onClick={(e) => { e.stopPropagation(); onNavigate(); navigate(uri); }}>
            {title || 'Ooops'}
        </div>
      );
  }

  return (
    <div className={"mb-1 text-left text-sm font-medium p-2 hover:bg-brand-grey hover:text-white cursor-pointer border-2 hover:border-brand-grey " + (active ? " bg-brand-grey text-white font-semibold border-brand-grey" : " border-white")} onClick={(e) => { e.stopPropagation(); onNavigate(); navigate(uri) }}>
        {title || 'Ooops'}
    </div>
  );
}

export default SidebarItem;