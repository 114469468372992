import { useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const TYPES = ["Cash", "Card", "Cheque", "Credit Note", "Loyalty Points", "Voucher"];

const TillPaymentModal = ({ onClose, onSuccess, max, baseTransaction, customer }) => {
    const [message, setMessage] = useState(null);
    const [amount, setAmount] = useState(0);
    const [type, setType] = useState("Cash");
    const [referenceId, setReferenceId] = useState("");
    const [waiting, setWaiting] = useState(false);

    const onSubmit = () => {
        if(type !== 'Cash' && amount > max) return;

        if(type === 'Loyalty Points' && !customer) {
            return;
        }

        if(!waiting) {
            setWaiting(true);
            APIFetch("POST", 'transaction', {
                ...baseTransaction,
                amount: type === "Credit Note" || type === "Voucher" ? max : amount,
                type,
                referenceId: type === "Credit Note" || type === "Voucher" ? referenceId : ""
            })
            .then(result => {
                if(result.ok) {
                    onSuccess(result.data);
                } else if((type === "Credit Note" || type === "Voucher") && result.status === 400) {
                    setMessage(`There is no remaining balance on this ${type}.`);
                } else {
                    setMessage("An error occurred when attempting to save the transaction.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when attempting to save the transaction.");
            })
            .finally(() => {
                setWaiting(false);
            })
        }
    }

    return <Modal onClose={onClose} heading={'Record Payment'} className="text-black text-left flex flex-col" windowClassName="w-[450px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-col mt-4">
            <label>Type: </label>
            <select className="flex-1" value={type} onChange={(e) => { setType(e.target.value) }}>
                {TYPES.map((r) => {
                    return <option value={r} key={r}>{r}</option>
                })}
            </select>
        </div>
        
        { type === "Credit Note" || type === "Voucher" ? <div className="flex flex-col mt-4">
            <label>Code:</label>
            <input type="text" value={referenceId} placeholder="Code..." className="flex-1" onChange={(e) => setReferenceId(e.target.value)}/>
        </div> : (type === "Loyalty Points" ? null : <div className="flex flex-col mt-4">
            <label>Amount: £</label>
            <input type="number" value={amount} placeholder="Amount..." className="flex-1" onChange={(e) => setAmount(e.target.value)}/>
        </div>)}
        {type !== 'Cash' && amount > max ? <div className="text-red-600 mt-6 text-center">Payment is higher than the remaining balance.</div> : null}
        {type === 'Credit Note' || type === "Voucher" && referenceId.length < 1 ? <div className="text-red-600 mt-6 text-center">Code is required.</div> : null}
        {type === 'Loyalty Points' && !customer ? <div className="text-red-600 mt-6 text-center">A customer must be linked to the order first.</div> : null}
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={onClose}>Cancel</div>
            <div className="flex btn" onClick={() => onSubmit()}>OK</div>
        </div>
    </Modal>;
}

export default TillPaymentModal;