import { faPlay, faPlayCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import APIFetch from "../utilities/APIFetch";
import DataCache from "../utilities/DataCache";
import MessageModal from "./MessageModal";
import Modal from "./Modal";

const StockTakeItem = ({ item, onDelete }) => {
    const [takeType, setTakeType] = useState("");
    const [takeDetail, setTakeDetail] = useState("");
    const [location, setLocation] = useState("");
    const [pendingDelete, setPendingDelete] = useState("");
    const [message, setMessage] = useState(null);
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
        var locationLookup = !item.locationId ? { name : 'All' } : DataCache.locationLookup[item.locationId];
        var typeName = "";
        var detailName = "";

        if(item.productId) {
            typeName = "Product";
            detailName = DataCache.productLookup[item.productId] ? DataCache.productLookup[item.productId].productCode : "";
        } else if(item.categoryId) {
            typeName = "Category";
            detailName = DataCache.categoryLookup[item.categoryId] ? DataCache.categoryLookup[item.categoryId].name : "";
        } else if(item.departmentId) {
            typeName = "Department";
            detailName = DataCache.departmentLookup[item.departmentId] ? DataCache.departmentLookup[item.departmentId].name : "";
        } else {
            typeName = "Location";
            detailName = locationLookup ? locationLookup.name : "";
        }

        setTakeDetail(detailName);
        setTakeType(typeName);
        setLocation(locationLookup ? locationLookup.name : "");
    }, [item]);

    const deleteStockTake = (id) => {
        setPendingDelete(id);
    }

    const confirmDelete = (id) => {
        if(!waiting) {
            setWaiting(true);
            APIFetch("DELETE", `stocktake/${id}`)
            .then(result => {
                if(result.ok) {
                    onDelete();
                } else {
                    setMessage("An error occurred when attempting to delete the stock take.");
                }
            })
            .catch(() => {
                setMessage("An error occurred when attempting to delete the stock take.");
            })
            .finally(() => {
                setWaiting(false);
            });
        }
    }

    return <div className="flex flex-row border-b border-b-brand-grey mt-1 p-3 text-left font-medium text-sm">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { pendingDelete ? <Modal heading="Confirm Stock Take Deletion" className="text-black" onClose={() => setPendingDelete(null)}>
            <div className="text-sm text-center">Are you sure you wish to delete this stock take?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {confirmDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        <div className="basis-2/12">{takeType}</div>
        <div className="basis-3/12">{takeDetail}</div>
        {location !== takeType ? <div className="basis-2/12">{location}</div> : <div className="basis-2/12" /> }
        <div className="basis-2/12">{item.completed ? "Completed" : "Incomplete"}</div>
        <div className="basis-3/12">{new Date(item.createdAt).toLocaleString()}</div>
        <div className="flex flex-row basis-[45px]">
            <FontAwesomeIcon icon={faTrash} className="cursor-pointer hover:text-brand-grey-alt" onClick={() => { deleteStockTake(item.id) }}/>
        </div>
    </div>;
}

export default StockTakeItem;