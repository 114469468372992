import './Landing.scss';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import appConfig from '../../appConfig.json';
import { AuthStore } from '../../stores/AuthStore';
import MessageModal from '../../components/MessageModal';
import PasswordResetModal from '../../components/PasswordResetModal';
import DataCache from '../../utilities/DataCache';
import logo from '../../assets/images/chatleys.png';
import { SpinnerCircular } from 'spinners-react';

function Landing() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);

  const login = (newPassword) => {
    if(!loading) {
      setLoading(true);
      fetch(appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV].API_DOMAIN + `auth`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            password: newPassword || password
        })
      })
      .then(response => {
        if(response.ok) {
          return response.json()
          .then(async result => {
            AuthStore.update(s => { s.accessToken = result.token });
            AuthStore.update(s => { s.refreshToken = result.refreshToken });
            AuthStore.update(s => { s.name = result.name });
            AuthStore.update(s => { s.userId = result.id });
            sessionStorage.setItem('refreshToken', result.refreshToken);
            await DataCache.initialise();
            setLoading(false);
            navigate('/dashboard');
          });
        } else {
          if(response.status === 403) {
            setReset(true);
          } else if(response.status === 401) {
            setMessage("Your email or password do not match.");
          } else {
            setMessage("An error occurred when attempting to log-in.");
          }

          setLoading(false);
        }
      })
      .catch((e) => {
        console.warn(e);
        setMessage("An error occurred when attempting to log-in.");
        setLoading(false);
      })
    }
  }

  const detectEnter = ({key}) => {
    if(!loading && key === "Enter") {
        login();
    }
  }

  return (
    <div className="App landing">
      { reset ? <PasswordResetModal email={email} password={password} onClose={() => { setReset(false); setMessage("Password reset was not completed successfully."); }} onComplete={(newPassword) => { login(newPassword) }}/> : null }
      <MessageModal message={message} onClose={() => setMessage(null)} />
      <img src={logo} width={400} className="mb-20 px-4"/>
      <h1 className='font-bold text-2xl'>Admin Dashboard</h1>
      { loading ? <SpinnerCircular size={50} color="#24272b" secondaryColor="white" className='mt-10'/> : <div className='flex flex-col'>
        <input type="text" placeholder="Email" className='mb-2 mt-6 w-48' value={email} onChange={(e) => { setEmail(e.target.value) }}/>
        <input type="password" placeholder="Password" className='mt-0 w-48' value={password} onChange={(e) => { setPassword(e.target.value) }} onKeyUp={detectEnter}/>
      </div> }
      <div onClick={() => login()} className='btn mt-10'>Log In</div>
    </div>
  );
}

export default Landing;