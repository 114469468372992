import { useEffect, useState } from "react";
import Modal from "../../components/Modal";

const MediaLinkModal = ({onClose, onLink, variations, existingVariations}) => {
    const [selected, setSelected] = useState({});

    useEffect(() => {
        var newSelected = {};
        existingVariations.forEach(v => {
            newSelected[v.fieldId] = v.value;
        });
        setSelected(newSelected);
    }, [existingVariations])

    const onSelect = (fieldId, value) => {
        var newSelected = {...selected};
        newSelected[fieldId] = value;
        setSelected(newSelected);
    }

    return <Modal onClose={onClose} heading="Select variation values to link to" className="" windowClassName="w-[450px]">
        {variations ? variations.map(v => {
            return <div className="flex flex-row items-center mt-2 mb-2">
                <div>{v.name}:</div>
                <select className="p-1 px-1 border-b border-brand-grey ml-2 flex-1" value={selected[v.id] || "-1"} onChange={(e) => { onSelect(v.id, e.target.value) }}>
                    <option value={"-1"}>All</option>
                    {v.values ? v.values.map(vV => {
                        return <option value={vV.id}>{vV.value}</option>
                    }) : null}
                </select>
            </div>
        }) : null}
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={onClose}>Cancel</div>
            <div className="flex btn" onClick={() => { onLink(selected) }}>Link</div>
        </div>
    </Modal>;
}

export default MediaLinkModal;