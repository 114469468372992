import { useEffect, useState } from "react";
import Modal from "../../components/Modal";

const get = (obj, property) => {
    if(!obj) return null;
    return obj[property];
}

function SettingsAddressModal({ onSave, onClose, settings }) {
    const [newSettings, setNewSettings] = useState();

    useEffect(() => {
        setNewSettings(settings);
    }, [settings]);

    const updateSettingData = (name, value, icon = null) => {
        var newSettingData = [...newSettings];
        var match = newSettingData.findIndex(s => s.name == name);

        if(match >= 0) {
            var newSettingDataItem = {...newSettingData[match]};
            newSettingDataItem.value = value;
            if(icon) {
                newSettingDataItem.icon = icon;
            }
            newSettingData[match] = newSettingDataItem;
        } else {
            newSettingData.push({ name, value, icon });
        }

        setNewSettings(newSettingData);
    }

    return <Modal onClose={onClose} heading="Address:" className="text-black text-left" windowClassName="w-[450px]">
        <div className="flex flex-col flex-1 mt-2">
            <label>Address Line 1:</label>
            <input className="flex-1"
                type="text"
                value={newSettings && newSettings.length ? get(newSettings.find(s => s.name === "address1"), "value") || "" : ""}
                onChange={(e) => updateSettingData("address1", e.target.value)}
            />
        </div>
        <div className="flex flex-col flex-1 mt-2">
            <label>Address Line 2:</label>
            <input className="flex-1"
                type="text"
                value={newSettings && newSettings.length ? get(newSettings.find(s => s.name === "address2"), "value") || "" : ""}
                onChange={(e) => updateSettingData("address2", e.target.value)}
            />
        </div>
        <div className="flex flex-col flex-1 mt-2">
            <label>Town:</label>
            <input className="flex-1"
                type="text"
                value={newSettings && newSettings.length ? get(newSettings.find(s => s.name === "town"), "value") || "" : ""}
                onChange={(e) => updateSettingData("town", e.target.value)}
            />
        </div>
        <div className="flex flex-col flex-1 mt-2">
            <label>County:</label>
            <input className="flex-1"
                type="text"
                value={newSettings && newSettings.length ? get(newSettings.find(s => s.name === "county"), "value") || "" : ""}
                onChange={(e) => updateSettingData("county", e.target.value)}
            />
        </div>
        <div className="flex flex-col flex-1 mt-2">
            <label>Postcode:</label>
            <input className="flex-1"
                type="text"
                value={newSettings && newSettings.length ? get(newSettings.find(s => s.name === "postcode"), "value") || "" : ""}
                onChange={(e) => updateSettingData("postcode", e.target.value)}
            />
        </div>
        <div className="flex flex-row mt-8 justify-center">
            <div className="btn mr-4" onClick={() => { onSave(newSettings) }}>Save</div>
            <div className="btn" onClick={() => { onClose() }}>Cancel</div>
        </div>
    </Modal>;
}

export default SettingsAddressModal;