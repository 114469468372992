import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import Modal from "../../components/Modal";
import ItemCreationModal from "../../components/ItemCreationModal";
import { SpinnerCircular } from "spinners-react";
import ItemCard from "../../components/ItemCard";
import ItemEditPanel from "../../components/ItemEditPanel";

function CustomerSourceManagement() {
    const [sources, setSources] = useState(null);
    const [message, setMessage] = useState(null);
    const [pendingDelete, setPendingDelete] = useState(false);
    const [creation, setCreation] = useState(false);
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        APIFetch("GET", "customersource")
        .then(result => {
            if(result.ok) {
                setSources(result.data);
            } else {
                setSources([]);
            }
        })
        .catch(() => {
            setSources([]);
        })
    }, []);

    const onDelete = (item) => {
        if(!pendingDelete || pendingDelete !== item) {
            setPendingDelete(item);
            return;
        }

        APIFetch("DELETE", `customersource/${item.id}`)
        .then(result => {
            if(result.ok) {
                setSources(sources.filter(s => s !== item));
                setPendingDelete(null);
            } else {
                setMessage("An error occurred when attempting to delete source.");
            }
        })
        .catch(() => {
            setMessage("An error occurred when attempting to delete source.");
        })
    }

    const onCreate = (item) => {
        setSources([item, ...sources]);
        setCreation(false);
    }

    const onEdit = (item) => {
        var newItems = [...sources];
        var index = newItems.findIndex(a => a.id === item.id);
        newItems[index] = item;
        setSources(newItems);
    }

    return (
        <div className="App">
        { creation ? <ItemCreationModal onClose={() => setCreation(false)} itemType="customersource" heading="Create New Source" onCreate={onCreate} /> : null }
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { pendingDelete ? <Modal heading="Confirm Source Deletion" className="text-black" onClose={() => setPendingDelete(null)}>
            <div className="text-sm">Are you sure you wish to delete '{pendingDelete.name}'?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {onDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        <div className="flex flex-col md:flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="flex flex-row items-center ml-2 mt-9 mb-0">
                    <h1 className="text-left font-medium text-lg">Customer Sources</h1>
                    <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => { setCreation(true) }}/>
                </div>
                <div className="mb-12 text-sm text-left ml-2">
                    Note: Customer Sources are used when answering the question 'Where did you hear about us?', when completing an order.
                </div>
                <div className="flex flex-col flex-grow overflow-y-auto">
                    { sources && sources.length === 0 ? <div>No sources to display.</div> : null }
                    { sources ? sources.map(l => <ItemCard key={l.id} item={l} onEdit={() => setSelected(l)} onDelete={onDelete} selected={selected === l} nameClass="basis-full" />) : null }
                    <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular enabled={!sources} size={50} color="#24272b" secondaryColor="white" />
                    </div>
                </div>
            </div>
            { selected ? <ItemEditPanel 
                key={selected.id + "edit"}
                itemType={'customersource'}
                displayName={'Customer Source'}
                item={selected}
                onDelete={onDelete}
                onChange={(a) => { onEdit(a); }}
                onClose={() => { setSelected(null) }}
                /> : null }
        </div>
        </div>
    );
}

export default CustomerSourceManagement;