// Utility function to get barcode and quantity from CSV
// Handles both plain lists of barcodes and 'barcode, quantity, time, date' format
const parseRow = (r) => {
    let split = r.split(',');
    let quantity = 1;
    let barcode = "";

    if(split.length > 1) {
        quantity = split[1].trim();
        barcode = split[0].trim();
    } else {
        barcode = r;
    }

    return { barcode, quantity }
}

export default parseRow;