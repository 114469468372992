import { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import TillAdjustmentPrint from "../../components/PrintTemplates/TillAdjustmentPrint";

const TillAdjustmentPrintModal = ({ onClose, adjustment }) => {
    const [message, setMessage] = useState(null);
    const printingRef = useRef(null);

    return <Modal onClose={onClose} heading={'Print Adjustment Receipt'} className="text-black text-left flex flex-col" windowClassName="w-[450px]">
        <TillAdjustmentPrint ref={printingRef} adjustment={adjustment}/>
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-col mt-2 mb-2 justify-center text-center">
            <div>Would you like to print the adjustment receipt?</div>
        </div>
        <div className="flex flex-row mt-8 justify-center">
            <div className="btn mr-4" onClick={() => onClose()}>Skip</div>
            <ReactToPrint
                trigger={() => {
                    return <div className="btn">Print</div>
                }}
                content={() => printingRef.current}
                onAfterPrint={onClose}
            />
        </div>
    </Modal>;
}

export default TillAdjustmentPrintModal;