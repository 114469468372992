import { useCallback, useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCashRegister, faPlus } from "@fortawesome/free-solid-svg-icons";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import Modal from "../../components/Modal";
import { SpinnerCircular } from "spinners-react";
import ItemCard from "../../components/ItemCard";
import CustomerEditModal from "./CustomerEditModal";
import { useNavigate } from "react-router-dom";

function CustomerManagement() {
    const [customers, setCustomers] = useState(null);
    const [message, setMessage] = useState(null);
    const [pendingDelete, setPendingDelete] = useState(false);
    const [creation, setCreation] = useState(false);
    const [selected, setSelected] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(true);
    const [complete, setComplete] = useState(false);
    const loader = useRef(null);
    const navigate = useNavigate();

    const clearFilters = () => {
        if(searchText === "") return;
        setSearchText("");
        getData(true, { query: "" });
    }

    const getData = (clear = false, filterOverride = null) => {
        setLoading(true);

        var query = {
            count: 50
        }

        if(!clear && customers && customers.length > 0) {
            query.lastDate = customers[customers.length - 1].createdAt;
        }

        if(searchText.length > 0) {
            query.query = searchText;
        }

        if(filterOverride) {
            query = {...query, ...filterOverride};
        }

        APIFetch("post", "customer/query", query)
        .then(result => {
            if(result.ok) {
                if(clear || !customers) {
                    setCustomers(result.data);
                } else {
                    setCustomers(customers.concat(result.data));
                }

                if(result.data.length < query.count) {
                    setComplete(true);
                } else {
                    setComplete(false);
                }
            } else {
                setCustomers([]);
                setComplete(true);
            }

            setLoading(false);
        })
        .catch(() => {
            setCustomers([]);
            setLoading(false);
        });
    }

    useEffect(() => {
        getData(true);
    }, []);

    const detectEnter = ({key}) => {
        if(key === "Enter") {
            getData(true);
        }
    }

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (!loading  && !complete && target.isIntersecting) {
          getData();
        }
      }, [complete, loading, getData]);

    useEffect(() => {
        const option = {
          root: null,
          rootMargin: "20px",
          threshold: 0
        };

        const observer = new IntersectionObserver(handleObserver, option);
        const current = loader.current;
  
        if (current) observer.observe(current);
    
        return () => {
          if (current) observer.unobserve(current);
        }
    }, [complete, loading, handleObserver]);

    const onDelete = (item) => {
        if(!pendingDelete || pendingDelete !== item) {
            setPendingDelete(item);
            return;
        }

        APIFetch("DELETE", `customer/${item.id}`)
        .then(result => {
            if(result.ok) {
                setCustomers(customers.filter(c => c !== item));
                setPendingDelete(null);
            } else {
                setMessage("An error occurred when attempting to delete the customer.");
            }
        })
        .catch(() => {
            setMessage("An error occurred when attempting to delete the customer.");
        })
    }

    const onCreate = (item) => {
        setCustomers([item, ...customers]);
        setCreation(false);
    }

    const onUpdate = (item) => {
        var newCustomers = [...customers];
        var index = newCustomers.findIndex(c => c.id === item.id);

        if(index >= 0) {
            newCustomers[index] = item;
            setCustomers(newCustomers);
        } else {
            setCustomers([item, ...newCustomers]);
        }

        if(selected) {
            setSelected(item);
        }
    }

    return (
        <div className="App">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { pendingDelete ? <Modal heading="Confirm Customer Deletion" className="text-black" onClose={() => setPendingDelete(null)}>
            <div className="text-sm">Are you sure you wish to delete '{pendingDelete.name}'?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {onDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        {selected ? <CustomerEditModal customer={selected} onClose={() => setSelected(null)} onSuccess={(c) => onUpdate(c)} /> : null}
        <div className="flex flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content min-w-[1000px] md:min-w-[0px] overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="min-w-[1000px] md:min-w-[0px] flex flex-row items-center ml-2 mt-9 mb-0">
                    <h1 className="text-left font-medium text-lg">Customers</h1>
                    <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => { setSelected({ addresses: [] }) }}/>
                    <input type="text"
                        value={searchText} 
                        className="ml-10 mb-1 text-sm" 
                        onChange={(e) => { setSearchText(e.target.value) }} placeholder="Search..."
                        onKeyDown={detectEnter}/>
                    <div className="cursor-pointer ml-10 text-sm border-b border-b-brand-grey mt-1 mr-4" onClick={clearFilters}>Clear All</div>
                </div>
                <div className="min-w-[1000px] md:min-w-[0px] flex flex-col flex-grow overflow-y-auto">
                    { customers && customers.length === 0 ? <div>No customers to display.</div> : null }
                    { customers ? customers.map(c => 
                        <ItemCard key={c.id} 
                            item={c} 
                            onEdit={() => setSelected(c)}
                            onDelete={onDelete}
                            selected={selected === c}
                            nameClass=""
                            additionalFields={[
                                { value: (c.firstName || "") + " " + (c.surname || ""), className: "basis-3/12" },
                                { value: c.email || "", className: "basis-3/12" },
                                { value: c.phoneNumber || "", className: "basis-5/12" }
                            ]}
                            additionalActions={[
                                { icon: faCashRegister, action: () => { navigate(`/orders?search=customer:${c.id}`) } }
                            ]} />
                    ) : null }
                    <div ref={loader} className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular enabled={!customers || !complete} size={50} color="#24272b" secondaryColor="white" />
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default CustomerManagement;