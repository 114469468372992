import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

const TillAddressModal = ({ customerId, addresses, onClose, onSuccess, onDeleteAddress }) => {
    const [message, setMessage] = useState(null);
    const [waiting, setWaiting] = useState(false);

    const [name, setName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [postcode, setPostcode] = useState("");
    const [telephone, setTelephone] = useState("");
    const [country, setCountry] = useState("United Kingdom");

    const createAddress = () => {
        if(!name || !address1 || !postcode || !country) {
            setMessage("Missing one or more required fields.");
            return;
        }

        if(!waiting) {
            setWaiting(true);
            APIFetch('POST', 'address', {
                customerId,
                name,
                address1,
                address2,
                city,
                postcode,
                telephone,
                country
            })
            .then(result => {
                if(result.ok) {
                    onSuccess(result.data);
                } else {
                    setMessage("An error occurred when attempting to create the address.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when attempting to create the address.");
            })
            .finally(() => {
                setWaiting(false);
            })
        }
    }

    const selectAddress = (address) => {
        onSuccess(address);
    }

    const deleteAddress = (address) => {
        if(!waiting) {
            setWaiting(true);
            APIFetch('delete', `address/${address.id}`)
            .then(result => {
                if(result.ok) {
                    onDeleteAddress(address);
                } else {
                    setMessage("An error occurred when attempting to delete the address.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when attempting to delete the address.");
            })
            .finally(() => {
                setWaiting(false);
            })
        }
    }

    return <Modal onClose={onClose} heading={'Select Address'} className="" >
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-row overflow-y-auto">
            <div className="flex flex-col p-2 text-sm w-full">
                <div className="text-lg text-bold text-center mb-4 min-w-[275px]">Address Details</div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">Name*:</div>
                    <input type="text"
                        value={name} 
                        className="mb-1 text-sm" 
                        onChange={(e) => { setName(e.target.value) }} placeholder=""/>
                </div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">Address 1*:</div>
                    <input type="text"
                        value={address1} 
                        className="mb-1 text-sm" 
                        onChange={(e) => { setAddress1(e.target.value) }} placeholder=""/>
                </div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">Address 2:</div>
                    <input type="text"
                        value={address2} 
                        className="mb-1 text-sm" 
                        onChange={(e) => { setAddress2(e.target.value) }} placeholder=""/>
                </div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">City:</div>
                    <input type="text"
                        value={city} 
                        className="mb-1 text-sm" 
                        onChange={(e) => { setCity(e.target.value) }} placeholder=""/>
                </div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">Postcode*:</div>
                    <input type="text"
                        value={postcode} 
                        className="mb-1 text-sm" 
                        onChange={(e) => { setPostcode(e.target.value) }} placeholder=""/>
                </div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">Telephone:</div>
                    <input type="text"
                        value={telephone} 
                        className="mb-1 text-sm" 
                        onChange={(e) => { setTelephone(e.target.value) }} placeholder=""/>
                </div>
                <div className="flex flex-col mr-2 mt-2">
                    <div className="mr-2 mt-2 mb-2">Country*:</div>
                    <input type="text"
                        value={country} 
                        className="mb-1 text-sm" 
                        onChange={(e) => { setCountry(e.target.value) }} placeholder=""/>
                </div>
            </div>
            { addresses && addresses.length > 0 ? <div className="flex basis-1/2 flex-col p-2 pl-8 border-l border-l-brand-grey ml-8 min-h-[300px]">
                <div className="text-lg text-bold text-center mb-4 min-w-[275px]">Existing Addresses</div>
                <div className="flex flex-col first-letter:overflow-y-scroll">
                    {addresses.filter(a => a.active === true).map(a => {
                        return <div className="flex flex-row border-b border-b-brand-grey p-2 hover:bg-brand hover:text-white cursor-pointer" onClick={() => { selectAddress(a) }}>
                            <div className="flex flex-col text-sm">
                                <div>{a.name}</div>
                                <div className="">{a.address1}</div>
                                {a.address2 ? <div className="">{a.address2}</div> : null }
                                {a.city ? <div className="">{a.city}</div> : null }
                                <div className="">{a.postcode}</div>
                                {a.telephone ? <div className="">{a.telephone}</div> : null }
                                <div className="">{a.country}</div>
                            </div>
                            <div className="flex justify-center items-center flex-1">
                                <FontAwesomeIcon icon={faTrash} className="flex cursor-pointer ml-auto" onClick={(e) => { e.stopPropagation(); deleteAddress(a); }} />
                            </div>
                        </div>
                    })}
                </div>
            </div> : null }
        </div>
        <div className="flex flex-row mt-8 justify-center">
            <div className="btn mr-4" onClick={() => { createAddress() }}>Save Address</div>
            <div className="btn" onClick={() => onClose()}>Cancel</div>
        </div>
    </Modal>;
}

export default TillAddressModal;