import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import APIFetch from "../utilities/APIFetch";
import MessageModal from "./MessageModal";
import DataCache from '../utilities/DataCache';
import { format } from "date-fns";
import LookupInput from "./LookupInput";

function ItemEditPanel({ item, itemType, displayName, onClose, onChange, additionalFields, children }) {
    const [name, setName] = useState(item.name);
    const [changed, setChanged] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [message, setMessage] = useState(null);

    const [additionalData, setAdditionalData] = useState({});

    useEffect(() => {
        if(additionalFields && additionalFields.length > 0) {
            var newData = {};
            additionalFields.forEach(f => {
                newData[f.propertyName] = item[f.propertyName] !== null && item[f.propertyName] !== undefined ? item[f.propertyName] : (f.defaultValue !== null && f.defaultValue !== undefined ? f.defaultValue : (f.type === 'bool' ? false : ""));
            });
            setAdditionalData(newData);
        }
    }, [])

    const updateAdditionalData = (propertyName, value) => {
        var newData = {...additionalData};
        newData[propertyName] = value;
        setAdditionalData(newData);
        setChanged(true);
    }

    const saveChanges = () => {
        if(name.length === 0) {
            setMessage("Name is required.");
            return;
        }

        var newAdditionalFields = {};

        if(additionalFields && additionalFields.length) {
            for(let i = 0; i < additionalFields.length; i++) {
                if(additionalData[additionalFields[i].propertyName] === null) {
                    setMessage(`'${additionalFields[i].name}' is required.`);
                    return;
                }

                if(additionalData[additionalFields[i].propertyName] === "") {
                    continue;
                }

                if(additionalFields[i].type === 'select') {
                    newAdditionalFields[additionalFields[i].propertyName] = parseInt(additionalData[additionalFields[i].propertyName]);
                } else if(additionalFields[i].type === 'date' && !additionalData[additionalFields[i].propertyName]) {
                    continue;
                } else {
                    newAdditionalFields[additionalFields[i].propertyName] = additionalData[additionalFields[i].propertyName];
                }
            }
        }

        if(!waiting) {
            setWaiting(true);
            APIFetch('POST', `${itemType}/${item.id}`, { name, ...newAdditionalFields })
            .then(result => {
                if(result.ok) {
                    var newItem = {...item};
                    onChange(Object.assign(newItem, { name, ...additionalData }));
                    onClose();
                } else {
                    if(result.status === 409) {
                        setMessage(`A ${itemType} with the name '${name}' already exists.`);
                    } else {
                        setMessage("An error occurred while saving your changes.");
                    }
                }
            })
            .catch(e => {
                setMessage("An error occurred while saving your changes.");
            })
            .finally(() => setWaiting(false));
        }
    }

    return <div className={"flex flex-col border-l border-l-brand-grey w-full md:w-1/4 px-8 text-left text-sm"}>
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { onClose ? <FontAwesomeIcon icon={faTimes} className="text-2xl absolute top-0 right-0 mr-6 mt-5 cursor-pointer hover:text-brand-grey-alt" onClick={onClose}/> : null }
        <div className="text-lg font-medium text-center my-5">Edit {displayName || (itemType.charAt(0).toUpperCase() + itemType.slice(1))}</div>
        {item.createdAt ? <div className="flex flex-col mt-4">
            <label>Created: </label>
            <div className="flex-1">{new Date(item.createdAt).toLocaleDateString()}</div>
        </div> : null}
        <div className="flex flex-col mt-4">
            <label>Name: </label>
            <input type="text" value={name} className="flex-1" onChange={(e) => { setName(e.target.value); setChanged(true) }}/>
        </div>
        {additionalFields && additionalFields.length > 0 ? additionalFields.map(f => {
            if(f.condition) {
                var c = f.condition;
                if(!c.condition(additionalData[c.name])) return null;
            }

            if(f.type === "cache" || f.type == "cacheList") {
                var options = DataCache[f.name.toLowerCase()];

                return <div className="flex flex-col mt-4">
                    <label>{f.name}: </label>
                    <select className="flex-1" value={additionalData[f.propertyName] || ''} onChange={(e) => { updateAdditionalData(f.propertyName, e.target.value) }}>
                        <option value='' hidden disabled></option>
                        {options && options.length > 0 ? options.map(o => { return <option value={o.id}>{o.name}</option> }): null}
                    </select>
                </div>
            } else if(f.type === "string" || f.type === "number"  || f.type === "date") {
                return <div className="flex flex-col mt-4">
                    <label>{f.name}: </label>
                    <input type={f.type === "string" ? "text" : f.type} value={additionalData[f.propertyName] ? (f.type === "date" ? format(new Date(additionalData[f.propertyName]), "yyyy-MM-dd") : additionalData[f.propertyName]) : ''} className="flex-1" onChange={(e) => { updateAdditionalData(f.propertyName, e.target.value) }}/>
                </div>
            } else if(f.type === "bool") {
                return <div className="flex flex-row mt-4">
                    <input type="checkbox" checked={additionalData[f.propertyName] || false} className="mr-2 relative top-[-1px]" onChange={() => { updateAdditionalData(f.propertyName, !additionalData[f.propertyName]) }}/>
                    <label>{f.name}</label>
                </div>
            } else if(f.type === "select") {
                return <div className="flex flex-col mt-4">
                    <label>{f.name}: </label>
                    <select value={additionalData[f.propertyName] || f.defaultValue} onChange={(e) => { updateAdditionalData(f.propertyName, e.target.value) }}>
                        {f.options.map(o => <option value={o.value}>{o.name}</option>)}
                    </select>
                </div>
            } else if(f.type === "lookup") {
                return <div className="flex flex-col mt-4">
                    <label>{f.name}: </label>
                    <LookupInput className="flex flex-1" initialId={additionalData[f.propertyName]} itemType={f.dataType} keyName={f.keyName || null} onChange={(name, value) => { value ? updateAdditionalData(f.propertyName, value.id) : updateAdditionalData(f.propertyName, "")}} />
                </div>
            }
        }) : null }
        <div className={"flex ml-auto mr-auto mt-8 btn" + (changed ? "" : " invisible")} onClick={saveChanges}>Save</div>
        {children}
    </div>;
}

export default ItemEditPanel;