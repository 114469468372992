import { useState } from "react";
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";
import DataCache from "../../utilities/DataCache";
import LookupInput from "../../components/LookupInput";
import parseRow from "../../utilities/CSVParse";

const StockTakeModal = ({ onClose, onSuccess, existingId }) => {
    const [locations] = useState([...DataCache.location].sort((a, b) => a.name.localeCompare(b.name)));
    const [departments] = useState([...DataCache.department].sort((a, b) => a.name.localeCompare(b.name)));
    const [categories] = useState([...DataCache.category].sort((a, b) => a.name.localeCompare(b.name)));
    const [products] = useState([...DataCache.product].sort((a, b) => a.productCode.localeCompare(b.productCode)));
    const [message, setMessage] = useState(null);

    const [takeType, setTakeType] = useState(0);
    const [currentLocation, setCurrentLocation] = useState(-1);
    const [selectedValue, setSelectedValue] = useState(products.length > 0 ? products[0].id.toString() : null);
    const [csvData, setCSVData] = useState(null);

    let selectorName = '';
    let selectorField = null;

    const fileChangeListener = (e) => {
        const file = e.target.files[0];
        let processedResults = [];
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          var results = fileReader.result.split(/\r?\n/);
          results.forEach((r, i) => {
            let { barcode, quantity } = parseRow(r);
            
            let existing = processedResults.find(result => result.barcode === barcode);

            if(existing) {
                existing.quantity += quantity;
            } else {
                processedResults.push({ barcode, quantity });
            }
          });

          setCSVData(processedResults);
        }
        fileReader.readAsText(file);
    }

    const onSubmit = () => {
        if(csvData) {
            var requestBody = { stockTakeId: existingId, locationId: currentLocation, productId: takeType === 0 ? selectedValue : null, categoryId: takeType === 1 ? selectedValue : null, departmentId: takeType === 2 ? selectedValue : null, entries: csvData };
            APIFetch('POST', 'stocktake', requestBody)
            .then(result => {
                if(result.ok) {
                    setMessage("Success.");
                    var itemName = "";
                    var typeName = "Location";
                    
                    switch(takeType) {
                        case 0:
                            typeName = 'Product';
                            itemName = DataCache.productLookup[selectedValue].productCode + " (" + DataCache.productLookup[selectedValue].name + ")";
                            break;
                        case 1:
                            typeName = 'Category';
                            itemName = DataCache.categoryLookup[selectedValue].name;
                            break;
                        case 2:
                            typeName = 'Department';
                            itemName = DataCache.departmentLookup[selectedValue].name;
                            break;
                        default: 
                            break;
                    }

                    onSuccess(result.data, currentLocation === -1 ? "All Locations" : DataCache.locationLookup[currentLocation].name, typeName, itemName, requestBody);
                } else {
                    setMessage("An error occurred during the stock take.");
                }
            })
            .catch(e => {
                setMessage("An error occurred during the stock take.");
            })
        }
    }

    switch(takeType) {
        case 0:
            selectorName = 'Product Code';
            selectorField = <select value={selectedValue} onChange={(e) => { setSelectedValue(e.target.value) }}>
                {products && products.length > 0 ? products.filter(p => p.productCode).map(o => { return <option key={o.id} value={o.id.toString()}>{o.productCode}</option> }): null}
            </select>
            selectorField = <LookupInput className="flex flex-1" itemType={'product'} keyName="productCode" onChange={(name, value) => { setSelectedValue(value.id) }} />
            break;
        case 1:
            selectorName = 'Category';
            selectorField = <select value={selectedValue} onChange={(e) => { setSelectedValue(e.target.value) }}>
                {categories && categories.length > 0 ? categories.map(o => { return <option key={o.id} value={o.id.toString()}>{o.name}</option> }): null}
            </select>
            break;
        case 2:
            selectorName = 'Department';
            selectorField = <select value={selectedValue} onChange={(e) => { setSelectedValue(e.target.value) }}>
                {departments && departments.length > 0 ? departments.map(o => { return <option key={o.id} value={o.id.toString()}>{o.name}</option> }): null}
            </select>
            break;
        default: break;
    }

    if(existingId) {
        return <Modal onClose={onClose} heading={'Redo Stock Take'} className="text-black text-left flex flex-col" windowClassName="w-[550px]">
            <MessageModal message={message} onClose={() => setMessage(null)} />
            <div className="flex flex-col mt-2 justify-center mr-auto">
                <label>Stock Take File:</label>
                <input type="file" accept=".csv,.txt" className="border-0 mt-1" onChange={fileChangeListener} />
            </div>
            {csvData ? <div className="mt-8 input-style text-center">{csvData.map(i => parseInt(i.quantity)).reduce((a,b) => a + b)} items scanned</div> : null }
            <div className={"flex flex-row justify-center " + (csvData ? " mt-2" : "mt-8")}>
                <div className="mr-4 btn" onClick={() => onClose()}>Cancel</div>
                <div className="btn" onClick={() => onSubmit()}>OK</div>
            </div>
        </Modal>;
    }

    return <Modal onClose={onClose} heading={'New Stock Take'} className="text-black text-left flex flex-col" windowClassName="w-[550px]">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className="flex flex-col mt-4">
            <label>Location:</label>
            <select className="" value={currentLocation} onChange={(e) => { setCurrentLocation(e.target.value) }}>
                <option key={"-1"} value={"-1"}>All</option>
                {locations && locations.length > 0 ? locations.map(o => { return <option key={o.id} value={o.id.toString()}>{o.name}</option> }): null}
            </select>
        </div>
        <div className="flex flex-col mt-4">
            <label>Stock Take Type:</label>
            <div className="flex flex-row mr-auto mt-2 input-style">
                <div className={(takeType === 0 ? "bg-brand-grey-medium " : "") + "cursor-pointer border-r border-brand-grey py-2 px-3 text-brand-grey"} onClick={() => { setTakeType(0); setSelectedValue(products[0].id.toString()); }}>Product</div>
                <div className={(takeType === 1 ? "bg-brand-grey-medium " : "") + "cursor-pointer border-r border-brand-grey py-2 px-3 text-brand-grey"} onClick={() => { setTakeType(1); setSelectedValue(categories[0].id.toString()); }}>Category</div>
                <div className={(takeType === 2 ? "bg-brand-grey-medium " : "") + "cursor-pointer border-r border-brand-grey py-2 px-3 text-brand-grey"} onClick={() => { setTakeType(2); setSelectedValue(products[0].id.toString()); }}>Department</div>
                <div className={(takeType === 3 ? "bg-brand-grey-medium " : "") + "cursor-pointer border-brand-grey py-2 px-3 text-brand-grey"} onClick={() => { setTakeType(3); setSelectedValue(null); }}>Location</div>
            </div>
        </div>
        {selectorName ? <div className="flex flex-col mt-4">
            <label>Select {selectorName}:</label>
            {selectorField}
        </div> : null }
        <div className="flex flex-col mt-4">
            <label>Stock Take File:</label>
            <input type="file" accept=".csv,.txt" className="border-0 mt-1" onChange={fileChangeListener} />
        </div>
        {csvData ? <div className="mt-8 input-style text-center">{csvData.map(i => parseInt(i.quantity)).reduce((a,b) => a + b)} items scanned</div> : null }
        <div className={"flex flex-row justify-center " + (csvData ? "mt-2" : "mt-8")}>
            <div className="mr-4 btn" onClick={() => onClose()}>Cancel</div>
            <div className="btn" onClick={() => onSubmit()}>OK</div>
        </div>
    </Modal>;
}

export default StockTakeModal;