import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "../../components/Modal";

const get = (obj, property) => {
    if(!obj) return null;
    return obj[property];
}

const options = [
    { value: "0", label: "phone", icon: faPhone },
    { value: "1", label: "whatsapp", icon: faWhatsapp }
];
  
const formatOptionLabel = ({ value, label, icon }) => (
    <div style={{ display: "flex" }}>
        <FontAwesomeIcon icon={icon} />
    </div>
);

function SettingsNumbersModal({ onSave, onClose, settings }) {
    const [newSettings, setNewSettings] = useState();

    useEffect(() => {
        setNewSettings(settings);
    }, [settings]);

    const updateSettingData = (name, value, icon = null) => {
        var newSettingData = [...newSettings];
        var match = newSettingData.findIndex(s => s.name == name);

        if(match >= 0) {
            var newSettingDataItem = {...newSettingData[match]};
            newSettingDataItem.value = value;
            if(icon) {
                newSettingDataItem.icon = icon;
            }
            newSettingData[match] = newSettingDataItem;
        } else {
            newSettingData.push({ name, value, icon });
        }

        setNewSettings(newSettingData);
    }

    const createNumber = () => {
        var ids = newSettings.filter(s => s.name.startsWith("phone_number_")).map(p => parseInt(p.name.substring(13)));
        var max = Math.max(...ids);
        var newSettingData = [...newSettings];
        newSettingData.push({
            name: "phone_number_" + (max + 1).toString(),
            value: "",
            isImage: false
        });
        setNewSettings(newSettingData);
    }

    const deleteNumber = (name) => {
        var newSettingData = [...newSettings];
        var idx = newSettingData.findIndex(p => p.name === name);

        if(newSettingData[idx].id) {
            newSettingData[idx].isDeleted = true;
        } else {
            newSettingData.splice(idx, 1);
        }

        setNewSettings(newSettingData);
    }

    return <Modal onClose={onClose} heading="Contact numbers:" className="text-black text-left" windowClassName="w-[450px]">
        { newSettings ? newSettings.filter(s => s.name.startsWith("phone_number_") && !s.isDeleted)
            .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            .map(p => {
                return <div className="flex flex-row mb-4 items-center justify-center">
                    <Select
                        defaultValue={options[0]}
                        formatOptionLabel={formatOptionLabel}
                        options={options}
                        value={p.icon}
                        onChange={(val) => updateSettingData(p.name, p.value, val)}
                        className="min-w-[75px] mr-4"
                    />
                    <input className="" type="text" value={p.value} onChange={(e) => updateSettingData(p.name, e.target.value)}/>
                    <FontAwesomeIcon icon={faTimes} className="ml-2 cursor-pointer hover:text-brand-grey-alt" onClick={() => { deleteNumber(p.name) }}/>
                </div>
            })
        : null } 
        <div className="border-b border-b-brand-grey-active text-brand-grey-active text-sm mx-auto cursor-pointer" onClick={() => { createNumber() }}>Add Number</div>
        <div className="flex flex-row mt-8 justify-center">
            <div className="btn mr-4" onClick={() => { onSave(newSettings) }}>Save</div>
            <div className="btn" onClick={() => { onClose() }}>Cancel</div>
        </div>
    </Modal>;
}

export default SettingsNumbersModal;