import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { SpinnerCircular } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import LocationCard from "../../components/LocationCard";
import ItemCreationModal from "../../components/ItemCreationModal";
import Modal from "../../components/Modal";
import ItemEditPanel from "../../components/ItemEditPanel";
import ItemCard from "../../components/ItemCard";

function LocationManagement() {
    const [locations, setLocations] = useState(null);
    const [message, setMessage] = useState(null);
    const [creation, setCreation] = useState(false);
    const [selected, setSelected] = useState(null);
    const [pendingDelete, setPendingDelete] = useState(false);

    useEffect(() => {
        APIFetch("GET", "location")
        .then(result => {
            if(result.ok) {
                setLocations(result.data);
            } else {
                setLocations([]);
            }
        })
        .catch(() => {
            setLocations([]);
        })
    }, []);

    const onDelete = (location) => {
        if(!pendingDelete || pendingDelete !== location) {
            setPendingDelete(location);
        } else {
            APIFetch("DELETE", `location/${location.id}`)
            .then(result => {
                if(result.ok) {
                    var newLocations = [...locations];
                    newLocations = newLocations.filter(l => l.id !== location.id);
                    setLocations(newLocations);
                    setPendingDelete(null);
                    setMessage("Location deleted successfully.");
                } else {
                    setMessage("An error occurred when attempting to delete the location.");
                }
            })
            .catch(() => {
                setMessage("An error occurred when attempting to delete the location.");
            })
        }
    }

    const createLocation = (location) => {
        var newLocations = [...locations];
        newLocations.push(location);
        newLocations.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        setLocations(newLocations);
        setCreation(false);
    }

    const onEdit = (location) => {
        var newLocations = [...locations];
        var index = newLocations.findIndex(a => a.id === location.id);
        newLocations[index] = location;
        setLocations(newLocations);
    }

    return (
        <div className="App">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        { creation ? <ItemCreationModal onClose={() => setCreation(false)} itemType="location" heading="Create New Location" onCreate={createLocation}/> : null }
        { pendingDelete ? <Modal heading="Confirm Location Deletion" className="text-black" onClose={() => setPendingDelete(null)} windowClassName="w-[450px]">
            <div className="text-sm">Are you sure you wish to delete '{pendingDelete.name}'?</div>
            <div className="flex flex-row mt-8 justify-center">
                <div className="btn danger mr-4" onClick={() => {onDelete(pendingDelete)}}>Delete</div>
                <div className="btn" onClick={() => setPendingDelete(null)}>Cancel</div>
            </div>
        </Modal> : null }
        <div className="flex flex-col md:flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-8 flex-grow">
                <div className="flex flex-row items-center ml-2 mt-9 mb-16">
                    <h1 className="text-left font-medium text-lg">Locations</h1>
                    <FontAwesomeIcon icon={faPlus} className="ml-2 mb-1 text-lg cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => setCreation(true)}/>
                </div>
                <div className="flex flex-row px-3 font-bold mb-2 text-sm mt-2">
                    <div className="flex flex-1 basis-full">Name</div>
                    <div className="flex flex-1 basis-[45px]"></div>
                </div>
                <div className="flex flex-col flex-grow overflow-y-auto">
                    { locations && locations.length === 0 ? <div>No locations to display.</div> : null }
                    { locations ? locations.map(l => <ItemCard key={l.id} item={l} onEdit={() => setSelected(l)} onDelete={onDelete} selected={selected === l} nameClass="basis-full"/>) : null }
                    <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular enabled={!locations} size={50} color="#24272b" secondaryColor="white" />
                    </div>
                </div>
            </div>
            { selected ? <ItemEditPanel key={selected.id + "edit"} itemType={'location'} item={selected} onDelete={onDelete} onChange={(a) => { onEdit(a); }} onClose={() => { setSelected(null) }}/> : null }
        </div>
        </div>
    );
}

export default LocationManagement;