import Modal from "../../components/Modal";

const LabelPrintingModal = ({onSuccess, onClose, ids}) => {
    return <Modal onClose={onClose} heading="Print New Labels" className="text-black text-center" windowClassName="w-[450px]">
        <div className="text-center mt-4">{ids.length || 0} items of stock have been added, do you wish to print the labels now?</div>
        <div className="flex flex-row justify-center mt-8">
            <div className="flex btn mr-4" onClick={onClose}>No</div>
            <div className="flex btn" onClick={() => { onSuccess() }}>Yes</div>
        </div>
    </Modal>;
}

export default LabelPrintingModal;