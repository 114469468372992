import { forwardRef, useEffect, useState } from "react";
import Barcode from "react-barcode";
import logo from '../../assets/images/chatleys.png';
import appConfig from "../../appConfig.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

function CreditNotePrint({ value, code, settings }, ref) {
    const getSetting = (name) => {
        var match = settings.find(s => s.name === name);
        if(!match) return null;
        return match.value;
    }

    let addressLines = [
        getSetting("address1"),
        getSetting("address2"),
        getSetting("town"),
        getSetting("county"),
        getSetting("postcode")
    ].filter(l => l && l.length)

    return <div ref={ref} className="onlyPrint fixed top-0 left-0 w-full">
        <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center ml-auto mr-auto">
                <object data={logo} type="image/png" className="w-[400px] max-w-[95%] min-w-0 my-8">
                    <img src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].ASSETS_DOMAIN + `system/logo.jpg?${new Date().getTime()}` || logo} width={400} className="my-8"/>
                </object>
                <div className="mb-2 text-center text-sm">{addressLines.join(", ")}</div>
                {settings.filter(s => s.name.startsWith("phone_number_")).sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(p => <div className="text-center text-sm"><FontAwesomeIcon icon={p.icon === "1" ? faWhatsapp : faPhone} className="mr-1"/>{p.value}</div>)}
                <div className="text-lg font-bold mt-6 mb-4">Credit Note</div>
                <div className="text-sm">Credit Note for the value of £{parseFloat(value || 0).toFixed(2)}</div>
                <div className="mt-0">
                    <Barcode
                        value={code}
                        height={60}
                        width={1.3}
                        displayValue={false}/>
                </div>
            </div>
        </div>
    </div>;
}

export default forwardRef(CreditNotePrint);