import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'; 
import './App.scss';
import NavigateSetter from './components/NavigateSetter';
import OverlaySpinner from './components/OverlaySpinner';
import AccountManagement from './pages/AccountManagement/AccountManagement';
import CategoryManagement from './pages/CategoryManagement/CategoryManagement';
import Dashboard from './pages/Dashboard/Dashboard';
import DepartmentManagement from './pages/DepartmentManagement/DepartmentManagement';
import DiscountManagement from './pages/DiscountManagement/DiscountManagement';
import Landing from './pages/Landing/Landing';
import LocationManagement from './pages/LocationManagement/LocationManagement';
import ManufacturerManagement from './pages/ManufacturerManagement/ManufacturerManagement';
import NotFound from './pages/NotFound/NotFound';
import OrderManagementPage from './pages/OrderManagement/OrderManagementPage';
import ProductDetails from './pages/ProductDetails/ProductDetails';
import ProductManagement from './pages/ProductManagement/ProductManagement';
import StockAdjustment from './pages/StockAdjustment/StockAdjustment';
import StockManagement from './pages/StockManagement/StockManagement';
import StockTakes from './pages/StockTakes/StockTakes';
import StockTransfer from './pages/StockTransfer/StockTransfer';
import StockValue from './pages/StockValue/StockValue';
import SupplierManagement from './pages/SupplierManagement/SupplierManagement';
import SystemSettings from './pages/SystemSettings/SystemSettings';
import TemplateManagement from './pages/TemplateManagement/TemplateManagement';
import Till from './pages/Till/Till';
import TillManagement from './pages/TillManagement/TillManagement';
import { AuthStore } from './stores/AuthStore';
import DataCache from './utilities/DataCache';
import ShippingMethods from './pages/ShippingManagement/ShippingManagement';
import StockArrivals from './pages/StockArrivals/StockArrivals';
import CustomerSourceManagement from './pages/CustomerSourceManagement/CustomerSourceManagement';
import ReplenishmentReport from './pages/ReplenishmentReport/ReplenishmentReport';
import CustomerManagement from './pages/CustomerManagement/CustomerManagement';
import BestSellers from './pages/BestSellers/BestSellers';
import ChartTrend from './pages/ChartTrend/ChartTrend';
import TillReport from './pages/TillReport/TillReport';

function App() {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if(AuthStore.currentState.refreshToken) {
      DataCache.initialise()
      .then(() => { 
        if(window.location.pathname == '/') {
          window.location = "/dashboard";
        };
        
        setTimeout(() => setReady(true), 100) })
      .catch(() => { if(window.location.pathname !== '/') { window.location = "/"; };  setReady(true); })
    } else {
      if(window.location.pathname !== '/') { window.location = "/"; };
      setReady(true);
    }
  }, []);

  if(!ready) {
    return <OverlaySpinner />;
  }

  return <BrowserRouter>
    <NavigateSetter />
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/accounts" element={<AccountManagement />} />
      <Route path="/products" element={<ProductManagement />} />
      <Route path="/locations" element={<LocationManagement />} />
      <Route path="/departments" element={<DepartmentManagement />} />
      <Route path="/discounts" element={<DiscountManagement />} />
      <Route path="/manufacturers" element={<ManufacturerManagement />} />
      <Route path="/categories" element={<CategoryManagement />} />
      <Route path="/suppliers" element={<SupplierManagement />} />
      <Route path="/replenishment" element={<ReplenishmentReport />} />
      <Route path="/bestsellers" element={<BestSellers />} />
      <Route path="/sourcereport" element={<ChartTrend />} />
      <Route path="/stock" element={<StockManagement />} />
      <Route path="/stockarrivals" element={<StockArrivals />} />
      <Route path="/stocktransfer" element={<StockTransfer />} />
      <Route path="/stockadjustment" element={<StockAdjustment />} />
      <Route path="/stocktakes" element={<StockTakes />} />
      <Route path="/stockvalue" element={<StockValue />} />
      <Route path="/orders" element={<OrderManagementPage />} />
      <Route path="/templates" element={<TemplateManagement />} />
      <Route path="/settings" element={<SystemSettings />} />
      <Route path="/shippingmethods" element={<ShippingMethods />} />
      <Route path="/customers" element={<CustomerManagement />} />
      <Route path="/customersources" element={<CustomerSourceManagement />} />
      <Route path="/tills" element={<TillManagement />} />
      <Route path="/till/:tillId" element={<Till />} />
      <Route path="/till/:tillId/:orderId" element={<Till />} />
      <Route path="/tillreport/:tillId" element={<TillReport />} />
      <Route path="/product/:id" element={<ProductDetails />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
}

export default App;